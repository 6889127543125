import React from 'react'
import { ChevronLeft } from 'components/atoms/Icons'
import { MenuItemMobile } from 'components/atoms'

const SubMenuMobile = ({ parent, open, onBack, setMenuMobileOpen, level = 1, ancestors = [] }) => {
  const hasImage = (item) => item?.node?.image

  return (
    <div className={'submenu-mobile' + (open ? ' submenu-mobile--open' : '')}>
      {parent && (
        <>
          <button role="button" className="submenu-mobile__back" onClick={onBack}>
            <ChevronLeft />
            {parent.node.name}
          </button>
          <div className="submenu-mobile__nav">
            {parent.node.children.edges.map((item, i) => {
              return !hasImage(item) ? (
                <MenuItemMobile
                  item={item}
                  key={i}
                  type={'category-item'}
                  setMenuMobileOpen={setMenuMobileOpen}
                  level={level + 1}
                  ancestors={ancestors.concat(parent.node.name)}
                />
              ) : null
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default SubMenuMobile
