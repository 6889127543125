import React, { useState } from 'react'
import { Heart, HeartFilled } from 'components/atoms/Icons'
import { WContainer, Button, ImageBox } from 'components/atoms'
import { priceNotation } from 'utils/safe'
import { useTrans, useWishlist } from 'utils/hooks'

const ProdottoStickyBar = ({ prodotto, addToCart = () => {}, visible }) => {
  const t = useTrans()

  const [prodottoWishlist, setProdottoWishlist] = useState(
    prodotto.wishlist ? prodotto.wishlist : false
  )
  function handleAddToCart() {
    addToCart(prodotto)
  }

  const handleWishlist = useWishlist()

  const HandleClickWishlist = () => {
    handleWishlist(prodottoWishlist, prodotto).then((response) => {
      setProdottoWishlist(response)
    })
  }

  return (
    <div className={'prodotto-sticky-bar' + (visible ? ' prodotto-sticky-bar--visible' : '')}>
      <WContainer>
        <div className="prodotto-sticky-bar__content">
          <div className="prodotto-sticky-bar__info">
            <ImageBox
              src={prodotto.smallThumb}
              maxWidth={80}
              maxHeight={80}
              classPrefix="prodotto-sticky-bar"
              alt={prodotto.titolo.toAltTitle()}
              title={prodotto.titolo.toAltTitle()}
            />
            <div className="prodotto-sticky-bar__details">
              <p className="prodotto-sticky-bar__title">{prodotto.titolo}</p>
              <p className="prodotto-sticky-bar__subtitle">
                {prodotto.sottotitolo}
                {prodotto.formato?.nome ? <> - {prodotto.formato?.nome}</> : <></>}
              </p>
            </div>
            <div className="prodotto-sticky-bar__price">
              {prodotto.disponibile &&
                (prodotto.prezzoScontato !== prodotto.prezzo && prodotto.prezzoScontato ? (
                  <>
                    <span className="prodotto-sticky-bar__fullprice">
                      {priceNotation(prodotto.prezzo)}
                    </span>{' '}
                    <span className="prodotto-sticky-bar__finalprice">
                      {priceNotation(prodotto.prezzoScontato)}
                    </span>
                  </>
                ) : (
                  <span className="prodotto-sticky-bar__finalprice">
                    {priceNotation(prodotto.prezzoScontato)}
                  </span>
                ))}
            </div>
          </div>
          <div className="prodotto-sticky-bar__buttons">
            <Button
              type="secondary"
              icon={prodottoWishlist ? <HeartFilled /> : <Heart />}
              onClick={() => HandleClickWishlist()}
            />
            {prodotto.disponibile ? (
              <Button type="primary" label="Aggiungi al carrello" onClick={handleAddToCart} />
            ) : (
              <Button type="primary" label={t('Non disponibile')} disabled />
            )}
          </div>
        </div>
      </WContainer>
    </div>
  )
}

export default ProdottoStickyBar
