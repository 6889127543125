import React from 'react'
import { MenuItemMobile, Button } from 'components/atoms'
import { useTrans } from 'utils/hooks'
import { trackingGAevent } from 'utils/gtm'

const MenuMobile = ({ vociMenu, setMenuMobileOpen }) => {
  const t = useTrans()

  return (
    <>
      <div className="menu-mobile">
        <nav>
          <Button
            href="/diventa-un-centro"
            label={t('Diventa un centro Matis Paris')}
            type="secondary"
            size="sm"
            onClick={() => trackingGAevent(56, 'beauty_center', 'diventa_un_centro_matis')}
          />
          {vociMenu?.edges?.length
            ? vociMenu.edges.map((item, i) => (
                <MenuItemMobile
                  item={item}
                  key={'menu_key_' + i}
                  type="main"
                  setMenuMobileOpen={setMenuMobileOpen}
                  level={0}
                />
              ))
            : null}
        </nav>
      </div>
    </>
  )
}

export default MenuMobile
