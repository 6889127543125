import React from 'react'

function Player(props) {
  return (
    <svg
      viewBox="0 0 119 119"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--player ' + (props.classes ? props.classes : '')}
    >
      <circle opacity="0.8" cx="59.5" cy="59.5" r="59.5" fill="#363636" stroke="none" />
      <path d="M40 59.25V94.5L96 59.25L40 24V59.25Z" stroke="none" />
    </svg>
  )
}

export default Player
