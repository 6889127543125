import React from 'react'
import { Spedizione, Regalo, BeautyExpert } from 'components/atoms/Icons'
import { useTrans } from 'utils/hooks'

const PreFooter = ({}) => {
  const t = useTrans()

  return (
    <div className="prefooter">
      <div className="prefooter__content">
        <div className="prefooter__item">
          <Spedizione />
          <span className="prefooter__item__label">
            {t('Spedizione gratuita per ordini superiori a 100€')}
          </span>
        </div>
        <div className="prefooter__item">
          <Regalo />
          <span className="prefooter__item__label">
            {t('Prodotti confezionati con cura ed eleganza')}
          </span>
        </div>
        <div className="prefooter__item">
          <BeautyExpert />
          <span className="prefooter__item__label">
            {t('Contatta la Beauty Expert Matis al +39 351/0689144')}
          </span>
        </div>
      </div>
    </div>
  )
}

export default PreFooter
