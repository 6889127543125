import React from 'react'

function Pin(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : '18px'}
      height={props.height ? props.height : '18px'}
      viewBox="0 0 18 18"
    >
      <path
        d="M1.38429 7.00118C2.62353 8.89502 4.16373 10.78 5.68624 12.311C7.21759 13.842 9.10301 15.373 10.9973 16.612C12.7676 17.7713 15.1045 17.5058 16.6004 16.0102L16.9722 15.6385C17.5387 15.0722 17.4237 14.1075 16.7332 13.7004C15.8038 13.1518 14.8124 12.4792 13.9715 11.9039C13.644 11.6827 13.2368 11.6473 12.8827 11.8154L11.431 12.5057C11.0592 12.6827 10.6078 12.6385 10.2891 12.3818C9.43938 11.7092 8.60731 10.9747 7.81951 10.1871C7.02285 9.39061 6.29701 8.56758 5.62427 7.71801C5.35872 7.39942 5.31446 6.95693 5.4915 6.57639L6.18193 5.12503C6.35012 4.77104 6.31471 4.3551 6.09342 4.03651C5.51805 3.19578 4.84532 2.20461 4.29651 1.26654C3.88933 0.576256 2.93334 0.461209 2.35798 1.02759C2.24291 1.15149 2.11898 1.27539 1.9862 1.39928C0.490259 2.89489 0.233558 5.23123 1.38429 7.00118Z"
        fill={props.color ? props.color : ''}
      />
    </svg>
  )
}

export default Pin
