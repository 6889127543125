import React from 'react'
import { WContainer } from 'components/atoms'

import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
SwiperCore.use([Navigation])

const BoxAziendaSliderItem = ({ item }) => {
  return (
    <div className="box-azienda-slider-item">
      <p className="box-azienda-slider-item__title">{item.titolo}</p>
      <p className="box-azienda-slider-item__subtitle">{item.sottotitolo}</p>
      <div
        className="box-azienda-slider-item__text"
        dangerouslySetInnerHTML={{ __html: item.testo }}
      ></div>
    </div>
  )
}

const BoxAziendaSlider = ({ box }) => {
  return box.slides?.length > 0 ? (
    <div
      className={
        'box-azienda-slider box-azienda-slider--' + box.stile?.toLowerCase().replace('_', '-')
      }
    >
      <WContainer>
        <div className="box-azienda-slider__content">
          {box.slides.map((item, index) => (
            <BoxAziendaSliderItem item={item} key={index} />
          ))}
        </div>
        <div className="box-azienda-slider__slider">
          <Swiper
            slidesPerView={2}
            navigation={true}
            breakpoints={{
              992: {
                slidesPerView: 3,
              },
            }}
            watchOverflow
          >
            {box.slides.map((item, index) => {
              return (
                <SwiperSlide>
                  <BoxAziendaSliderItem item={item} key={index} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default BoxAziendaSlider
