import React from 'react'

function Wishlist(props) {
  return (
    <svg
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--strokes icon--wishlist ' + (props.classes ? props.classes : '')}
    >
      <path
        d="M11.1672 6.66064L12.0005 7.91474L12.8332 6.66024C12.9285 6.51671 13.0316 6.37963 13.142 6.25001C13.9565 5.29354 15.2101 4.6875 16.5 4.6875C17.6329 4.6875 18.6302 5.12897 19.3443 5.84316C20.0585 6.55735 20.5 7.55463 20.5 8.6875C20.5 10.1892 19.8159 11.6249 18.4121 13.3074C17.0801 14.9037 15.2055 16.6024 12.894 18.6969C12.7492 18.8282 12.6026 18.961 12.4544 19.0955L12.4529 19.0968L11.9962 19.5125L11.5588 19.1174C11.5584 19.1171 11.5581 19.1168 11.5578 19.1165C11.4087 18.981 11.2613 18.8471 11.1157 18.7149C8.79475 16.6073 6.91771 14.9028 5.58569 13.3045C4.18159 11.6198 3.5 10.1865 3.5 8.6875C3.5 7.55463 3.94147 6.55735 4.65566 5.84316C5.36985 5.12897 6.36713 4.6875 7.5 4.6875C8.78987 4.6875 10.0435 5.29354 10.858 6.25001C10.9684 6.37963 11.0716 6.51682 11.1672 6.66064Z"
        fill="none"
        strokeWidth="2"
      />
    </svg>
  )
}

export default Wishlist
