import { useQuery } from '@apollo/client'
import { Button, InfiniteLoader } from 'components/atoms'
import { Plus } from 'components/atoms/Icons'
import { IndirizzoCard } from 'components/molecules'
import { useEffect, useState } from 'react'
import { INDIRIZZI_SPEDIZIONE } from 'utils/queries'
import { MyAccountIndirizzoAddForm, MyAccountIndirizzoEditForm } from 'components/organisms'
import { trackingGAevent } from 'utils/gtm'

function SpedizioneCheckout({ formik, classes }) {
  const [indirizzi, setIndirizzi] = useState([])
  const [selected, setSelected] = useState(null)
  const [add, setAdd] = useState(false)
  const [edit, setEdit] = useState(false)

  const { data: indirizziSpedizione, loading } = useQuery(INDIRIZZI_SPEDIZIONE)

  useEffect(() => {
    if (indirizziSpedizione?.indirizzi)
      setIndirizzi(indirizziSpedizione.indirizzi.edges.map(({ node }) => node))
  }, [indirizziSpedizione])

  function handleSelect(value) {
    formik.setFieldValue('indirizzoSpedizione', value)
  }

  return (
    <section className={'spedizione-checkout ' + classes}>
      <p className="spedizione-checkout__title">Indirizzo di spedizione</p>
      {loading ? (
        <InfiniteLoader classes="icon--loader loading" />
      ) : indirizzi.length ? (
        <div className="spedizione-checkout__list">
          {indirizzi?.map((indirizzo) =>
            edit && selected === indirizzo.pk ? (
              <MyAccountIndirizzoEditForm
                indirizzo={indirizzo}
                setEdit={setEdit}
                checkout
                key={indirizzo.pk}
              />
            ) : (
              !edit && (
                <IndirizzoCard
                  indirizzo={indirizzo}
                  selectable
                  onSelect={() => handleSelect(indirizzo.pk)}
                  editable
                  onEdit={() => {
                    setSelected(indirizzo.pk)
                    setEdit(true)
                  }}
                  checked={formik.values.indirizzoSpedizione === indirizzo.pk}
                  key={indirizzo.pk}
                  checkout
                />
              )
            )
          )}
        </div>
      ) : (
        <p className="spedizione-checkout__empty">Non hai ancora aggiunto nessun indirizzo</p>
      )}
      {add ? (
        <MyAccountIndirizzoAddForm isFatturazione={false} checkout setEdit={setAdd} />
      ) : (
        !edit && (
          <a
            onClick={() => {
              trackingGAevent(33, 'shipping_address', 'add', 'checkout')
            }}
          >
            <Button
              label="Aggiungi indirizzo"
              size="md"
              responsive
              type="secondary"
              icon={<Plus />}
              classes="spedizione-checkout__add-button"
              onClick={() => setAdd(true)}
            />
          </a>
        )
      )}
    </section>
  )
}

export default SpedizioneCheckout
