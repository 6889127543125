import React, { useState } from 'react'
import { Filters as FiltersIcon, Remove } from 'components/atoms/Icons'
import { FormCheckbox, Button, Chip, FormSelect } from 'components/atoms'
import { useTrans, useIsMobile } from 'utils/hooks'
import { trackingGAevent } from 'utils/gtm'

const Filters = ({ attributi, ordinamenti, onChange, onChangeOrdinamento }) => {
  const t = useTrans()
  const isMobile = useIsMobile()
  const [open, setOpen] = useState(false)

  return attributi?.length ? (
    <div className="filters">
      <div className="filters__head">
        <div className="filters__filters">
          <Button
            label={
              open
                ? t('Nascondi filtri')
                : ordinamenti?.length && isMobile
                ? t('Filtra e ordina')
                : t('Mostra filtri')
            }
            size="md"
            type="ghost"
            icon={<FiltersIcon />}
            onClick={() => setOpen(!open)}
            classes="filters__toggle-button"
          />
          {attributi.map((attr) => {
            return attr.valori_disponibili
              .filter((v) => v.selezionato && v.value !== null)
              .map((v) => (
                <Chip
                  label={v.label}
                  removable
                  key={'filter-chip_' + attr.name + '-' + v.value}
                  onClick={() => {
                    onChange('remove', attr.name, v.value)
                    trackingGAevent(25, 'filter', 'delete', v.label)
                  }}
                />
              ))
          })}
        </div>
        {ordinamenti?.length && (
          <div className="filters__orders">
            <span className="filters__orders__label">{t('Ordina per')}</span>
            <FormSelect
              name="ordinamento_desktop"
              onChange={(selectedOption) => {
                onChangeOrdinamento(selectedOption.value)
                trackingGAevent(26, 'sorting', 'click', selectedOption.label)
              }}
              options={ordinamenti}
            />
          </div>
        )}
      </div>
      {open && <div className="filters__overlay" onClick={() => setOpen(false)}></div>}
      <div className={'filters__tendina' + (open ? ' filters__tendina--open' : '')}>
        <span className="filters__close" onClick={() => setOpen(false)}>
          <Remove />
        </span>
        <div className="filters__list">
          {ordinamenti?.length && (
            <div className="filters__item filters__item--order">
              <p className="filters__item-title">{t('Ordina per')}</p>
              <div className="filters__values">
                {ordinamenti.map((v) => (
                  <FormCheckbox
                    key={'order_' + (v.value ? v.value : '0')}
                    label={v.label}
                    name={'ordinamento_mobile'}
                    value={v.value === null ? '' : v.value.toString()}
                    onChange={() => onChangeOrdinamento(v.value)}
                    checked={v.selected}
                    radioStyle
                    className="filters__value"
                  />
                ))}
              </div>
            </div>
          )}
          {attributi.map((attr) =>
            attr.valori_disponibili.length > 0 ? (
              <div className="filters__item" key={'filter-' + attr.name}>
                <p className="filters__item-title">{attr.label}</p>
                <div className="filters__values">
                  {attr.valori_disponibili.map((v) => (
                    <FormCheckbox
                      key={'filter-value_' + attr.name + '-' + (v.value ? v.value : '0')}
                      label={v.label}
                      name={attr.name}
                      value={v.value === null ? '' : v.value.toString()}
                      onChange={() => {
                        onChange('toggle', attr.name, v.value)
                        if (!v.selezionato) {
                          // cambiato con !v.selezionato negato perchè prima era true al click e false alla deselezione ed ora dopo
                          let eventLabel = ''
                          attr.valori_disponibili.forEach((filtro) => {
                            if (filtro.selezionato) eventLabel += filtro.label + '-'
                          })
                          trackingGAevent(
                            12,
                            'filter',
                            attr.label,
                            eventLabel ? v.label + '-' + eventLabel.slice(0, -1) : v.label
                          )
                        } else {
                          trackingGAevent(25, 'filter', 'delete', v.label)
                        }
                      }}
                      checked={v.selezionato}
                      radioStyle
                      className="filters__value"
                      disabled={v.disabilitato}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default Filters
