import React from 'react'

function Check(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--check ' + (props.classes ? props.classes : '')}
    >
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2072 7.20718L9.50008 18.9143L2.79297 12.2072L4.20718 10.793L9.50008 16.0859L19.793 5.79297L21.2072 7.20718Z"
      />
    </svg>
  )
}

export default Check
