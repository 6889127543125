import { useMutation } from '@apollo/client'
import {
  Button,
  Divider,
  FormInput,
  NotificationBar,
  FormCheckbox,
  FormInputPassword,
  FormField,
} from 'components/atoms'
import { Facebook, Google } from 'components/atoms/Icons'
import { Form, Formik } from 'formik'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { FACEBOOK_APP_ID, GOOGLE_APP_ID, LOGIN_FORBID_TIME, LOGIN_ATTEMPT_MESSAGE } from 'utils'
import { GET_TOKEN, VERIFY_TOKEN, SOCIAL_LOGIN } from 'utils/queries'
// import GoogleLogin from 'react-google-login'
import axios from 'axios'
import { useGoogleLogin } from '@react-oauth/google'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useTrans } from 'utils/hooks'
import { trackingGAevent } from 'utils/gtm'

function FormLogin({ classes = 'form-login' }) {
  const router = useRouter()
  const t = useTrans()

  const [error, setError] = useState('')
  const [blocked, setBlocked] = useState(false)
  const [initialValues, setInitialValues] = useState({
    email: '',
    password: '',
    remember: false,
  })

  const [getToken, { data: getPayload, loading: getLoading, error: getError }] = useMutation(
    GET_TOKEN,
    { errorPolicy: 'all' }
  )
  const [verifyToken, { data: verifyPayload, loading: verifyLoading, error: verifyError }] =
    useMutation(VERIFY_TOKEN, { errorPolicy: 'all' })

  const [socialLogin, { data: socialPayload, loading: socialLoading, error: socialError }] =
    useMutation(SOCIAL_LOGIN, { errorPolicy: 'all' })

  async function handleGetToken(values) {
    if (values.remember) {
      localStorage.setItem('remember', true)
      localStorage.setItem('email', values.email)
      localStorage.setItem('password', values.password)
    }

    const resp = await getToken({
      variables: {
        email: values.email,
        password: values.password,
      },
    })

    if (resp.errors?.length) {
      const blocked = resp.errors[0].context?.blocked
      setBlocked(blocked)
    }
  }

  async function handleVerify(token) {
    await verifyToken({
      variables: {
        token,
      },
    })
  }

  function prepareGoogleLogin(response) {
    if (response.error) {
      if (response.error !== 'popup_closed_by_user') setError('Si è verificato un errore.')
    } else if (response.access_token) {
      handleSocialLogin('google-oauth2', response.access_token)
    }
  }

  function prepareFacebookLogin(response) {
    console.log(response)

    if (response.status) {
      if (response.status !== 'unknown') setError('Si è verificato un errore.')
    } else if (response.accessToken) {
      handleSocialLogin('facebook', response.accessToken)
    }
  }

  async function handleSocialLogin(provider, accessToken) {
    if (error) setError('')

    await socialLogin({
      variables: {
        provider,
        accessToken,
      },
    })

    trackingGAevent(6, 'user', 'social_login', '')
  }

  async function handleLogin(token) {
    localStorage.setItem('jwt', token)

    trackingGAevent(6, 'user', 'login', '')
    router.push(router.query?.next || '/')
  }

  function failureGoogleLogin(response) {
    setError('Si è verificato un errore.')
  }

  const loginGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => prepareGoogleLogin(tokenResponse),
    onError: (credentialResponse) => failureGoogleLogin(credentialResponse),
    scope: 'profile email',
  })

  useEffect(() => {
    if (getPayload?.tokenAuth) handleVerify(getPayload?.tokenAuth.token)
  }, [getPayload?.tokenAuth])

  useEffect(() => {
    if (verifyPayload?.verifyToken) handleLogin(getPayload.tokenAuth.token)
  }, [verifyPayload?.verifyToken])

  useEffect(() => {
    if (socialPayload?.socialAuth) handleLogin(socialPayload.socialAuth.token)
  }, [socialPayload?.socialAuth])

  useEffect(() => {
    if (getError) setError(getError.message)
  }, [getError])

  useEffect(() => {
    if (verifyError || socialError) setError('Si è verificato un errore.')
  }, [verifyError, socialError])

  function initValues() {
    const email = localStorage.getItem('email') || ''
    const password = localStorage.getItem('password') || ''
    const remember = localStorage.getItem('remember') || false

    setInitialValues({ email, password, remember: JSON.parse(remember) })
  }

  useEffect(() => {
    initValues()
    router.prefetch(router.query?.next || '/')
    const check = setInterval(checkBlocked, 1000)
    return () => clearInterval(check)
  }, [])

  function checkBlocked() {
    const timestamp = JSON.parse(localStorage.getItem('login-blocked'))

    if (blocked) return
    else if (timestamp) {
      if (timestamp + LOGIN_FORBID_TIME > Date.now()) {
        setError(t(LOGIN_ATTEMPT_MESSAGE))
        setBlocked(true)
      } else {
        localStorage.removeItem('login-blocked')
        if (error.includes(LOGIN_ATTEMPT_MESSAGE)) setError('')
        setBlocked(false)
      }
    } else {
      localStorage.removeItem('login-blocked')
      if (error.includes(LOGIN_ATTEMPT_MESSAGE)) setError('')
      setBlocked(false)
    }
  }

  useEffect(() => {
    handleBlock()
  }, [blocked])

  function handleBlock() {
    if (blocked) localStorage.setItem('login-blocked', JSON.stringify(Date.now()))
    else checkBlocked()
  }

  return (
    <>
      <Formik
        validateOnChange={true}
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => {
          setError('')
          handleGetToken(values)
        }}
      >
        {(props) => (
          <Form className={classes}>
            <section className="form-login__dati">
              <div className={`form-login__error ${error ? 'visible' : 'hidden'}`}>
                <NotificationBar type="error" placeholder={error} />
              </div>
              <FormField>
                <FormInput
                  name="email"
                  placeholder={t('Email')}
                  value={props.values.email}
                  status={props.errors.email ? 'error' : ''}
                  errorMessage={props.errors.email}
                  onChange={props.handleChange}
                  disabled={blocked}
                />
              </FormField>
              <FormField>
                <FormInputPassword
                  name="password"
                  placeholder={t('Password')}
                  value={props.values.password}
                  status={props.errors.password ? 'error' : ''}
                  errorMessage={props.errors.password}
                  onChange={props.handleChange}
                  disabled={blocked}
                />
              </FormField>
            </section>
            <section className="form-login__remember">
              <FormCheckbox
                name="remember"
                label={t('Ricordati di me')}
                checked={props.values.remember}
                onChange={props.handleChange}
              />
              <Link legacyBehavior href="/recupera-password">
                <a>{t('Password dimenticata?')}</a>
              </Link>
            </section>
            <section className="form-login__submit">
              <Button
                label={t('Accedi')}
                disabled={getLoading || verifyLoading || blocked}
                inputType="submit"
                classes="form-login__submit__accedi"
              />
              <p className="form-login__submit__registrati">
                {t('Non hai ancora un account?')}{' '}
                <Link legacyBehavior href="/registrazione">
                  <a onClick={() => trackingGAevent(8, 'user', 'registration_step1')}>
                    {t('Registrati')}
                  </a>
                </Link>
              </p>
            </section>
            <Divider />
          </Form>
        )}
      </Formik>
      <section className="social-login">
        <FacebookLogin
          appId={FACEBOOK_APP_ID}
          callback={prepareFacebookLogin}
          fields="first_name,last_name,email"
          isMobile={false}
          render={(renderProps) => (
            <Button
              type="social"
              classes={`facebook`}
              onClick={renderProps.onClick}
              label={t('Accedi con Facebook')}
              icon={<Facebook />}
            />
          )}
        />
        {/*<GoogleLogin*/}
        {/*  clientId={GOOGLE_APP_ID}*/}
        {/*  render={(renderProps) => (*/}
        {/*    <Button*/}
        {/*      type="social"*/}
        {/*      classes={`google`}*/}
        {/*      onClick={renderProps.onClick}*/}
        {/*      label={t('Accedi con Google')}*/}
        {/*      icon={<Google />}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*  onSuccess={prepareGoogleLogin}*/}
        {/*  onFailure={prepareGoogleLogin}*/}
        {/*  cookiePolicy={'single_host_origin'}*/}
        {/*/>*/}
        <Button
          type="social"
          classes={`google`}
          onClick={() => loginGoogle()}
          label={t('Accedi con Google')}
          icon={<Google />}
        />
      </section>
    </>
  )
}

export default FormLogin
