import React from 'react'

function Facebook({ classes = 'icon--facebook' }) {
  return (
    <svg className={'icon ' + classes} viewBox="0 0 24 24" fill="none">
      <mask
        id="mask0"
        masktype="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <path d="M0 0H23.9396V23.7992H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9698 0C5.35907 0 0 5.35907 0 11.9698C0 17.9558 4.39406 22.9151 10.1326 23.7992V15.5651H7.05173V11.9698H10.1326V8.9682C10.1326 8.9682 10.0769 4.51436 14.6422 4.51436C14.6422 4.51436 16.5947 4.62184 17.3467 4.77747V7.86331H15.6093C15.6093 7.86331 14.0273 8.07077 14.0273 9.34145V11.9698H17.3467L16.905 15.5651H14.0273V23.7599C19.657 22.7839 23.9396 17.8785 23.9396 11.9698C23.9396 5.35907 18.5806 0 11.9698 0Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default Facebook
