import { Minus, Plus } from 'components/atoms/Icons'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import { CartContext } from 'utils/context'

const QuantityHandler = ({ value, prodotto, inputDisabled = true }) => {
  const cart = useContext(CartContext)
  const addToCart = cart ? cart.addToCart : () => {}

  const router = useRouter()

  const waType = router.asPath.includes('cart') ? 'modifiche_cart' : 'popup'

  return (
    <div className="quantity-handler">
      <button
        className="quantity-handler__button quantity-handler__button--minus"
        onClick={() => addToCart({ ...prodotto, type: waType }, -1, false)}
      >
        <Minus />
      </button>
      <input
        className="quantity-handler__input"
        type="number"
        value={value}
        disabled={inputDisabled}
      />
      <button
        className="quantity-handler__button quantity-handler__button--plus"
        onClick={() => addToCart({ ...prodotto, type: waType }, 1, false)}
      >
        <Plus />
      </button>
    </div>
  )
}

export default QuantityHandler
