import Link from 'next/link'
import { Fragment } from 'react'
import { useTrans } from 'utils/hooks'

function ProdottiBundle({ prodotti, isBundle }) {
  const t = useTrans()

  return (
    <section className="prodotti-bundle">
      <h3 className="prodotti-bundle__title">{t('All’interno del kit')}</h3>
      <main className="prodotti-bundle__list">
        {prodotti.map((p, index) => (
          <Fragment key={index}>
            {isBundle ? (
              <article className="prodotti-bundle__list__item">
                <Link legacyBehavior href={p.url}>
                  <a className="prodotti-bundle__list__item__title">{p.titolo}</a>
                </Link>
                <p className="prodotti-bundle__list__item__description">{p.descrizione}</p>
              </article>
            ) : (
              <article className="prodotti-bundle__list__item">
                <p className="prodotti-bundle__list__item__title">{p.nome}</p>
                <div
                  className="prodotti-bundle__list__item__description"
                  dangerouslySetInnerHTML={{ __html: p.descrizione }}
                />
              </article>
            )}
          </Fragment>
        ))}
      </main>
    </section>
  )
}

export default ProdottiBundle
