import React from 'react'

function HeartFilled(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--strokes icon--heart-filled ' + (props.classes ? props.classes : '')}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 3.5C17.9 3.5 19.1511 4.04819 20.0514 4.94855C20.9518 5.84892 21.5 7.1 21.5 8.5C21.5 12.1774 18.1043 15.1345 13.1261 19.6488L11.9987 20.675L10.8863 19.67C5.88459 15.1248 2.5 12.1713 2.5 8.5C2.5 7.1 3.04819 5.84892 3.94855 4.94855C4.84892 4.04819 6.1 3.5 7.5 3.5C9.09309 3.5 10.6215 4.24245 11.6193 5.41417C11.7559 5.57458 11.883 5.74353 12.0001 5.9197C12.117 5.74353 12.2441 5.57458 12.3807 5.41417C13.3785 4.24245 14.9069 3.5 16.5 3.5Z"
        strokeWidth="2"
      />
    </svg>
  )
}

export default HeartFilled
