import React from 'react'

function Close({ classes = 'icon--close', onClick }) {
  return (
    <svg
      className={'icon icon--strokes ' + classes}
      fill="none"
      viewBox="0 0 24 24"
      onClick={onClick}
    >
      <path d="M20.1118 4.1123L4.11184 20.1123" strokeWidth="2" />
      <path d="M20.1118 20.1123L4.11184 4.11233" strokeWidth="2" />
    </svg>
  )
}

export default Close
