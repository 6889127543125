import React, { useEffect } from 'react'
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps'

const GoogleMapWrap = ({
  mapCoords = {
    lat: 42.94813463083979,
    lng: 12.442669572953918,
  },
  zoom = 16,
  items,
  children,
  options,
}) => {
  const refs = { map: undefined }

  useEffect(() => {
    if (window?.google) {
      let bounds = new window.google.maps.LatLngBounds()

      if (items?.length) {
        items.forEach((item) => {
          if (item)
            bounds.extend(
              new window.google.maps.LatLng(Number(item.latitudine), Number(item.longitudine))
            )
        })
        // bounds.extend(new window.google.maps.LatLng(mapCoords.lat, mapCoords.lng))
        refs.map.fitBounds(bounds)
      }
    }
  }, [items])

  useEffect(() => {
    if (window?.google) {
      refs.map.panTo(mapCoords)
    }
  }, [mapCoords])

  return (
    <GoogleMap
      ref={(ref) => (refs.map = ref)}
      defaultZoom={zoom}
      defaultCenter={mapCoords}
      options={options}
    >
      {children}
    </GoogleMap>
  )
}

export default withScriptjs(withGoogleMap(GoogleMapWrap))
