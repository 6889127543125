import { Facebook, Instagram, Logo } from 'components/atoms/Icons'
import { WContainer } from 'components/atoms'
import { NewsletterBox } from 'components/organisms'
import Link from 'next/link'
import { trackingGAevent } from 'utils/gtm'
import { useTrans } from 'utils/hooks'

const menu = [
  { label: 'Contatti', url: '/contatti' },
  { label: 'FAQs', url: '/faq' },
  { label: 'Privacy policy', url: '/privacy' },
  { label: 'Cookie policy', url: '/cookie' },
  { label: 'Condizioni di vendita', url: '/condizioni-vendita' },
]

function Footer() {
  const t = useTrans()

  return (
    <footer className="footer">
      <WContainer>
        <div className="footer__row footer__row--first">
          <section className="footer__logo">
            <a href="/">
              <Logo />
            </a>
          </section>
          <section className="footer__social">
            <a href="https://www.facebook.com/matisparisItalia/" target="_blank">
              <Facebook />
            </a>
            <a href="https://www.instagram.com/matisparisitalia/" target="_blank">
              <Instagram />
            </a>
          </section>
        </div>
        <div className="footer__row footer__row--second">
          <section className="footer__contact">
            <p className="footer__contact__company">MB Divisione Cosmetica S.p.A.</p>
            <p>Via Regina Pacis, 74 - 41049 Sassuolo</p>
          </section>
          <section className="footer__newsletter">
            <NewsletterBox />
          </section>
        </div>
        <div className="footer__row footer__row--third">
          <nav className="footer__menu">
            {menu.map((m) => (
              <Link legacyBehavior href={m.url} key={m.label}>
                <a
                  className="footer__menu__item"
                  onClick={() => {
                    trackingGAevent(21, 'footer', 'click', m.label)
                  }}
                >
                  {m.label}
                </a>
              </Link>
            ))}
            <div
              onClick={(e) => {
                e.preventDefault()
                Cookiebot.renew()
              }}
            >
              Rinnova o modifica la tua autorizzazione ai cookie
            </div>
          </nav>
        </div>
        <div className="footer__row footer__row--fourth">
          <section className="footer__data">
            <p>
              Codice Fiscale, Partita IVA e Registro Imprese n. 01640810360
              <br /> R.E.A. di. Modena n. 232471 - Capitale Sociale € 800.000,00 I.V. - Tutti i
              diritti sono riservati.
            </p>
          </section>
        </div>
      </WContainer>
    </footer>
  )
}

export default Footer
