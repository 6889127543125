import { Button, WImage } from 'components/atoms'

function BoxMarketing2({ box, noPadding, onClick = () => {} }) {
  return (
    <div
      className={
        `box-marketing-2 ` +
        `box-marketing-2--image-v-${
          box.imageVerticalPosition ? box.imageVerticalPosition.toLowerCase() : 'top'
        } ` +
        `box-marketing-2--image-h-${
          box.imageHorizontalPosition ? box.imageHorizontalPosition.toLowerCase() : 'left'
        } ` +
        `box-marketing-2--content-${
          box.contentStyle ? box.contentStyle.toLowerCase() : 'default'
        } ` +
        `box-marketing-2--bg-${
          box.backgroundStyle ? box.backgroundStyle.toLowerCase() : 'beige'
        } ` +
        `${noPadding ? '' : 'box-marketing-2--padded'}`
      }
    >
      <div className="box-marketing-2__bg">
        <div className="box-marketing-2__container">
          <div className="box-marketing-2__image-box">
            <WImage
              src={box.imageThumb}
              maxWidth={560}
              maxHeight={800}
              classPrefix="box-marketing-2"
              lazyLoad
            />
            {box.contentStyle?.toLowerCase() === 'highlight' && (
              <p className="box-marketing-2__title">{box.titolo}</p>
            )}
          </div>
          <div className="box-marketing-2__content">
            {box.contentStyle?.toLowerCase() !== 'highlight' && (
              <p className="box-marketing-2__title">{box.titolo}</p>
            )}
            <div
              className="box-marketing-2__text"
              dangerouslySetInnerHTML={{ __html: box.testo }}
            />
            {box.finalUrl && (
              <Button
                href={box.finalUrl}
                label={box.cta ? box.cta : 'Scopri la linea'}
                type="primary"
                classes="box-marketing-2__cta"
                onClick={onClick}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BoxMarketing2
