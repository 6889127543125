import React from 'react'
import { Help } from 'components/atoms/Icons'
import { useTrans } from 'utils/hooks'

const BoxHelp = ({}) => {
  const t = useTrans()
  return (
    <div className="box-help">
      <div className="box-help__container">
        <div className="box-help__icon">
          <Help />
        </div>
        <div className="box-help__content">
          <p className="box-help__title">{t('box-help__titolo')}</p>
          <div
            className="box-help__description"
            dangerouslySetInnerHTML={{ __html: t('box-help__testo') }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default BoxHelp
