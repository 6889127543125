import React from 'react'

const ProgressBarStep = ({ step, status }) => {
  return (
    <span className={'progress-bar__step' + (status ? ' progress-bar__step--' + status : '')}>
      {status === 'active' && <span className="progress-bar__step-label">{step}</span>}
      <span className="progress-bar__step-dot"></span>
    </span>
  )
}

const ProgressBar = ({ steps, activeStepIndex, addLast }) => {
  return (
    <div className="progress-bar">
      {steps?.length &&
        steps.map((step, index) => (
          <ProgressBarStep
            key={index}
            step={step}
            status={index === activeStepIndex ? 'active' : index < activeStepIndex ? 'past' : ''}
          />
        ))}
      {addLast && <ProgressBarStep step={''} />}
    </div>
  )
}

export default ProgressBar
