import React from 'react'
import { Remove } from 'components/atoms/Icons'
import { InfiniteLoader } from 'components/atoms'

const VideoOverlay = ({ video, open, onClose }) => {
  return (
    <div className={'video-overlay' + (open ? ' video-overlay--open' : '')}>
      <div className="video-overlay__content">
        <div className="video-overlay__video-box">
          <InfiniteLoader />
          {/* Carico il video solo quando effettivamente ho aperto il popup */}
          {open && (
            <iframe
              src={
                video.tipoVideo == 'Youtube'
                  ? 'https://www.youtube.com/embed/' + video.url + '?autoplay=1'
                  : 'https://player.vimeo.com/video/' + video.url + '?autoplay=1'
              }
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </div>
      </div>
      <span className="video-overlay__close" onClick={onClose}>
        <Remove />
      </span>
    </div>
  )
}

export default VideoOverlay
