import { Divider, RadioButton } from 'components/atoms'
import React from 'react'

function PagamentoCheckout({ formik, checkout, classes }) {
  return (
    <section className={'pagamento-checkout ' + classes}>
      <p className="pagamento-checkout__title">Riepilogo</p>
      <div className="pagamento-checkout__indirizzi">
        <p>
          <span>Indirizzo di spedizione</span>
          <span>
            {checkout.indirizzoSpedizione.nome} {checkout.indirizzoSpedizione.cognome}
          </span>
          {checkout.indirizzoSpedizione.indirizzo}
          <br />
          {checkout.indirizzoSpedizione.cap} {checkout.indirizzoSpedizione.citta} (
          {checkout.indirizzoSpedizione.provincia}){' '}
          {checkout.indirizzoSpedizione.nazione || 'Italia'}
          <br />
          {checkout.indirizzoSpedizione.consegnaPresso}
          <br />
          {checkout.indirizzoSpedizione.informazioniAggiuntive}
        </p>
        <p>
          <span>Indirizzo di fatturazione</span>
          <span>
            {checkout.indirizzoFatturazione.nome} {checkout.indirizzoFatturazione.cognome}
          </span>
          {checkout.indirizzoFatturazione.indirizzo}
          <br />
          {checkout.indirizzoFatturazione.cap} {checkout.indirizzoFatturazione.citta} (
          {checkout.indirizzoFatturazione.provincia}){' '}
          {checkout.indirizzoFatturazione.nazione || 'Italia'}
          <br />
          {checkout.indirizzoFatturazione.cf}
        </p>
      </div>
      <Divider />
      <p className="pagamento-checkout__title">Metodo di pagamento</p>
      <div className="pagamento-checkout__metodi">
        {checkout.sistemiPagamento.map((sis) => (
          <article key={sis.pk} className="pagamento-checkout__metodi__item">
            <RadioButton
              name="sistemaPagamento"
              value={sis.pk}
              onChange={() => formik.setFieldValue('sistemaPagamento', sis.pk)}
              checked={formik.values.sistemaPagamento === sis.pk}
              label={
                <span className="pagamento-checkout__metodi__item__nome">
                  {sis.nome && (
                    <span className="pagamento-checkout__metodi__item__nome__label">
                      {sis.nome}
                    </span>
                  )}
                  {sis.image && <img src={sis.image} alt={sis.alias || sis.nome} />}
                </span>
              }
            />
            {formik.values.sistemaPagamento === sis.pk && sis.descrizione && (
              <span className="pagamento-checkout__metodi__item__description">
                {sis.descrizione}
              </span>
            )}
          </article>
        ))}
      </div>
    </section>
  )
}

export default PagamentoCheckout
