import { Listing, Page } from 'components/organisms'

function CategoriaTemplate({ categoria, listing, pagina }) {
  const paginaCategoria = {
    ...pagina,
    titolo: categoria.titolo,
    descrizione: categoria.descrizione,
  }
  return (
    <Page pagina={paginaCategoria} classes="categoria-detail">
      <Listing categoria={categoria.pk} initial={listing} classes={'categoria-detail__listing'} />
    </Page>
  )
}

export default CategoriaTemplate
