import React, { useEffect, useState } from 'react'
import { ChevronLeft } from 'components/atoms/Icons'
import { usePrevious } from 'utils/hooks'
import { RICERCA_ELEMENTS_PER_PAGE } from 'utils'

const Paginazione = ({
  loading,
  results,
  reset,
  setReset,
  handlePrevPage,
  handleNextPage,
  currentPage,
}) => {
  const [page, setPage] = useState(currentPage)
  const [numbers, setNumbers] = useState([])
  const prevPage = usePrevious(page)
  const prevReset = usePrevious(reset)

  const getNPagine = (data) => {
    if (data?.totalCount) {
      return Math.ceil(results.totalCount / RICERCA_ELEMENTS_PER_PAGE)
    }
  }

  useEffect(() => {
    if (reset) {
      setPage(1)
      setNumbers([])
      setReset(false)
    }
  }, [reset])

  useEffect(() => {
    if (page > prevPage && !loading && !prevReset) {
      return handleNextPage()
    }
    if (page < prevPage && !loading && !prevReset) {
      return handlePrevPage()
    }
  }, [page])

  useEffect(() => {
    if (results?.totalCount) {
      let nPagine = getNPagine(results)
      if (nPagine > 0) {
        let narr = [...Array(nPagine).keys()]
        if (nPagine > 5) {
          if (page < 4) {
            narr = narr.slice(0, 5)
          }
          if (page >= 4) {
            if (narr.length >= page + 3) {
              narr = narr.slice(page - 2, page + 3)
            } else {
              narr = narr.slice(narr.length - 5, narr.length)
            }
          }
        }
        setNumbers(narr)
      }
    }
  }, [results])

  return (
    <div className="paginazione">
      <a onClick={() => (page > 1 && !loading && results?.count > 0 ? setPage(page - 1) : null)}>
        <ChevronLeft
          classes={
            page > 1
              ? 'paginazione-lefticon'
              : 'paginazione-lefticon paginazione-lefticon--disabled'
          }
        />
      </a>
      {numbers?.length
        ? numbers.map((n, i) => {
            return (
              <div
                key={'paginazione_' + i}
                className={n + 1 === page ? 'number number-active' : 'number'}
              >
                {n + 1}
              </div>
            )
          })
        : null}
      <a onClick={() => (page < getNPagine(results) && !loading ? setPage(page + 1) : null)}>
        <ChevronLeft
          classes={
            page < getNPagine(results)
              ? 'paginazione-righticon'
              : 'paginazione-righticon paginazione-righticon--disabled'
          }
        />
      </a>
    </div>
  )
}

export default Paginazione
