import { useMutation } from '@apollo/client'
import { Button, Modal } from 'components/atoms'
import { Form, Formik } from 'formik'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { UPDATE_UTENTE } from 'utils/queries'
import { useTrans } from 'utils/hooks'
import { SelezionaCentro } from '..'
import { selezionaCentroCheckout } from 'utils/validation'

function FormSelezionaCentroCheckout({ classes = 'form-seleziona-centro-checkout' }) {
  const router = useRouter()
  const t = useTrans()

  const [errors, setErrors] = useState(false)

  const [updateUtente, { loading: mutationLoading }] = useMutation(UPDATE_UTENTE, {
    errorPolicy: 'all',
  })

  async function handleSubmit(values, setSubmitting) {
    setSubmitting(true)

    const response = await updateUtente({
      variables: {
        input: {
          centroEstetico: values.centro,
        },
      },
      refetchQueries: ['Me'],
    })

    if (response.errors && response.errors.length) {
      setErrors(response.errors[0])
    } else {
      router.push('/cart/checkout')
    }

    setSubmitting(false)
  }

  return (
    <Formik
      validateOnChange={true}
      validationSchema={selezionaCentroCheckout}
      initialValues={{
        centro: '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting)
      }}
    >
      {(props) => (
        <Form className={classes}>
          <SelezionaCentro
            formik={props}
            classes="form-seleziona-centro-checkout__section form-seleziona-centro-checkout__centro"
          />
          <section className="form-seleziona-centro-checkout__submit">
            <Button
              label="Vai al checkout"
              disabled={!props.isValid || mutationLoading || props.isSubmitting}
              inputType="submit"
            />
          </section>
          <Modal open={errors} setOpen={() => setErrors(false)}>
            <p className="form-seleziona-centro-checkout__error">{errors}</p>
          </Modal>
        </Form>
      )}
    </Formik>
  )
}

export default FormSelezionaCentroCheckout
