import React from 'react'

function Google({ classes = 'icon--google' }) {
  return (
    <svg className={'icon ' + classes} viewBox="0 0 24 24" fill="none">
      <path d="M23.7799 9.76931H18.9291H12.2497V14.0106H18.9884C18.1164 17.0497 15.3143 19.2762 11.9958 19.2762C7.9746 19.2762 4.71534 16.0169 4.71534 11.9958C4.71534 7.9746 7.9746 4.71534 11.9958 4.71534C13.8413 4.71534 15.5259 5.40952 16.8042 6.54392L20.2243 3.28466C18.0741 1.25291 15.1873 0 11.9958 0C5.37566 0 0 5.37566 0 11.9958C0 18.6243 5.37566 24 11.9958 24C17.9386 24 22.8487 19.6825 23.8138 14.0106C23.9238 13.3587 23.9915 12.6815 23.9915 11.9958C24 11.2339 23.9153 10.4974 23.7799 9.76931Z" />
    </svg>
  )
}

export default Google
