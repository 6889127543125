export default function reducer(
  state = {
    utente: null,
    request: false,
  },
  action
) {
  switch (action.type) {
    case 'SET_ME': {
      return {
        ...state,
        utente: action.payload.data?.me,
        request: true,
      }
    }

    default: {
      return state
    }
  }
}
