import React from 'react'
import { WContainer } from 'components/atoms'
import { Breadcrumbs } from 'components/molecules'
import Head from 'next/head'

const BoxHero = ({ title, text, image, breadcrumbs, isHome = false }) => {
  return (
    <div
      className="box-hero"
      style={{
        backgroundImage: 'url(' + (image ? image : 'https://via.placeholder.com/1920x1080') + ')',
      }}
    >
      {image ? (
        <Head>
          <link rel="preload" href={image} as="image" />
        </Head>
      ) : null}
      {breadcrumbs && breadcrumbs.length && (
        <div className="box-hero__breadcrumbs">
          <WContainer>
            <Breadcrumbs breadcrumbs={breadcrumbs} white />
          </WContainer>
        </div>
      )}
      <div className="box-hero__content">
        {title ? (
          isHome ? (
            <p className="box-hero__title">{title}</p>
          ) : (
            <h1 className="box-hero__title">{title}</h1>
          )
        ) : null}
        {text && (
          <div className="box-hero__description" dangerouslySetInnerHTML={{ __html: text }}></div>
        )}
      </div>
    </div>
  )
}

export default BoxHero
