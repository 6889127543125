import React from 'react'

function Edit({ classes }) {
  return (
    <svg
      className={'icon icon--strokes ' + classes}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5193 7.51933L17 3L6.02447 13.9755L10.5438 18.4949L21.5193 7.51933Z"
        fill="none"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M6.02448 13.9756L4.41043 20.109L10.5438 18.4949"
        strokeWidth="2"
        fill="none"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Edit
