import React from 'react'

function Search(props) {
  return (
    <svg
      viewBox="0 0 24 25"
      fill="none"
      className={'icon icon--strokes icon--search ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <path
        d="M10 17.1875C13.866 17.1875 17 14.0535 17 10.1875C17 6.32151 13.866 3.1875 10 3.1875C6.13401 3.1875 3 6.32151 3 10.1875C3 14.0535 6.13401 17.1875 10 17.1875Z"
        fill="none"
        strokeWidth="2"
      />
      <path d="M21.0833 21.2708L15 15.1875" fill="none" strokeWidth="2" />
    </svg>
  )
}

export default Search
