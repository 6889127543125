import React from 'react'
import { Edit } from 'components/atoms/Icons'
import { Button } from 'components/atoms'
import { trackingGAevent } from 'utils/gtm'

const MyAccountBlock = ({ title, edit, setEdit, children, submitting, myAccount = false }) => {
  return (
    <div className="myaccount-block">
      {title && (
        <div className="myaccount-block__head">
          <p className="myaccount-block__title">{title}</p>
          {edit === true ? (
            <div className="myaccount-block__actions">
              <Button
                label={'Annulla modifiche'}
                disabled={submitting}
                type="ghost"
                size="sm"
                onClick={() => setEdit(false)}
              />

              <Button label={'Salva'} type="primary" inputType="submit" size="sm" />
            </div>
          ) : edit === false ? (
            <a
              onClick={() => {
                myAccount ? trackingGAevent(36, 'my_account', 'negozi_preferiti', 'modifica') : null
              }}
            >
              <Button
                label="Modifica"
                disabled={submitting}
                icon={<Edit />}
                type="ghost"
                size="sm"
                onClick={() => setEdit(true)}
              />
            </a>
          ) : null}
        </div>
      )}
      {children}
    </div>
  )
}

export default MyAccountBlock
