import React, { useEffect, useState } from 'react'
import { Close } from 'components/atoms/Icons'
import { useTrans } from 'utils/hooks'

const PreHeader = ({}) => {
  const [close, setClose] = useState(false)

  const t = useTrans()

  const onClose = () => {
    sessionStorage.setItem('preheaderClosed', true)
    setClose(true)
  }

  useEffect(() => {
    const flag = sessionStorage.getItem('preheaderClosed')
    if (flag !== close) setClose(flag)
  }, [])

  // Gestione del contenuto del preheader dalle traduzioni?
  const preheaderContent = t('preheader') === 'preheader' ? '' : t('preheader')

  return preheaderContent && !close ? (
    <div className={'preheader' + (!close ? ' preheader--open' : '')}>
      <button className="preheader__close" onClick={onClose}>
        <Close />
      </button>
      <div className="preheader__content">
        <p dangerouslySetInnerHTML={{ __html: preheaderContent }}></p>
      </div>
    </div>
  ) : null
}

export default PreHeader
