import React from 'react'
import { useTrans } from 'utils/hooks'

const BoxHelpContatti = ({}) => {
  const t = useTrans()
  return (
    <div className="box-help-contatti">
      <div className="w-container">
        <p className="box-help-contatti__title">{t('box-help-contatti__titolo')}</p>
        <p className="box-help-contatti__subtitle">{t('box-help-contatti__sottotitolo')}</p>
        <div
          className="box-help-contatti__description"
          dangerouslySetInnerHTML={{ __html: t('box-help-contatti__testo') }}
        />
      </div>
    </div>
  )
}

export default BoxHelpContatti
