import React from 'react'

function Cart(props) {
  return (
    <svg
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--strokes icon--cart ' + (props.classes ? props.classes : '')}
    >
      <path
        d="M4.06889 21.1875L4.93555 8.1875H19.0644L19.9311 21.1875H4.06889Z"
        fill="none"
        strokeWidth="2"
      />
      <path
        d="M8 8.1875V7.1875C8 4.97836 9.79086 3.1875 12 3.1875C14.2091 3.1875 16 4.97836 16 7.1875V8.1875"
        fill="none"
        strokeWidth="2"
      />
    </svg>
  )
}

export default Cart
