import React, { useEffect } from 'react'
import { ApolloProvider, gql } from '@apollo/client'
import { Layout } from 'components/index'
import Router, { useRouter } from 'next/router'
import NProgress from 'nprogress'
import 'public/styles/sass/components/_nprogress.sass'
import 'public/styles/sass/main.sass'
import { wrapper } from 'store'
import { useApollo, initializeApollo, GQL_HOST } from 'utils/apollo'
import CartContextProvider from 'utils/context'
import { initGTMdataLayer, updateGTMDataLayer } from 'utils/gtm'
import { useSelector, useStore } from 'react-redux'
import { setPagina } from 'containers/pagina'
import { api, isEmpty, GOOGLE_APP_ID, FACEBOOK_PIXEL_ID } from 'utils'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { CHECK_REVALIDATE } from 'utils/endpoint'
// import * as Sentry from '@sentry/browser'

NProgress.configure({ showSpinner: false })

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

// Sentry.init({
//   enabled: process.env.NODE_ENV !== 'development',
//   dsn: 'https://d516f5f3f8e440479e9eb8583e1e37c6@o19983.ingest.sentry.io/5449735',
// })

const Greenboxshop = ({ Component, pageProps, cookies }) => {
  const router = useRouter()
  const storage = useStore((state) => state)
  const apolloStore = useApollo(pageProps, cookies)

  const { pagina } = useSelector((state) => state.pagina)
  const { utente, request } = useSelector((state) => state.utente)

  const layoutProps = {
    loginRequired: Component.loginRequired || false,
    withHeader: Component.withHeader !== undefined ? Component.withHeader : true,
    withPreHeader: Component.withPreHeader !== undefined ? Component.withPreHeader : false,
    withFooter: Component.withFooter !== undefined ? Component.withFooter : true,
    centroRequired: Component.centroRequired !== undefined ? Component.centroRequired : false,
    item: pageProps?.item,
    page: pageProps?.pagina,
  }

  useEffect(() => {
    initGTMdataLayer()
  }, [])

  async function reactPixelInit() {
    const { default: ReactPixel } = await import('react-facebook-pixel')
    ReactPixel.init(FACEBOOK_PIXEL_ID, null, {
      autoConfig: true,
      debug: true,
    })
    ReactPixel.pageView()
    ReactPixel.track('ViewContent')
  }

  useEffect(() => {
    reactPixelInit()
  }, [])

  useEffect(() => {
    if (isEmpty(pageProps)) {
      storage.dispatch(setPagina({ pagina: { chiave: '404', titolo: '404' } }))
    } else {
      storage.dispatch(setPagina(pageProps))
    }
  }, [router.asPath])

  async function waitForData() {
    const client = initializeApollo()

    const { data: cartData } = await client.query({
      query: gql`
        query {
          cart {
            numProdotti
          }
        }
      `,
      fetchPolicy: 'network-only',
    })

    const { data: wishlistData } = await client.query({
      query: gql`
        query {
          wishlist {
            numProdotti
          }
        }
      `,
      fetchPolicy: 'network-only',
    })

    updateGTMDataLayer(
      pagina,
      utente,
      cartData?.cart.numProdotti,
      wishlistData?.wishlist.numProdotti,
      router
    )
  }

  useEffect(() => {
    if (!isEmpty(pagina) && request) waitForData()
  }, [pagina, request])

  useEffect(() => {
    if (pageProps?.revalidate)
      handleCheckRevalidate(router.asPath.split('?')?.[0], pageProps.revalidate)
  }, [pageProps?.revalidate])

  const handleCheckRevalidate = async (path, revalidate) => {
    await api.get(CHECK_REVALIDATE, { params: { path, revalidate } })
  }

  return (
    <ApolloProvider client={apolloStore}>
      <CartContextProvider>
        <GoogleOAuthProvider clientId={GOOGLE_APP_ID}>
          <Layout {...layoutProps} key={layoutProps.loginRequired}>
            <Component {...pageProps} />
          </Layout>
        </GoogleOAuthProvider>
      </CartContextProvider>
    </ApolloProvider>
  )
}

export default wrapper.withRedux(Greenboxshop)
