import React from 'react'
import { ProdottoOrdine } from 'components/molecules'
import { priceNotation } from 'utils/safe'

const OrdineRiepilogo = ({ ordine }) => {
  return (
    <div className="ordine-riepilogo">
      <div className="ordine-riepilogo__section ordine-riepilogo__section--prodotti">
        {ordine.righeOrdine.map((riga, index) => (
          <ProdottoOrdine rigaOrdine={riga} key={index} />
        ))}
      </div>
      <div className="ordine-riepilogo__section">
        <div className="ordine-riepilogo__price-row">
          <span className="ordine-riepilogo__label">Subtotale</span>
          <span className="ordine-riepilogo__price">
            {priceNotation(ordine.totaleProdottiScontato)}
          </span>
        </div>
        {ordine.promozione ? (
          <div className="ordine-riepilogo__price-row ordine-riepilogo__price-row--promo">
            <span className="ordine-riepilogo__subtotale__label">
              Sconto {ordine.promozione.alias}
            </span>
            <span className="ordine-riepilogo__subtotale__price">
              - {priceNotation(ordine.promozioneValoreAssoluto)}
            </span>
          </div>
        ) : null}
        <div className="ordine-riepilogo__price-row">
          <span className="ordine-riepilogo__spedizione__label">Spedizione</span>
          <span className="ordine-riepilogo__spedizione__price">
            {ordine.costoSpedizione === 0 ? 'Gratuita' : priceNotation(ordine.costoSpedizione)}
          </span>
        </div>
      </div>
      <div className="ordine-riepilogo__section">
        <div className="ordine-riepilogo__price-row ordine-riepilogo__price-row--totale">
          <span className="ordine-riepilogo__label">Totale</span>
          <span className="ordine-riepilogo__price">
            {priceNotation(ordine.prezzoTotaleScontato)}
          </span>
        </div>
      </div>
    </div>
  )
}

export default OrdineRiepilogo
