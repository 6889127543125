import { ChevronRight } from 'components/atoms/Icons'
import React from 'react'

const BreadcrumbItem = ({ titolo, url, last = false, white = false, linea = false }) => {
  return (
    <div className={`breadcrumb__step ${white ? 'breadcrumb__step--white' : ''}`}>
      {last ? (
        <span className={'breadcrumb__step__label breadcrumb__step__label--last'}>
          {linea ? 'Linea ' : ''}
          {titolo}
        </span>
      ) : (
        <a className={'breadcrumb__step__label '} href={url || '/'}>
          {titolo}
        </a>
      )}
      {!last && <ChevronRight classes="breadcrumb__step__icon" />}
    </div>
  )
}

export default BreadcrumbItem
