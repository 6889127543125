import React from 'react'
import { Button } from 'components/atoms'

const MyAccountForm = ({
  title,
  children,
  onCancel,
  onSave,
  add,
  centro,
  cancelLabel,
  saveLabel,
  submitting,
  checkout = false,
}) => {
  const classes = `myaccount-form--${add ? 'add' : 'edit'} ${
    checkout ? 'myaccount-form--checkout' : ''
  } ${centro ? 'form-centroestetico' : ''}`

  return (
    <div className={'myaccount-form ' + classes}>
      {title && <p className="myaccount-form__title">{title}</p>}
      <div className="myaccount-form__content">{children}</div>

      <div className="myaccount-form__actions">
        <Button
          label={cancelLabel ? cancelLabel : add ? 'Annulla' : 'Annulla modifiche'}
          disabled={submitting}
          type="ghost"
          size="sm"
          onClick={onCancel}
        />

        <Button
          label={saveLabel ? saveLabel : 'Salva'}
          disabled={submitting}
          type={checkout ? 'secondary' : 'primary'}
          size="sm"
          onClick={onSave}
          inputType="submit"
        />
      </div>
    </div>
  )
}

export default MyAccountForm
