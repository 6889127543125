import React from 'react'

function Exit(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--strokes icon--pin2 ' + (props.classes ? props.classes : '')}
    >
      <line fill="none" x1="15" y1="12" x2="3" y2="12" strokeWidth="2" />
      <path fill="none" d="M7.5 16.5L3 12L7.5 7.5" strokeWidth="2" />
      <path fill="none" d="M11 9V4H21V20H11V15" strokeWidth="2" />
    </svg>
  )
}

export default Exit
