import React from 'react'

function ArrowRight({ classes = '' }) {
  return (
    <svg
      className={'icon icon--strokes icon--arrow-right ' + classes}
      fill="none"
      viewBox="0 0 24 24"
    >
      <line x1="4" y1="11.5" x2="20" y2="11.5" strokeWidth="2" />
      <path d="M15.5 7L20 11.5L15.5 16" strokeWidth="2" />
    </svg>
  )
}

export default ArrowRight
