import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { FormLayout, FormField, FormRadio, FormCheckbox } from 'components/atoms'
import { MyAccountBlock } from 'components/molecules'
import { UPDATE_UTENTE } from 'utils/queries'

const FormPrivacy = ({ utente, setEdit }) => {
  const initialValues = {
    privacy: utente.privacy,
    privacyCommerciale: utente.privacyCommerciale,
  }

  const [updateUtente, { data }] = useMutation(UPDATE_UTENTE)

  async function handleSubmit(values, setSubmitting) {
    setSubmitting(true)

    const response = await updateUtente({
      variables: {
        input: {
          privacy: values.privacy,
          privacyCommerciale:
            typeof values.privacyCommerciale === 'string'
              ? parseInt(values.privacyCommerciale)
              : values.privacyCommerciale,
        },
      },
      refetchQueries: ['Me'],
    })

    setSubmitting(false)
    setEdit(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting)
      }}
    >
      {({ values, errors, handleChange, isSubmitting, setFieldValue }) => (
        <Form>
          <MyAccountBlock
            title="Informativa sulla privacy"
            submitting={isSubmitting}
            edit={true}
            setEdit={(val) => {
              setEdit(val)
            }}
          >
            <FormLayout>
              <FormField size="full" space={'lg'}>
                <FormCheckbox
                  name="privacy"
                  checked={values.privacy}
                  label="Fornisco il consenso per la registrazione come indicato nell’informativa"
                  onChange={handleChange}
                />
              </FormField>
              <FormField size="full" space={'none'}>
                <FormRadio
                  name="privacyCommerciale"
                  onChange={(e) => {
                    setFieldValue('privacyCommerciale', e.target.value)
                  }}
                  value={values.privacyCommerciale}
                  description={
                    'Fornisco il consenso per finalità di Marketing come indicato nell’ Informativa e dichiaro di avere compiuto almeno 14 anni.'
                  }
                  options={[
                    { label: 'SI', value: 1 },
                    { label: 'NO', value: 0 },
                  ]}
                />
              </FormField>
            </FormLayout>
          </MyAccountBlock>
        </Form>
      )}
    </Formik>
  )
}

export default FormPrivacy
