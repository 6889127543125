import { Button, InfiniteLoader } from 'components/atoms'
import { ChevronLeft } from 'components/atoms/Icons'
import { Steps } from 'components/molecules'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { FatturazioneCheckout, PagamentoCheckout, SpedizioneCheckout } from 'components/organisms'
import { useMutation } from '@apollo/client'
import { SET_CHECKOUT, SET_INDIRIZZI } from 'utils/queries'
import { useRouter } from 'next/router'
import { trackingCheckout } from 'utils/gtm'

const controls = [
  {
    prev: { label: 'Torna al carrello', href: '/cart' },
    next: { label: 'Vai alla fatturazione', href: null },
  },
  {
    prev: { label: 'Torna alla spedizione', href: null },
    next: { label: 'Vai al pagamento', href: null },
  },
  {
    prev: { label: 'Torna alla fatturazione', href: null },
    next: { label: 'Procedi al pagamento', href: null },
  },
]

const requiredFields = ['indirizzoSpedizione', 'indirizzoFatturazione', 'sistemaPagamento']

function FormCheckout({ checkout, classes }) {
  const router = useRouter()

  const [step, setStep] = useState(1)

  const [setIndirizzi, { data: indirizziPayload, loading: indirizziLoading }] =
    useMutation(SET_INDIRIZZI)
  const [setCheckout, { data, loading, error }] = useMutation(SET_CHECKOUT, { errorPolicy: 'all' })

  const callbacks = [setSpedizione, setFatturazione, setPagamento]

  function setSpedizione(formik) {
    setStep(2)
  }

  async function setFatturazione(formik) {
    const { indirizzoSpedizione, indirizzoFatturazione } = formik.values

    await setIndirizzi({
      variables: {
        indirizzoSpedizione,
        indirizzoFatturazione,
      },
      refetchQueries: ['Checkout'],
    })
  }

  function setPagamento(formik) {
    formik.handleSubmit()
  }

  function renderControls(formik) {
    const index = step - 1
    const { prev, next } = controls[index]

    return (
      <section className="form-checkout__controls">
        {prev.href ? (
          <Button
            href={prev.href}
            label={prev.label}
            icon={<ChevronLeft />}
            type="ghost"
            responsive
          />
        ) : (
          <Button
            onClick={() => setStep(step - 1)}
            label={prev.label}
            icon={<ChevronLeft />}
            responsive
            type="ghost"
          />
        )}
        <Button
          onClick={() => {
            callbacks[index](formik)
            trackingCheckout(checkout, step + 1, formik.values)
          }}
          disabled={!formik.values[requiredFields[index]] || formik.isSubmitting}
          responsive
          label={next.label}
        />
      </section>
    )
  }

  async function handleSubmit(values) {
    const { sistemaPagamento } = values

    await setCheckout({
      variables: {
        sistemaPagamento,
      },
    })
  }

  function handleRedirect(response) {
    if (response.url) {
      localStorage.setItem('checkout', JSON.stringify(checkout)) // per TYP
      window.location.href = response.url // force SSR
    }
  }

  useEffect(() => {
    if (data?.setCheckout) handleRedirect(data?.setCheckout)
  }, [data?.setCheckout])

  useEffect(() => {
    if (indirizziPayload?.setIndirizzi && indirizziPayload?.setIndirizzi.status) setStep(3)
  }, [indirizziPayload?.setIndirizzi])

  return (
    <section className={'form-checkout ' + classes}>
      <Formik
        validateOnChange={true}
        enableReinitialize={true}
        initialValues={{
          indirizzoSpedizione: checkout.indirizzoSpedizione?.pk || null,
          indirizzoFatturazione: checkout.indirizzoFatturazione?.pk || null,
          sistemaPagamento: checkout.sistemaPagamento?.pk || null,
        }}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {(props) => (
          <Form className={classes}>
            <Steps
              step={step}
              steps={['Spedizione', 'Fatturazione', 'Pagamento']}
              classes="form-checkout__steps"
            />
            {loading || indirizziLoading || props.isSubmitting ? (
              <InfiniteLoader classes="icon--loader loading" />
            ) : step === 1 ? (
              <SpedizioneCheckout formik={props} classes="form-checkout__spedizione" />
            ) : step === 2 ? (
              <FatturazioneCheckout formik={props} classes="form-checkout__fatturazione" />
            ) : (
              <PagamentoCheckout
                formik={props}
                checkout={checkout}
                classes="form-checkout__pagamento"
              />
            )}
            {renderControls(props)}
          </Form>
        )}
      </Formik>
    </section>
  )
}

export default FormCheckout
