import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { FormLayout, FormField, FormInput, Button, FormSelect } from 'components/atoms'
import MyAccountForm from '../MyAccountForm/MyAccountForm'
import { Bin } from 'components/atoms/Icons'
import { editIndirizzoSpedizione, editIndirizzoFatturazione } from 'utils/validation'
import { DELETE_INDIRIZZO, UPDATE_INDIRIZZO } from 'utils/queries'
import { trackingGAevent } from 'utils/gtm'
import italia from 'utils/italia'

const MyAccountIndirizzoEditForm = ({
  indirizzo,
  setEdit,
  checkout = false,
  fatturazione = false,
}) => {
  const [updateIndirizzo, { data: dataUpdateIndirizzo }] = useMutation(UPDATE_INDIRIZZO)
  const [deleteIndirizzo, { data: dataDeleteIndirizzo }] = useMutation(DELETE_INDIRIZZO)

  const [cap, setCap] = useState(indirizzo.cap ? [indirizzo.cap] : [])
  const [province, setProvince] = useState(indirizzo.provincia ? [indirizzo.provincia] : [])

  const initialValues = {
    nome: indirizzo.nome,
    cognome: indirizzo.cognome,
    indirizzo: indirizzo.indirizzo,
    informazioniAggiuntive: indirizzo.informazioniAggiuntive,
    consegnaPresso: indirizzo.consegnaPresso,
    citta: indirizzo.citta,
    cap: indirizzo.cap,
    provincia: indirizzo.provincia,
    telefono: indirizzo.telefono,
    cf: indirizzo.cf,
  }

  async function handleSubmit(values, setSubmitting) {
    setSubmitting(true)

    const response = await updateIndirizzo({
      variables: {
        input: {
          pk: indirizzo.pk,
          nome: values.nome,
          cognome: values.cognome,
          indirizzo: values.indirizzo,
          informazioniAggiuntive: values.informazioniAggiuntive,
          consegnaPresso: values.consegnaPresso,
          citta: values.citta,
          cap: values.cap,
          provincia: values.provincia,
          telefono: values.telefono,
          cf: values.cf,
        },
      },
      refetchQueries: ['IndirizziSpedizione', 'IndirizziFatturazione'],
    })

    setSubmitting(false)
    setEdit(false)
  }

  async function handleDelete() {
    const response = await deleteIndirizzo({
      variables: {
        input: {
          pk: indirizzo.pk,
        },
      },
      refetchQueries: ['IndirizziSpedizione', 'IndirizziFatturazione'],
    })

    setEdit(false)
  }

  function handleChangeCitta(citta, setFieldValue) {
    const cap = italia
      .filter((c) => c.comune === citta)
      .map((c) => c.cap)
      .flat()

    const province = italia.filter((c) => c.comune === citta).map((c) => c.provincia)

    setCap(cap)
    setProvince(province)

    if (cap.length) setFieldValue('cap', cap[0])
    if (province.length) setFieldValue('provincia', province[0])
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        indirizzo.isFatturazione ? editIndirizzoFatturazione : editIndirizzoSpedizione
      }
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting)
      }}
      enableReinitialize
    >
      {({ values, errors, handleChange, isSubmitting, setFieldValue, status, handleSubmit }) => (
        <Form>
          <MyAccountForm
            title="Modifica indirizzo"
            onCancel={() => setEdit(false)}
            onSave={handleSubmit}
            submitting={isSubmitting}
            checkout={checkout}
          >
            <FormLayout>
              <FormField>
                <FormInput
                  name="nome"
                  value={values.nome}
                  placeholder="Nome"
                  onChange={handleChange}
                  errorMessage={errors.nome}
                  status={errors.nome ? 'error' : null}
                />
              </FormField>
              <FormField>
                <FormInput
                  name="cognome"
                  value={values.cognome}
                  placeholder="Cognome"
                  onChange={handleChange}
                  errorMessage={errors.cognome}
                  status={errors.cognome ? 'error' : null}
                />
              </FormField>
              <FormField>
                <FormInput
                  name="indirizzo"
                  value={values.indirizzo}
                  placeholder="Indirizzo"
                  onChange={handleChange}
                  errorMessage={errors.indirizzo}
                  status={errors.indirizzo ? 'error' : null}
                />
              </FormField>
              {!indirizzo.isFatturazione && (
                <FormField>
                  <FormInput
                    name="consegnaPresso"
                    value={values.consegnaPresso}
                    placeholder={'Consegna presso (se azienda)'}
                    onChange={handleChange}
                  />
                </FormField>
              )}
              <FormField>
                <FormInput
                  name="informazioniAggiuntive"
                  value={values.informazioniAggiuntive}
                  placeholder={'Informazioni aggiuntive'}
                  onChange={handleChange}
                />
              </FormField>
              <FormField>
                <FormSelect
                  name="citta"
                  options={italia.map(({ comune }) => ({
                    value: comune,
                    label: comune,
                    selected: comune === values.citta,
                  }))}
                  placeholder="Città"
                  onChange={({ value }) => {
                    setFieldValue('citta', value)
                    handleChangeCitta(value, setFieldValue)
                  }}
                  errorMessage={errors.citta}
                  status={errors.citta ? 'error' : null}
                  isSearchable
                />
              </FormField>
              <FormField size="sm">
                <FormSelect
                  name="cap"
                  options={cap.map((cap) => ({
                    value: cap,
                    label: cap,
                    selected: cap === values.cap,
                  }))}
                  placeholder="CAP"
                  onChange={({ value }) => setFieldValue('cap', value)}
                  errorMessage={errors.cap}
                  status={errors.cap ? 'error' : null}
                />
              </FormField>
              <FormField size="sm">
                <FormSelect
                  name="provincia"
                  options={province.map((provincia) => ({
                    value: provincia,
                    label: provincia,
                    selected: provincia === values.provincia,
                  }))}
                  placeholder="Provincia*"
                  onChange={({ value }) => setFieldValue('provincia', value)}
                  errorMessage={errors.provincia}
                  status={errors.provincia ? 'error' : null}
                />
              </FormField>
              <FormField>
                <FormInput
                  name="telefono"
                  value={values.telefono}
                  placeholder={'Telefono*'}
                  onChange={handleChange}
                  errorMessage={errors.telefono}
                  status={errors.telefono ? 'error' : null}
                />
              </FormField>
              {indirizzo.isFatturazione && (
                <FormField>
                  <FormInput
                    name="cf"
                    value={values.cf}
                    placeholder={'Codice fiscale'}
                    onChange={handleChange}
                    errorMessage={errors.cf}
                    status={errors.cf ? 'error' : null}
                  />
                </FormField>
              )}
            </FormLayout>
            {!checkout && (
              <a
                onClick={() => {
                  fatturazione
                    ? null
                    : trackingGAevent(33, 'shipping_address', 'delete', 'my_account')
                }}
              >
                <Button
                  label="Elimina indirizzo"
                  type="ghost"
                  size="md"
                  icon={<Bin />}
                  classes="myaccount-indirizzi__delete-button"
                  onClick={handleDelete}
                />
              </a>
            )}
          </MyAccountForm>
        </Form>
      )}
    </Formik>
  )
}

export default MyAccountIndirizzoEditForm
