import { Button, Label } from 'components'
import { WImage } from 'components/atoms'
import { Brush, Heart, HeartFilled } from 'components/atoms/Icons'
import Link from 'next/link'
import { useContext, useState } from 'react'
import { CartContext } from 'utils/context'
import { useTrans, useWishlist } from 'utils/hooks'
import { priceNotation } from 'utils/safe'
import { trackingGAevent, trackingProductClick, getPageType } from 'utils/gtm'

function ProdottoCard({
  prodotto,
  correlato,
  waPosition = 1,
  waList = '',
  lazyLoad = true,
  preLoad = false,
}) {
  const [wishlist, setWishlist] = useState(prodotto.wishlist ? prodotto.wishlist : false)

  const context = useContext(CartContext)
  const addToCart = context ? context.addToCart : () => {}

  const handleWishlist = useWishlist()
  const t = useTrans()

  function handleClickWishlist() {
    handleWishlist(wishlist, prodotto).then((response) => {
      setWishlist(response)
    })

    if (!wishlist)
      trackingGAevent(
        37,
        'product',
        'add_to_wishlist',
        'product_listing',
        undefined,
        prodotto?.codice
      )
    else
      trackingGAevent(
        38,
        'product',
        'remove_from_wishlist',
        getPageType() == 'wishlist' ? 'wishlist' : 'product_listing',
        undefined,
        prodotto?.codice
      )
  }

  function handleProductClick() {
    trackingProductClick(prodotto, waPosition, waList)
  }

  return (
    <article className={'prodotto-card' + (correlato ? ' prodotto-card--correlato' : '')}>
      <section className="prodotto-card__top">
        <div className="prodotto-card__top__side">
          {prodotto.novita && <Label type="neutral" label="Novità" />}
          {prodotto.promoApplicata?.descrizione ? (
            <Label type="primary" label={prodotto.promoApplicata.descrizione} />
          ) : prodotto.scontoPercentuale ? (
            <Label type="primary" label={`-${prodotto.scontoPercentuale}%`} />
          ) : null}
          {prodotto.promoTags &&
            prodotto.promoTags.map((tag, index) => (
              <Label key={index} colore={tag.colore} label={tag.testo} />
            ))}
        </div>
        <button className="prodotto-card__top__wishlist" onClick={handleClickWishlist}>
          {wishlist ? <HeartFilled /> : <Heart />}
        </button>
      </section>
      <Link legacyBehavior href={prodotto.url}>
        <a className="prodotto-card__image-box" onClick={handleProductClick}>
          <WImage
            src={prodotto.prodottoCardImageThumb}
            alt={`${prodotto.titolo} ${prodotto.sottotitolo || ''}`.toAltTitle()}
            title={`${prodotto.titolo} ${prodotto.sottotitolo || ''}`.toAltTitle()}
            maxWidth={220}
            maxHeight={220}
            classPrefix="prodotto-card"
            lazyLoad={lazyLoad}
            preLoad={preLoad}
          />
        </a>
      </Link>
      <Brush
        color={prodotto.linea?.colore}
        classes="prodotto-card__brush"
        title={prodotto.linea?.nome}
      />
      <Link legacyBehavior href={prodotto.url}>
        <a className="prodotto-card__title" onClick={handleProductClick}>
          {prodotto.titolo}
        </a>
      </Link>
      <p className="prodotto-card__subtitle">{prodotto.sottotitolo}</p>
      <section className="prodotto-card__price">
        {prodotto.disponibile ? (
          prodotto.prezzoScontato !== prodotto.prezzo && prodotto.prezzoScontato ? (
            <>
              <del>{priceNotation(prodotto.prezzo)}</del>
              {'  '}
              {priceNotation(prodotto.prezzoScontato)}
            </>
          ) : (
            priceNotation(prodotto.prezzoScontato)
          )
        ) : (
          <span className="prodotto-card__price__unavailable">{t('Non disponibile')}</span>
        )}
      </section>
      {!correlato && prodotto.disponibile && (
        <Button
          type="primary"
          label="Aggiungi al carrello"
          classes="prodotto-card__cta"
          onClick={() => addToCart({ ...prodotto, position: waPosition, list: waList }, 1)}
        />
      )}
    </article>
  )
}

export default ProdottoCard
