import { useMutation, useQuery } from '@apollo/client'
import { Modal } from 'components/atoms'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useTrans } from './hooks'
import { CART, ADD_TO_CART } from './queries'
import { trackingAddRemoveFromCart } from 'utils/gtm'

export const initialCart = { items: [], numProdotti: 0, totaleProdottiScontato: 0 }

export const CartContext = React.createContext(initialCart)

const CartContextProvider = (props) => {
  const router = useRouter()
  const t = useTrans()

  const { loading, data, refetch } = useQuery(CART, { fetchPolicy: 'network-only' })

  const [
    addItem,
    { data: addItemPayload, loading: addItemLoading, error: addItemError },
  ] = useMutation(ADD_TO_CART)

  const [cart, setCart] = useState(initialCart)
  const [showCart, setShowCart] = useState(false)
  const [message, setMessage] = useState('')

  const isLoading = loading || addItemLoading

  useEffect(() => {
    if (data) initCart(data.cart)
  }, [data])

  useEffect(() => {
    if (showCart) closeCart()
    refetch()
  }, [router.asPath])

  function initCart(cart) {
    setCart(cart)
  }

  async function addToCart(item, quantita = 1, show = true) {
    const response = await addItem({
      variables: {
        item: item.pk,
        quantita,
      },
    })

    const {
      data: {
        addItem: { status, message },
      },
    } = response

    if (status) {
      refetch()
      if (show) openCart()
    } else {
      setMessage(message)
    }

    trackingAddRemoveFromCart(item, quantita)
  }

  function openCart() {
    setShowCart(true)
  }

  function closeCart() {
    setShowCart(false)
  }

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        isLoading,
        showCart,
        openCart,
        closeCart,
      }}
    >
      {props.children}
      <Modal open={message} setOpen={() => setMessage('')}>
        <h3 className="modal__body__title">{t('Errore carrello')}</h3>
        <p className="modal__body__text">{message}</p>
      </Modal>
    </CartContext.Provider>
  )
}

export default CartContextProvider
