import { useMutation } from '@apollo/client'
import React, { useState, useEffect } from 'react'
import { Form, Formik } from 'formik'
import {
  FormLayout,
  FormField,
  FormRadio,
  FormCheckbox,
  FormInput,
  NotificationBar,
} from 'components/atoms'
import { MyAccountBlock } from 'components/molecules'
import { UPDATE_PASSWORD } from 'utils/queries'
import { cambiaPassword } from 'utils/validation'

const FormPassword = ({ utente, setEdit }) => {
  const [error, setError] = useState('')

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  }

  const [updatePassword, { data }] = useMutation(UPDATE_PASSWORD, {
    errorPolicy: 'all',
  })

  async function handleSubmit(values, setSubmitting) {
    setSubmitting(true)

    const response = await updatePassword({
      variables: {
        input: {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
        },
      },
      refetchQueries: ['Me'],
    })

    setSubmitting(false)
    if (!response.data.updatePassword.message) {
      setEdit(false)
    }
  }

  useEffect(() => {
    if (data) {
      setError(data.updatePassword.message)
    }
  }, [data])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={cambiaPassword}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting)
      }}
    >
      {({ values, errors, handleChange, isSubmitting, setFieldValue }) => (
        <Form>
          <MyAccountBlock
            title="Dati di accesso"
            submitting={isSubmitting}
            edit={true}
            setEdit={(val) => {
              setEdit(val)
            }}
          >
            <FormLayout>
              <FormField>
                <FormInput
                  name="oldPassword"
                  value={values.oldPassword}
                  placeholder="Vecchia password"
                  type="password"
                  onChange={handleChange}
                  errorMessage={errors.oldPassword}
                  status={errors.oldPassword ? 'error' : null}
                />
              </FormField>
              <FormField>
                <FormInput
                  name="newPassword"
                  value={values.newPassword}
                  placeholder="Nuova password"
                  type="password"
                  onChange={handleChange}
                  errorMessage={errors.newPassword}
                  status={errors.newPassword ? 'error' : null}
                />
              </FormField>
              <FormField>
                <FormInput
                  name="confirmPassword"
                  value={values.confirmPassword}
                  placeholder="Conferma nuova password"
                  type="password"
                  onChange={handleChange}
                  errorMessage={errors.confirmPassword}
                  status={errors.confirmPassword ? 'error' : null}
                />
              </FormField>
            </FormLayout>
            {error && error != '' && (
              <NotificationBar
                type="error"
                placeholder={error}
                classes={`form-password__error ${error ? 'visible' : ''}`}
              />
            )}
          </MyAccountBlock>
        </Form>
      )}
    </Formik>
  )
}

export default FormPassword
