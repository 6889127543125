function Label(props) {
  const { type = 'primary', label = null, colore = null, classes = '' } = props

  if (colore) {
    return (
      <button className={`label ${classes}`} style={{ backgroundColor: `#${colore}` }}>
        {label}
      </button>
    )
  }
  return <button className={`label label__${type} ${classes}`}>{label}</button>
}

export default Label
