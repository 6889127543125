import { InfiniteLoader, MetaSeo } from 'components/atoms'
import { Logo } from 'components/atoms/Icons'

function CheckoutLayout({ pagina, data, loading, main, side, classes = '' }) {
  return (
    <main className={'checkout ' + classes}>
      <MetaSeo seo={pagina.seo} />
      {loading ? (
        <InfiniteLoader classes="icon--loader loading" />
      ) : data ? (
        <>
          <a href="/" className="checkout__main__image__mobile">
            <Logo />
          </a>
          <section className="checkout__side">{side}</section>
          <section className="checkout__main">
            <div className="checkout__main__container">
              <a href="/" className="checkout__main__image">
                <Logo />
              </a>
              {main}
            </div>
          </section>
        </>
      ) : null}
    </main>
  )
}

export default CheckoutLayout
