import React from 'react'
import Link from 'next/link'
import { WImage } from 'components/atoms'

const LineaHomeCard = ({ linea, onClick = () => {} }) => {
  return (
    <Link legacyBehavior href={linea.url}>
      <a className={'linea-home-card'} onClick={onClick}>
        <div className="linea-home-card__image-box">
          <WImage
            src={linea.lineaHomeCardImageThumb}
            alt={linea.nome.toAltTitle()}
            title={linea.nome.toAltTitle()}
            maxWidth={272}
            maxHeight={355}
            classPrefix="linea-home-card"
            lazyLoad
          />
          <WImage
            src={linea.lineaHomeCardImageHoverThumb}
            alt={linea.nome.toAltTitle()}
            title={linea.nome.toAltTitle()}
            maxWidth={272}
            maxHeight={355}
            classPrefix="linea-home-card"
            className="linea-home-card__image--hover"
            lazyLoad
          />
        </div>
        <div className="linea-home-card__content">
          <div className="linea-home-card__title">{linea.nome}</div>
        </div>
      </a>
    </Link>
  )
}

export default LineaHomeCard
