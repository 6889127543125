import { BoxMarketing, BoxQuote, MetaSeo, ProdottoCard } from 'components'
import { Brush } from 'components/atoms/Icons'
import { WContainer, WImage } from 'components/atoms'
import { Breadcrumbs } from 'components/molecules'
import Head from 'next/head'
import { useEffect } from 'react'
import { trackingProductImpression } from 'utils/gtm'
import { useTrans } from 'utils/hooks'

function LineaTemplate({ linea, pagina }) {
  const boxQuote = linea.boxQuote.length ? linea.boxQuote[0] : null
  const t = useTrans()

  const primoBoxMarketing = linea.boxMarketing.length ? linea.boxMarketing[0] : null
  const secondoBoxMarketing =
    linea.boxMarketing.length && linea.boxMarketing.length > 1 ? linea.boxMarketing[1] : null

  useEffect(() => {
    trackingProductImpression(linea?.prodotti, 'linea')
  }, [])

  return (
    <main className="page linea-detail">
      <Head>
        <link rel="preload" href={linea.image} as="image" />
      </Head>
      <MetaSeo seo={pagina.seo} />
      <div className="linea-detail__top">
        <div className="linea-detail__breadcrumbs">
          <Breadcrumbs breadcrumbs={pagina.breadcrumbs} linea />
        </div>
        <figure className="linea-detail__hero">
          <WImage
            src={linea.image}
            maxWidth={1920}
            maxHeight={980}
            alt={linea.nome.toAltTitle()}
            title={linea.nome.toAltTitle()}
            classPrefix="linea-detail__hero"
          />
        </figure>
      </div>
      <section className="linea-detail__launch">
        <WContainer inner>
          <Brush color={linea.colore} classes="linea-detail__launch__brush" title={linea.nome} />
          <h1 className="linea-detail__launch__title">{linea.nome}</h1>
          <div
            className="linea-detail__launch__description"
            dangerouslySetInnerHTML={{ __html: linea.descrizione }}
          />
        </WContainer>
      </section>
      {primoBoxMarketing || boxQuote ? (
        <section className="linea-detail__marketing">
          {primoBoxMarketing && <BoxMarketing box={primoBoxMarketing} />}
          {boxQuote && <BoxQuote box={boxQuote} />}
          {secondoBoxMarketing && <BoxMarketing box={secondoBoxMarketing} />}
        </section>
      ) : null}
      <WContainer>
        <section className="linea-detail__listing">
          <h2 className="linea-detail__listing__title">I prodotti della linea</h2>
          <main className="linea-detail__listing__list row">
            {linea.prodotti?.length ? (
              linea.prodotti.map((p, index) => (
                <div className="col-6 col-md-4" key={p.pk}>
                  <ProdottoCard prodotto={p} waPosition={index + 1} waList="linea" />
                </div>
              ))
            ) : (
              <div className="linea-detail__listing__empty">{t('Nessun prodotto disponibile')}</div>
            )}
          </main>
        </section>
      </WContainer>
    </main>
  )
}

export default LineaTemplate
