import { StepItem } from 'components/atoms'

function Steps({ steps = [], step = 1, classes = '' }) {
  return (
    <section className={`steps ${classes}`}>
      {steps.map((item, index) => (
        <StepItem
          titolo={item}
          last={index === steps.length - 1}
          num={index + 1}
          disabled={step < index + 1}
          active={step === index + 1}
          key={index}
        />
      ))}
    </section>
  )
}

export default Steps
