import React from 'react'

function Pin(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : '15px'}
      height={props.height ? props.height : '20px'}
      viewBox="0 0 15 20"
    >
      <g clipPath="url(#pin-clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1778 11.2415C13.9573 10.0901 14.4126 8.70139 14.4126 7.2063C14.4126 3.22637 11.1862 0 7.2063 0C3.22637 0 0 3.22637 0 7.2063C0 8.70164 0.455456 10.0906 1.23521 11.242L1.23444 11.2423L7.21532 20L13.1805 11.2423L13.1778 11.2415Z"
          fill={props.color ? props.color : ''}
        />
        <path
          d="M7.30745 10.3428C8.85969 10.3428 10.118 9.08448 10.118 7.53225C10.118 5.98001 8.85969 4.72168 7.30745 4.72168C5.75522 4.72168 4.49689 5.98001 4.49689 7.53225C4.49689 9.08448 5.75522 10.3428 7.30745 10.3428Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="pin-clip0">
          <rect width="14.4148" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Pin
