import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Formik, Form } from 'formik'
import {
  Button,
  FormInput,
  FormCheckbox,
  FormField,
  InfiniteLoader,
  NotificationBar,
  FormRadio,
} from 'components/atoms'
import { newsletterBox } from 'utils/validation'
import { useTrans } from 'utils/hooks'
import { INSERT_UTENTE_NEWSLETTER } from 'utils/queries'
import { trackingGAevent } from 'utils/gtm'

const NewsletterBox = ({}) => {
  const [statusRichiesta, setStatusRichiesta] = useState(null)
  const t = useTrans()

  const [createUtenteNewsletter, { loading }] = useMutation(INSERT_UTENTE_NEWSLETTER, {
    onCompleted({ createUtenteNewsletter }) {
      setStatusRichiesta(createUtenteNewsletter)
    },
  })

  const initialValues = {
    email: '',
    privacy: false,
  }

  async function handleCreateRichiesta(values) {
    const response = await createUtenteNewsletter({
      variables: {
        input: {
          email: values.email,
        },
      },
    })
    if (response.data.createUtenteNewsletter.status)
      trackingGAevent(30, 'newsletter', 'subscribe', 'footer')

    return response.data.createRichiestaContatto
  }

  const handleSubmit = async (values) => {
    await handleCreateRichiesta(values)
  }

  return (
    <div className="newsletter-box">
      <p className="newsletter-box__title">{t('Iscriviti alla newsletter')}</p>
      {statusRichiesta && statusRichiesta.status ? (
        <NotificationBar
          type="success"
          placeholder={statusRichiesta.message}
          key={statusRichiesta?.status}
        />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={newsletterBox(t)}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {({ values, errors, handleChange, isSubmitting }) => (
            <Form className="newsletter-box__form">
              {statusRichiesta && !statusRichiesta.status && statusRichiesta.message && (
                <NotificationBar
                  type="error"
                  placeholder={statusRichiesta.message}
                  key={statusRichiesta?.status}
                />
              )}
              <FormField
                className="newsletter-box__field newsletter-box__field--email"
                errorMessage={errors.email}
                status={errors.email ? 'error' : null}
              >
                <FormInput
                  name="email"
                  value={values.email}
                  placeholder={t('Inserisci la tua mail')}
                  onChange={handleChange}
                />
              </FormField>
              <div className="newsletter-box__field newsletter-box__field--submit">
                {loading ? (
                  <div className="newsletter-box__loading">
                    <InfiniteLoader />
                  </div>
                ) : (
                  <Button
                    label={t('Invia')}
                    inputType="submit"
                    className="newsletter-box__submit"
                  />
                )}
              </div>
              {/* <FormField
                className="newsletter-box__field newsletter-box__field--privacy"
                errorMessage={errors.privacy}
                status={errors.privacy ? 'error' : null}
              >
                <FormCheckbox
                  name="privacy"
                  size="sm"
                  checked={values.privacy}
                  onChange={handleChange}
                  label={t('newsletter-box__tos')}
                />
              </FormField> */}
              <FormField size="full">
                <FormRadio
                  className="newsletter-box__field newsletter-box__field--privacy"
                  errorMessage={errors.privacy}
                  status={errors.privacy ? 'error' : null}
                  name="privacy"
                  value={values.privacy}
                  options={[
                    { label: t('Sì'), value: 1 },
                    { label: t('No'), value: 0 },
                  ]}
                  description={
                    <>
                      Fornisco il consenso dei miei dati personali per l’iscrizione alla newsletter
                      come indicato nel punto B) dell'<a href="/privacy/">informativa</a>
                    </>
                  }
                  onChange={handleChange}
                />
              </FormField>
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}

export default NewsletterBox
