import React, { useState } from 'react'
import { ArrowRight, Remove, Player } from 'components/atoms/Icons'
import { Button, WImage, ImageBox } from 'components/atoms'
import { trackingGAevent } from 'utils/gtm'

const TrattamentoCardBig = ({ trattamento, onClose, onVideoOpen }) => {
  const adatto_per = trattamento.tipologiePelle.map((pelle) => pelle.title).join(', ')

  const durate = trattamento.durateTrattamento.map((d) => d.title).join(', ')

  return (
    <div className="trattamento-card-big">
      {onClose && (
        <span className="trattamento-card-big__close" onClick={onClose}>
          <Remove />
        </span>
      )}
      {trattamento.videoTrattamento ? (
        <div
          className="trattamento-card-big__image-box trattamento-card-big__image-box--video"
          onClick={onVideoOpen}
        >
          <WImage
            src={
              trattamento.videoTrattamento.image
                ? trattamento.videoTrattamento.image
                : trattamento.videoTrattamento.tipoVideo == 'Youtube'
                ? 'https://img.youtube.com/vi/' +
                  trattamento.videoTrattamento.url +
                  '/hqdefault.jpg'
                : trattamento.videoTrattamento.vimeoImage
            }
            maxWidth={368}
            maxHeight={340}
            alt={trattamento.title.toAltTitle()}
            title={trattamento.title.toAltTitle()}
            classPrefix="trattamento-card-big"
          />
          <span className="trattamento-card-big__video-icon">
            <Player />
          </span>
        </div>
      ) : (
        <ImageBox
          src={trattamento.image}
          maxWidth={368}
          maxHeight={340}
          alt={trattamento.title.toAltTitle()}
          title={trattamento.title.toAltTitle()}
          classPrefix="trattamento-card-big"
        />
      )}
      <div className="trattamento-card-big__content">
        <p className="trattamento-card-big__title">{trattamento.title}</p>
        <p className="trattamento-card-big__abstract">{trattamento.abstract}</p>
        <div className="trattamento-card-big__description">{trattamento.description}</div>
        <div className="trattamento-card-big__details">
          {adatto_per ? (
            <p className="trattamento-card-big__detail-item">
              <span className="trattamento-card-big__detail-title">Adatto per: </span>
              <span className="trattamento-card-big__detail-value">{adatto_per}</span>
            </p>
          ) : null}
          <p className="trattamento-card-big__detail-item">
            <span className="trattamento-card-big__detail-title">Durata trattamento: </span>
            <span className="trattamento-card-big__detail-value">{durate}</span>
          </p>
        </div>
        <div className="trattamento-card-big__buttons">
          <Button
            type="secondary"
            icon={<ArrowRight />}
            iconPos={'right'}
            label="Trova il centro più vicino"
            href="/centri"
            onClick={() =>
              trackingGAevent(45, 'trattamenti', 'trova_il_centro_più_vicino', trattamento.title)
            }
          />
        </div>
      </div>
    </div>
  )
}

export default TrattamentoCardBig
