import { gql } from '@apollo/client'

export const ME = gql`
  query Me {
    me {
      pk
      email
      nome
      cognome
      telefono
      dataNascita
      sesso
      completed
      isStaff
      edit
      privacy
      privacyCommerciale
      dataRegistrazione
      hasCentro
    }
  }
`
// isImpersonate

export const MENU = gql`
  query Menu {
    menuitems(parent_Isnull: true, attivo: true) {
      edges {
        node {
          id
          pk
          alias
          key
          attivo
          name
          image
          url: finalUrl
          children(attivo: true) {
            edges {
              node {
                id
                pk
                alias
                key
                attivo
                name
                image
                url: finalUrl
                children {
                  edges {
                    node {
                      id
                      pk
                      alias
                      key
                      attivo
                      name
                      image
                      url: finalUrl
                      children {
                        edges {
                          node {
                            id
                            pk
                            alias
                            key
                            attivo
                            name
                            image
                            url: finalUrl
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const HOME_SERVER = gql`
  query HomeServer {
    boxHero(chiave: "home") {
      titolo
      testo
      image
    }
    linee(first: 4) {
      edges {
        node {
          nome
          lineaHomeCardImageThumb
          lineaHomeCardImageHoverThumb
          url
        }
      }
    }
  }
`

export const HOME_CLIENT = gql`
  query HomeClient {
    boxMarketing2(chiave: "home") {
      titolo
      testo
      imageThumb
      imageVerticalPosition
      imageHorizontalPosition
      contentStyle
      backgroundStyle
      finalUrl
    }
    boxMarketingProdotti(chiave: "home") {
      titolo
      testo
      imageThumb
      cta
      finalUrl
      prodotti {
        pk
        titolo
        sottotitolo
        codice
        prezzo
        prezzoScontato
        scontoPercentuale
        url
        prodottoCardImageThumb
        disponibile
        novita
        linea {
          nome
          colore
        }
        categoria {
          titolo
        }
        promoApplicata {
          descrizione
        }
        promoTags {
          testo
          colore
        }
      }
    }
    articoliMagazine(first: 3) {
      edges {
        node {
          title
          abstract
          publicationDate
          categoria {
            title
          }
          thumbnailSmall
          url
        }
      }
    }
    boxQuote(chiave: "home") {
      titolo
    }
    prodotti(inHome: true, last: 3) {
      edges {
        node {
          pk
          titolo
          sottotitolo
          prezzo
          prezzoScontato
          url
          prodottoCardImageThumb
        }
      }
    }
  }
`

export const LINEE = gql`
  query Linee {
    linee {
      edges {
        node {
          chiave
        }
      }
    }
  }
`

export const LINEA = `
  query Linea {
    linea(chiave: "__slug__") {
      pk
      nome
      descrizione
      image
      colore
      contentTypeId
      prodotti {
        pk
        titolo
        sottotitolo
        codice
        prodottoCardImageThumb
        url
        prezzo
        prezzoScontato
        scontoPercentuale
        wishlist
        disponibile
        novita
        linea {
          nome
          colore
        }
        categoria {
          titolo
        }
        promoApplicata{
          descrizione
        }
        promoTags {
          testo
          colore
        }
      }
      boxQuote{
        titolo
        colore
      }
      boxMarketing{
        titolo
        testo
        imageThumb
        imagePosition
        cta
        blank
        finalUrl
      }
    }
  }
`

export const CATEGORIE = gql`
  query Categorie {
    categorie {
      edges {
        node {
          slugTree
        }
      }
    }
  }
`

export const MAGAZINE = gql`
  query Magazine {
    articoliMagazine {
      edges {
        node {
          pk
          title
          publicationDate
          abstract
          thumbnailBig
          thumbnailSmall
          url
          categoria {
            title
          }
        }
      }
    }
    categorieArticoli {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`

export const PRODOTTI = gql`
  query Prodotti {
    prodotti(first: 10) {
      edges {
        node {
          slug
        }
      }
    }
  }
`

export const PRODOTTO = `
  query Prodotto {
    prodotto(slug: "__slug__") {
      contentTypeId
      pk
      titolo
      sottotitolo
      codice
      prezzo
      prezzoScontato
      scontoPercentuale
      descrizione
      disponibile
      wishlist
      smallThumb
      logicaCorrelati
      immagini{
        imageThumb
      }
      url
      formato {
        nome
      }
      rituale {
        nome
        image
        descrizione
      }
      bundle
      kit
      prodottiKit {
        nome
        descrizione
      }
      prodottiBundle{
        pk
        titolo
        descrizione
        url
      }
      boxQuote {
        titolo
        colore
      }
      boxMarketing{
        titolo
        testo
        imageThumb
        imagePosition
      }
      principiAttivi{
        nome
        descrizione
        principiAttiviImageThumb
      }
      principiComplementari{
        nome
        descrizione
      }
      consigli{
        nome
        descrizione
        image
      }
      linea {
        nome
        lineaLaunchThumb
        lancio
        colore
        url
      }
      categoria {
        titolo
      }
    }
  }
`

export const PRODOTTI_CORRELATI = `
  query ProdottiCorrelati {
    prodotto(slug: "__slug__") {
      correlati {
        pk
        titolo
        sottotitolo
        codice
        prodottoCardImageThumb
        url
        prezzo
        prezzoScontato
        scontoPercentuale
        wishlist
        disponibile
        novita
        linea {
          colore
          nome
        }
        categoria {
          titolo
        }
        promoApplicata{
          descrizione
        }
        promoTags {
          testo
          colore
        }
      }
    }
  }
`

export const PREZZI_PRODOTTI = gql`
  query PrezziProdotti($idProdotti: [Int]) {
    prezziProdotti(idProdotti: $idProdotti) {
      pk
      prezzoScontato
    }
  }
`

export const TRATTAMENTI = gql`
  query Trattamenti($tipologia: [String], $durata: [String], $zona: [String], $pelle: [String]) {
    trattamenti(
      tipologieTrattamento_Id_In: $tipologia
      durateTrattamento_Id_In: $durata
      zonaTrattamento_Id_In: $zona
      tipologiePelle_Id_In: $pelle
    ) {
      edges {
        node {
          id
          title
          abstract
          description
          image
          slug
          tipologiePelle {
            title
            slug
          }
          durateTrattamento {
            title
            minutes
          }
          tipologieTrattamento {
            title
            slug
          }
          zonaTrattamento {
            title
            slug
          }
          videoTrattamento {
            tipoVideo
            url
            vimeoImage
          }
        }
      }
    }
  }
`

export const TRATTAMENTI_FILTRI = gql`
  query TrattamentiFiltri {
    attributiFiltri {
      slug
      nome
      valori_disponibili: valoriDisponibili {
        pk
        nome
      }
    }
  }
`

export const ARTICOLO_MAGAZINE = gql`
  query ArticoloMagazine($slug: String!, $preview: Boolean) {
    articoloMagazine(slug: $slug, preview: $preview) {
      contentTypeId
      pk
      title
      mainImageThumb
      publicationDate
      pubblicato
      visibile
      categoria {
        title
      }
      immagini {
        file
      }
      prev {
        url
      }
      next {
        url
      }
      correlati {
        pk
        title
        publicationDate
        abstract
        thumbnailSmall
        url
        categoria {
          title
        }
      }
    }
  }
`

export const ARTICOLI_MAGAZINE = gql`
  query ArticoliMagazine {
    articoliMagazine {
      edges {
        node {
          slug
        }
      }
    }
  }
`

export const CENTRI_ESTETICI = gql`
  query CentriEstetici {
    centriEstetici {
      edges {
        node {
          pk
          alias
          ragioneSociale
          indirizzo
          cap
          citta
          provincia
          telefono
          latitudine
          longitudine
        }
      }
    }
  }
`

export const CART = gql`
  query Cart {
    cart {
      numProdotti
      totaleProdottiScontato
      items {
        pk
        quantita
        omaggio
        prodotto {
          pk
          titolo
          codice
          smallThumb
          url
          prezzo
          prezzoScontato
          disponibile
          formato {
            nome
          }
          linea {
            nome
          }
          categoria {
            titolo
          }
        }
      }
    }
  }
`

export const CHECKOUT = gql`
  query Checkout {
    checkout {
      items {
        omaggio
        quantita
        prodotto {
          pk
          titolo
          codice
          smallThumb
          url
          prezzo
          prezzoScontato
          disponibile
          formato {
            nome
          }
          linea {
            nome
          }
          categoria {
            titolo
          }
        }
      }
      indirizzoSpedizione {
        pk
        indirizzo
        nome
        cognome
        citta
        provincia
        cap
        informazioniAggiuntive
        consegnaPresso
      }
      indirizzoFatturazione {
        pk
        indirizzo
        nome
        cognome
        citta
        provincia
        cap
        cf
      }
      sistemaPagamento {
        pk
      }
      sistemiPagamento {
        pk
        alias
        nome
        descrizione
        image
      }
      prezzo
      scontoAssoluto
      totaleScontatoNetto
      totaleProdottiScontato
      promoApplicata {
        alias
        codice
        conCodice
      }
      sistemaSpedizione {
        prezzo
        prezzoScontato
      }
    }
  }
`

export const CREATE_UTENTE = gql`
  mutation CreateUtente($input: UtenteInput!) {
    createUtente(input: $input) {
      utente {
        pk
        isActive
        centroEstetico {
          ragioneSociale
        }
      }
    }
  }
`

export const UPDATE_UTENTE = gql`
  mutation UpdateUtente($input: UtenteUpdateInput!) {
    updateUtente(input: $input) {
      utente {
        email
        socialCompleted
      }
    }
  }
`

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($input: PasswordUpdateInput!) {
    updatePassword(input: $input) {
      message
    }
  }
`

export const GET_TOKEN = gql`
  mutation GetToken($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
    }
  }
`

export const VERIFY_TOKEN = gql`
  mutation VerifyToken($token: String!) {
    verifyToken(token: $token) {
      payload
    }
  }
`

export const SOCIAL_LOGIN = gql`
  mutation SocialAuth($provider: String!, $accessToken: String!) {
    socialAuth(provider: $provider, accessToken: $accessToken) {
      social {
        uid
      }
      token
    }
  }
`

export const ADD_TO_CART = gql`
  mutation AddItem($item: Int!, $quantita: Int!) {
    addItem(item: $item, quantita: $quantita) {
      status
      message
    }
  }
`

export const SET_INDIRIZZI = gql`
  mutation SetIndirizzi($indirizzoSpedizione: Int!, $indirizzoFatturazione: Int!) {
    setIndirizzi(
      indirizzoSpedizione: $indirizzoSpedizione
      indirizzoFatturazione: $indirizzoFatturazione
    ) {
      status
      message
    }
  }
`

export const SET_CHECKOUT = gql`
  mutation SetCheckout($sistemaPagamento: Int!) {
    setCheckout(sistemaPagamento: $sistemaPagamento) {
      url
      status
      message
    }
  }
`

export const INDIRIZZI_SPEDIZIONE = gql`
  query IndirizziSpedizione {
    indirizzi(isFatturazione: false) {
      edges {
        node {
          pk
          nome
          cognome
          indirizzo
          informazioniAggiuntive
          consegnaPresso
          isFatturazione
          telefono
          citta
          provincia
          cap
          cf
        }
      }
    }
  }
`

export const SET_PROMO_CODE = gql`
  mutation SetPromoCode($promoCode: String!) {
    setPromoCode(promoCode: $promoCode) {
      status
      message
    }
  }
`

export const REMOVE_PROMO_CODE = gql`
  mutation RemovePromoCode($promoCode: String!) {
    removePromoCode(promoCode: $promoCode) {
      status
      message
    }
  }
`

export const INDIRIZZI_FATTURAZIONE = gql`
  query IndirizziFatturazione {
    indirizzi(isFatturazione: true) {
      edges {
        node {
          pk
          nome
          cognome
          indirizzo
          informazioniAggiuntive
          consegnaPresso
          isFatturazione
          telefono
          citta
          provincia
          cap
          cf
        }
      }
    }
  }
`

export const CREATE_INDIRIZZO = gql`
  mutation CreateIndirizzo($input: IndirizzoInput!) {
    createIndirizzo(input: $input) {
      indirizzo {
        nome
        cognome
        indirizzo
        isFatturazione
      }
    }
  }
`

export const UPDATE_INDIRIZZO = gql`
  mutation UpdateIndirizzo($input: IndirizzoUpdateInput!) {
    updateIndirizzo(input: $input) {
      indirizzo {
        nome
        cognome
        isFatturazione
      }
    }
  }
`

export const DELETE_INDIRIZZO = gql`
  mutation DeleteIndirizzo($input: IndirizzoDeleteInput!) {
    deleteIndirizzo(input: $input) {
      message
    }
  }
`

export const ORDINI = gql`
  query Ordini {
    ordini {
      edges {
        node {
          pk
          data
          prezzoTotale
          stato {
            nome
            attivo
          }
          attivo
          url
        }
      }
    }
  }
`

export const ORDINE = `
  query Ordine {
    ordine(pk: __pk__) {
      pk
      data
      indirizzoSpedizione{
        nome
        cognome
        indirizzo
        provincia
        citta
        cap
      }
      indirizzoFatturazione{
        nome
        cognome
        indirizzo
        provincia
        citta
        cap
        cf
      }
      sistemaPagamento{
        alias
        nome
      }
      sistemaSpedizione{
        prezzoScontato
      }
      righeOrdine{
        omaggio
        prodotto{
          titolo
          codice
          smallThumb
          formato{
            nome
          }
          url
        }
        quantitaOrdinata
        prezzoTotale
        prezzoTotaleScontato
      }
      prezzoTotale
      prezzoTotaleScontato
      promozioneValoreAssoluto
      costoSpedizione
      totaleProdottiScontato
      stato {
        pk
        nome
        attivo
      }
      attivo
      trackingUrl
    }
  }
`

export const WISHLIST = gql`
  query Wishlist {
    wishlist {
      items {
        pk
        prodotto {
          pk
          titolo
          sottotitolo
          codice
          prodottoCardImageThumb
          url
          prezzo
          prezzoScontato
          scontoPercentuale
          wishlist
          disponibile
          novita
          formato {
            nome
          }
          linea {
            nome
            colore
          }
          categoria {
            titolo
          }
          promoTags {
            testo
            colore
          }
        }
      }
    }
  }
`

export const ADD_ITEM_WISHLIST = gql`
  mutation AddItemWishlist($item: Int!) {
    addItemWishlist(item: $item) {
      status
      message
    }
  }
`

export const REMOVE_ITEM_WISHLIST = gql`
  mutation RemoveItemWishlist($item: Int!) {
    removeItemWishlist(item: $item) {
      status
      message
    }
  }
`

export const SEARCH = gql`
  query Search(
    $query: String!
    $models: [Modello]
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    search(
      query: $query
      models: $models
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      count
      edges {
        node {
          object {
            ... on LineaNode {
              pk
              nome
              lineaHomeCardImageThumb
              lineaHomeCardImageHoverThumb
              url
            }
            ... on ArticoloMagazineNode {
              contentTypeId
              pk
              title
              abstract
              publicationDate
              categoria {
                title
              }
              thumbnailSmall
              url
            }
            ... on ProdottoNode {
              pk
              titolo
              sottotitolo
              codice
              prodottoCardImageThumb
              url
              prezzo
              prezzoScontato
              scontoPercentuale
              wishlist
              disponibile
              novita
              linea {
                colore
                nome
              }
              categoria {
                titolo
              }
              promoApplicata {
                descrizione
              }
              promoTags {
                testo
                colore
              }
            }
            ... on TrattamentoNode {
              id
              title
              abstract
              description
              image
              slug
              tipologiePelle {
                title
                slug
              }
              durateTrattamento {
                title
                minutes
              }
              tipologieTrattamento {
                title
                slug
              }
              zonaTrattamento {
                title
                slug
              }
              videoTrattamento {
                tipoVideo
                url
                vimeoImage
              }
            }
          }
          modelName
          score
          url
        }
      }
    }
  }
`

export const ORDINE_CHECKOUT = gql`
  query OrdineCheckout {
    ordineCheckout {
      pk
      indirizzoSpedizione {
        nome
        cognome
        indirizzo
        provincia
        citta
        cap
      }
      indirizzoFatturazione {
        nome
        cognome
        indirizzo
        provincia
        citta
        cap
        cf
      }
      sistemaPagamento {
        alias
        nome
      }
    }
  }
`

export const TIPOLOGIE_RICHIESTA = gql`
  query TipologieRichiesta {
    tipologieRichiesta {
      tipologiaChiave
      tipologia
    }
  }
`

export const LISTA_PROVINCE = gql`
  query Province {
    province {
      provinciaChiave
      provincia
    }
  }
`

export const CREATE_RICHIESTA_CONTATTO = gql`
  mutation CreateRichiestaContatto($input: RichiestaContattoInput!) {
    createRichiestaContatto(input: $input) {
      status
      message
    }
  }
`

export const CREATE_RICHIESTA_CENTRO = gql`
  mutation CreateRichiestaCentro($input: RichiestaCentroInput!) {
    createRichiestaCentro(input: $input) {
      status
      message
    }
  }
`

export const RECUPERA_PASSWORD = gql`
  mutation RecuperaPassword($email: String!) {
    recuperaPassword(email: $email) {
      status
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPassword($password: String!, $token: String!) {
    resetPassword(password: $password, token: $token) {
      status
    }
  }
`

export const CENTRO_ESTETICO = gql`
  query CentroEstetico {
    me {
      centroEstetico {
        alias
        ragioneSociale
        indirizzo
        cap
        citta
        provincia
        telefono
        latitudine
        longitudine
      }
    }
  }
`

export const INSERT_UTENTE_NEWSLETTER = gql`
  mutation CreateUtenteNewsletter($input: RichiestaIscrizioneNewsletterInput!) {
    createUtenteNewsletter(input: $input) {
      status
      message
    }
  }
`

export const LOGOUT = gql`
  mutation Logout {
    logout {
      status
    }
  }
`
export const MENU_GET = `
  {
    menuitems(parent_Isnull: true, attivo: true) {
      edges {
        node {
          id
          pk
          alias
          key
          attivo
          name
          description
          image
          url: finalUrl
          children(attivo: true) {
            edges {
              node {
                id
                pk
                alias
                key
                attivo
                name
                description
                image
                url: finalUrl
                children(attivo: true) {
                  edges {
                    node {
                      id
                      pk
                      alias
                      key
                      attivo
                      name
                      description
                      image
                      url: finalUrl                      
                      children(attivo: true) {
                        edges {
                          node {
                            id
                            pk
                            alias
                            key
                            attivo
                            name
                            image
                            url: finalUrl
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
