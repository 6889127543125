import React from 'react'

function Loader({ classes, onClick }) {
  return (
    <svg className={'icon icon--loader ' + classes} viewBox="0 0 40 40" onClick={onClick}>
      <path d="M20 40C8.97 40 0 31.03 0 20C0 18.58 1.15 17.44 2.56 17.44C3.97 17.44 5.12 18.58 5.12 20C5.12 28.2 11.79 34.88 20 34.88C28.21 34.88 34.88 28.2 34.88 20C34.88 11.8 28.21 5.12 20 5.12C18.58 5.12 17.44 3.97 17.44 2.56C17.44 1.15 18.58 0 20 0C31.03 0 40 8.97 40 20C40 31.03 31.03 40 20 40Z" />
    </svg>
  )
}

export default Loader
