import { ImageBox } from 'components/atoms'

function BannerLanding({ menu = {} }) {
  return !!menu ? (
    <div className={`banner-landing__container`}>
      <main className="banner-landing__steps">
        <article className="banner-landing__item" key={menu.id}>
          <div className="banner-landing__image">
            <ImageBox
              src={menu.image}
              maxWidth={575}
              maxHeight={181}
              alt={menu.name.toAltTitle()}
              title={menu.name.toAltTitle()}
              classPrefix="banner-landing__item"
              lazyLoad
            />
          </div>
          <div className="banner-landing__content">
            <p className="banner-landing__title">{menu.name}</p>
            <div
              className="banner-landing__description"
              dangerouslySetInnerHTML={{ __html: menu.description }}
            />
          </div>
        </article>
      </main>
    </div>
  ) : (
    <></>
  )
}

export default BannerLanding
