import React from 'react'

const Datatable = ({ cols, className, children }) => {
  return (
    <div className={'datatable' + (className ? ' ' + className : '')}>
      <div className="datatable__head">
        {Object.values(cols).map((col, index) => (
          <DatatableHeadCell key={index} col={col} />
        ))}
      </div>
      {children}
    </div>
  )
}

const DatatableHeadCell = ({ col }) => {
  // visibili sono nel desktop, quindi non gestisco i casi mobile
  return (
    <div
      className={
        'datatable-headcell' +
        (col.hidden ? ' u-hidden' : '') +
        (col.desktopHidden ? ' u-hidden-desktop' : '') +
        (col.desktopCols ? ' datatable-cell--desktop-cols-' + col.desktopCols : '') +
        (col.align ? ' u-align-' + col.align : '') +
        (col.desktopAlign ? ' u-align-' + col.desktopAlign + '-desktop' : '')
      }
      style={col.width && { flex: 'none', width: col.width ? col.width : null }}
    >
      <DatatableHeading label={col.label} />
    </div>
  )
}

const DatatableHeading = ({ label }) => {
  return (
    <div className="datatable-heading">
      <span className="datatable-heading__label">{label}</span>
    </div>
  )
}

const DatatableError = ({ error }) => {
  return error ? <div className="datatable-error">{error}</div> : <></>
}

const DatatableRow = ({ children, extra, error, even, className }) => {
  return (
    <div
      className={
        'datatable-row' + (even ? '' : ' datatable-row--odd') + (className ? ' ' + className : '')
      }
    >
      <div className="datatable-row__cells">{children}</div>
      {extra && <div className="datatable-row__extra">{extra}</div>}
      {error && (
        <div className="datatable-row__error">
          <DatatableError error={error} />
        </div>
      )}
    </div>
  )
}

// Se non c'è il parametro col non visualizzo la cella
const DatatableCell = ({ col, error, children, className }) => {
  return col ? (
    <div
      className={
        'datatable-cell' +
        (col.align ? ' u-align-' + col.align : '') +
        (col.desktopAlign ? ' u-align-' + col.desktopAlign + '-desktop' : '') +
        (col.mobileAlign ? ' u-align-' + col.mobileAlign + '-mobile' : '') +
        (col.mobileOrder ? ' datatable-cell--mobile-order-' + col.mobileOrder : '') +
        (col.desktopCols ? ' datatable-cell--desktop-cols-' + col.desktopCols : '') +
        (col.mobileCols ? ' datatable-cell--mobile-cols-' + col.mobileCols : '') +
        (col.mobileHidden ? ' u-hidden-mobile' : '') +
        (col.desktopHidden ? ' u-hidden-desktop' : '') +
        (col.hidden ? ' u-hidden' : '') +
        (className ? ' ' + className : '')
      }
      style={col.width && { flex: 'none', width: col.width ? col.width : null }}
    >
      {col.label && (
        <div className="datatable-cell__heading">
          <DatatableHeading label={col.label} />
        </div>
      )}
      <div className="datatable-cell__content">{children}</div>
      {error && (
        <div className="datatable-cell__error">
          <DatatableError error={error} />
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}

Datatable.Row = DatatableRow
Datatable.Heading = DatatableHeading
Datatable.HeadCell = DatatableHeadCell
Datatable.Cell = DatatableCell
Datatable.Error = DatatableError

export default Datatable
