import React from 'react'
import { Close } from '../Icons'

const Modal = ({ open, setOpen, children }) => {
  const close = (e) => {
    e.stopPropagation()
    if (setOpen) setOpen(false)
  }

  return (
    <>
      {open && <div className="overlay-modal" onClick={close}></div>}
      {open && (
        <div className={'modal'}>
          <span className="modal__close" onClick={close}>
            <Close />
          </span>
          <div className="modal__body">{children}</div>
        </div>
      )}
    </>
  )
}

export default Modal
