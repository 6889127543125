import { CartCounter, Button } from 'components/atoms'
import { Menu as MenuIcon, Profile, Search, Wishlist, Logo, Close } from 'components/atoms/Icons'
import { Menu, MenuMobile, SearchForm } from 'components/molecules'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { trackingGAevent } from 'utils/gtm'
import { useIsClient, useTrans } from 'utils/hooks'

function Header({ isHome }) {
  const t = useTrans()
  const isClientSide = useIsClient(false)

  const [headerHover, setHeaderHover] = useState(false)

  const [searchMobileOpen, setSearchMobileOpen] = useState(false)
  const [menuMobileOpen, setMenuMobileOpen] = useState(false)
  const [windowScroll, setWindowScroll] = useState(0)

  const { utente } = useSelector((state) => state.utente)
  const { menu } = useSelector((state) => state.menu)
  const main_menu = menu?.edges.find((item) => item.node.key === 'main')

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [windowScroll])

  const handleScroll = () => {
    if (window.scrollY > 0) document.body.classList.add('w-scrolled')
    else document.body.classList.remove('w-scrolled')
    if (window.scrollY < windowScroll || windowScroll == 0) {
      document.body.classList.remove('w-scrolldown')
    } else {
      document.body.classList.add('w-scrolldown')
    }
    setWindowScroll(window.scrollY)
  }

  const withToolbar = utente?.isStaff || utente?.isImpersonate ? ' header--toolbar-active' : ''
  const transparent = isHome && windowScroll < 20 && !menuMobileOpen ? ' header--transparent' : ''
  const transaprent_cta =
    isHome && windowScroll < 20 && !menuMobileOpen && !headerHover ? 'secondary-white' : 'secondary'

  return (
    <header
      className={'header' + withToolbar + transparent}
      onMouseEnter={() => setHeaderHover(true)}
      onMouseLeave={() => setHeaderHover(false)}
    >
      <div className="header__top">
        <section className="header__top__left header__top__left--mobile">
          {menuMobileOpen ? (
            <Close onClick={() => setMenuMobileOpen(false)} />
          ) : (
            <MenuIcon onClick={() => setMenuMobileOpen(true)} />
          )}
          <Search onClick={() => setSearchMobileOpen(!searchMobileOpen)} />
        </section>
        <section className="header__top__left header__top__left--desktop">
          <SearchForm />
        </section>
        <section className="header__top__logo">
          <a href="/">
            <Logo />
          </a>
        </section>
        <section className="header__top__icons">
          <Button
            href="/diventa-un-centro"
            label={t('Diventa un centro Matis Paris')}
            type={transaprent_cta}
            size="sm"
            onClick={() => trackingGAevent(56, 'beauty_center', 'diventa_un_centro_matis')}
          />
          <Link legacyBehavior href="/myaccount/wishlist">
            <a
              onClick={() => {
                trackingGAevent(29, 'header', 'top_right', 'wishlist')
              }}
            >
              <Wishlist />
            </a>
          </Link>
          <Link legacyBehavior href="/myaccount/profilo">
            <a
              onClick={() => {
                trackingGAevent(29, 'header', 'top_right', 'user')
              }}
            >
              <Profile />
            </a>
          </Link>
          <CartCounter />
        </section>
      </div>
      <div className="header__menu">
        <Menu vociMenu={main_menu?.node?.children} />
      </div>
      {isClientSide && (
        <>
          <div
            className={'header__menu-mobile' + (menuMobileOpen ? ' header__menu-mobile--open' : '')}
          >
            <MenuMobile
              vociMenu={main_menu?.node?.children}
              open={menuMobileOpen}
              menuMobileOpen={menuMobileOpen}
              setMenuMobileOpen={setMenuMobileOpen}
            />
          </div>
          <div
            className={
              'header__search-mobile' + (searchMobileOpen ? ' header__search-mobile--open' : '')
            }
          >
            <SearchForm mobile={true} searchMobileOpen={searchMobileOpen} />
          </div>
        </>
      )}
    </header>
  )
}

export default Header
