import React, { useContext } from 'react'
import { Cart } from 'components/atoms/Icons'
import { CartContext } from 'utils/context'
import Link from 'next/link'
import { trackingGAevent } from 'utils/gtm'

const CartCounter = () => {
  const cart = useContext(CartContext)
  const numProdotti = cart ? cart.cart?.numProdotti : 0

  return (
    <Link legacyBehavior href="/cart">
      <a
        className="cart-counter"
        onClick={() => {
          trackingGAevent(29, 'header', 'top_right', 'cart')
        }}
      >
        <Cart classes="cart-counter__icon" />
        {numProdotti > 0 && <span className="cart-counter__num">{numProdotti}</span>}
      </a>
    </Link>
  )
}

export default CartCounter
