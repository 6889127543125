import React, { useRef } from 'react'

import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Arrow2Left, Arrow2Right } from '../Icons'
SwiperCore.use([Navigation])
import { trackingGAevent } from 'utils/gtm'

const PagebuilderSlide = ({ slide }) => {
  return (
    <div className="pagebuilder-slide">
      <div className="pagebuilder-slide__image-box">
        <img
          src={slide.image ? slide.image : 'https://via.placeholder.com/480'}
          className="pagebuilder-slide__image"
        />
      </div>
    </div>
  )
}

const PagebuilderSlider = ({ slides, titoloArticolo }) => {
  const prevArrow = useRef(null)
  const nextArrow = useRef(null)

  return slides.length > 0 ? (
    <div className="pagebuilder-slider">
      <a
        onClick={() => {
          titoloArticolo
            ? trackingGAevent(48, 'magazine', 'clic_frecce_immagini', titoloArticolo)
            : null
        }}
      >
        <div className="pagebuilder-slider__nav pagebuilder-slider__nav--prev" ref={prevArrow}>
          <Arrow2Left />
        </div>
      </a>
      <a
        onClick={() => {
          titoloArticolo
            ? trackingGAevent(48, 'magazine', 'clic_frecce_immagini', titoloArticolo)
            : null
        }}
      >
        <div className="pagebuilder-slider__nav pagebuilder-slider__nav--next" ref={nextArrow}>
          <Arrow2Right />
        </div>
      </a>
      <div className="pagebuilder-slider__slider">
        <Swiper
          navigation={true}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevArrow.current
            swiper.params.navigation.nextEl = nextArrow.current
            swiper.navigation.update()
          }}
          loop={true}
          pagination={false}
          slidesPerView={2}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            375: {
              slidesPerView: 2,
              spaceBetween: 8,
            },
            768: {
              spaceBetween: 0,
            },
          }}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <PagebuilderSlide slide={slide} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default PagebuilderSlider
