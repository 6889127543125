import React, { useEffect, useState } from 'react'
import { Info } from 'components/atoms/Icons'

const FormInput = ({
  type = 'text',
  placeholder,
  variant, // "standard" per rimuovere il comportamento del placeholder e il padding correlato
  name,
  value,
  className = '',
  disabled,
  status,
  onBlur,
  onFocus,
  onChange,
  errorMessage,
}) => {
  const [focused, setFocused] = useState(false)
  const [filled, setFilled] = useState(Boolean(value))
  const localOnChange = (e) => {
    setFilled(Boolean(e.target.value))
    if (onChange) onChange(e)
  }
  const localOnFocus = (e) => {
    setFocused(true)
    if (onFocus) onFocus(e)
  }
  const localOnBlur = (e) => {
    setFocused(false)
    if (onBlur) onBlur(e)
  }

  useEffect(() => {
    setFilled(Boolean(value))
  }, [value])

  return (
    <div className="form-field__input-wrap">
      <input
        type={type}
        name={name}
        value={value}
        className={
          'form-field__input' +
          (status ? ` form-field__input--${status}` : '') +
          (filled || focused ? ' form-field__input--placeholder-up' : '') +
          ' form-input ' +
          (variant ? ` form-input--${variant}` : '') +
          className
        }
        placeholder={variant === 'standard' && placeholder ? placeholder : null}
        disabled={disabled}
        onFocus={localOnFocus}
        onBlur={localOnBlur}
        onChange={localOnChange}
      />
      {placeholder && variant !== 'standard' ? (
        <span className="form-field__input-placeholder">{placeholder}</span>
      ) : (
        <></>
      )}
      {status === 'error' && errorMessage ? (
        <p className="form-field__input-error">
          <Info classes="form-field__input-error-icon" />
          {errorMessage}
        </p>
      ) : (
        <></>
      )}
    </div>
  )
}

export default FormInput
