import {
  BreadcrumbJsonLd,
  FAQPageJsonLd,
  NewsArticleJsonLd,
  ProductJsonLd,
  SocialProfileJsonLd,
} from 'next-seo'
import { BRAND, DOMAIN } from 'utils'

function JsonLd({ item, type = 'prodotto' }) {
  switch (type) {
    case 'prodotto': {
      return (
        <ProductJsonLd
          productName={item.titolo}
          images={item.immagini.map((img) => `${DOMAIN}${img.imageThumb}`)}
          description={`${item.linea?.nome || ''}${
            item.linea ? ' | ' : ''
          }${item.descrizione?.replace(/<[^>]*>?/gm, '')}`}
          brand={BRAND}
          mpn={item.codice}
          sku={item.codice}
          offers={[
            {
              price: item.prezzoScontato || item.prezzo,
              priceCurrency: 'EUR',
              itemCondition: 'https://schema.org/NewCondition',
              availability: `https://schema.org/${item.disponibile ? 'InStock' : 'OutOfStock'}`,
              url: `${DOMAIN}${item.url}`,
              seller: {
                name: BRAND,
              },
            },
          ]}
        />
      )
    }
    case 'articolo': {
      return (
        <NewsArticleJsonLd
          url={`${DOMAIN}${item.url}`}
          title={item.title}
          images={[`${DOMAIN}${item.mainImageThumb}`]}
          section={item.categoria?.title}
          datePublished={item.publicationDate}
          description={item.abstract}
          body={item.abstract}
          authorName={BRAND}
          publisherName={BRAND}
          publisherLogo={`${DOMAIN}/img/logo.png`}
        />
      )
    }
    case 'breadcrumbs': {
      return <BreadcrumbJsonLd itemListElements={item} />
    }
    case 'homepage': {
      return (
        <SocialProfileJsonLd
          type="Organization"
          name={BRAND}
          url={DOMAIN}
          sameAs={[
            'https://www.facebook.com/matisparisItalia/',
            'https://www.instagram.com/matisparisitalia/',
          ]}
        />
      )
    }
    case 'faq': {
      return (
        <FAQPageJsonLd
          mainEntity={item
            .reduce((res, categoria) => {
              res = res.concat(categoria.faq)
              return res
            }, [])
            .map((faq) => ({
              questionName: faq.domanda?.replace(/\"/g, "'"),
              acceptedAnswerText: faq.risposta?.replace(/\"/g, "'"),
            }))}
        />
      )
    }
    default: {
      return null
    }
  }
}

export default JsonLd
