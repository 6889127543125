import React from 'react'
import Link from 'next/link'
import { Arrow2Right } from 'components/atoms/Icons'
import { trackingGAevent } from 'utils/gtm'

const MyAccountMenuItem = ({ icon, label, link, active, onClick = () => {} }) => {
  return link ? (
    <Link legacyBehavior href={link}>
      <a
        className={'myaccount-menu-item' + (active ? ' myaccount-menu-item--active' : '')}
        onClick={() => trackingGAevent(32, 'my_account', 'click', label)}
      >
        <span className="myaccount-menu-item__icon">{icon}</span>
        <span className="myaccount-menu-item__label">{label}</span>
        <span className="myaccount-menu-item__arrow">{<Arrow2Right />}</span>
      </a>
    </Link>
  ) : (
    <div
      className={'myaccount-menu-item' + (active ? ' myaccount-menu-item--active' : '')}
      onClick={onClick}
    >
      <span className="myaccount-menu-item__icon">{icon}</span>
      <span className="myaccount-menu-item__label">{label}</span>
    </div>
  )
}

export default MyAccountMenuItem
