import React, { useState } from 'react'
import { Info } from 'components/atoms/Icons'

const FormTextarea = ({
  placeholder,
  name,
  value,
  className = '',
  disabled,
  status,
  onBlur,
  onFocus,
  onChange,
  errorMessage,
}) => {
  const [focused, setFocused] = useState(false)
  const [filled, setFilled] = useState(value ? true : false)
  const localOnChange = (e) => {
    setFilled(e.target.value ? true : false)
    if (onChange) onChange(e)
  }
  const localOnFocus = (e) => {
    setFocused(true)
    if (onFocus) onFocus(e)
  }
  const localOnBlur = (e) => {
    setFocused(false)
    if (onBlur) onBlur(e)
  }
  return (
    <div className="form-field__input-wrap">
      <textarea
        name={name}
        value={value}
        onBlur={localOnBlur}
        onFocus={localOnFocus}
        onChange={localOnChange}
        className={
          'form-field__input' +
          (status ? ` form-field__input--${status}` : '') +
          (filled || focused ? ' form-field__input--placeholder-up' : '') +
          ' form-textarea ' +
          className
        }
        disabled={disabled}
      ></textarea>
      {placeholder ? <span className="form-field__input-placeholder">{placeholder}</span> : <></>}
      {status === 'error' && errorMessage ? (
        <p className="form-field__input-error">
          <Info classes="form-field__input-error-icon" />
          {errorMessage}
        </p>
      ) : (
        <></>
      )}
    </div>
  )
}

export default FormTextarea
