import React from 'react'

function Directions(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : '24px'}
      height={props.height ? props.height : '24px'}
      viewBox="0 0 24 24"
      className="icon--strokes"
    >
      <path
        d="M6 21V13C6 10.2386 8.23858 8 11 8H19"
        stroke={props.color ? props.color : ''}
        strokeWidth="2"
        fill="none"
      />
      <path
        d="M15 3L20 8L15 13"
        stroke={props.color ? props.color : ''}
        strokeWidth="2"
        fill="none"
      />
    </svg>
  )
}

export default Directions
