import React from 'react'

function CheckboxFull(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--strokes icon--checkbox-full ' + (props.classes ? props.classes : '')}
    >
      <rect
        x="4"
        y="4"
        width="16"
        height="16"
        rx="1"
        fill="none"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3708 9.20386L10.5416 16.033L6.62916 12.1205L7.45411 11.2956L10.5416 14.3831L16.5458 8.37891L17.3708 9.20386Z"
        stroke="none"
      />
    </svg>
  )
}

export default CheckboxFull
