import React from 'react'

function Spedizione(props) {
  return (
    <svg
      viewBox="0 0 71 70"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--spedizione ' + (props.classes ? props.classes : '')}
    >
      <g clipPath="url(#spedizione-clip0)">
        <path
          d="M68.232 35.399L64.2 30.212L57.9 16.94C57.816 16.758 57.627 16.639 57.424 16.639H45.307V13.832C45.307 13.545 45.069 13.307 44.782 13.307L3.12501 13.3C2.83801 13.3 2.60001 13.538 2.60001 13.825V50.323C2.60001 50.61 2.83101 50.848 3.12501 50.848L14.199 50.897H18.175C18.63 52.766 19.743 54.376 21.346 55.468C22.655 56.364 24.174 56.833 25.735 56.833C26.218 56.833 26.701 56.791 27.184 56.7C30.18 56.14 32.595 53.837 33.323 50.897H45.44C45.895 52.766 47.008 54.376 48.611 55.468C50.333 56.644 52.405 57.085 54.449 56.7C57.494 56.133 59.86 53.872 60.588 50.897H67.819C68.106 50.897 68.344 50.659 68.344 50.372V35.721C68.337 35.602 68.302 35.49 68.232 35.399ZM67.287 49.847H60.154C59.902 49.847 59.685 50.029 59.636 50.274C59.118 53.032 57.004 55.146 54.246 55.664C52.482 56 50.683 55.622 49.199 54.6C47.715 53.578 46.707 52.045 46.378 50.274C46.329 50.022 46.112 49.847 45.86 49.847H32.896C32.644 49.847 32.427 50.029 32.378 50.274C31.867 52.99 29.704 55.153 26.988 55.664C25.224 56 23.425 55.622 21.934 54.6C20.45 53.578 19.442 52.045 19.113 50.274C19.064 50.022 18.847 49.847 18.595 49.847L3.65001 49.798V14.35L44.257 14.357V17.164C44.257 17.451 44.495 17.689 44.782 17.689H57.088L63.269 30.709C63.283 30.744 63.304 30.779 63.332 30.807L67.287 35.896V49.847Z"
          stroke="none"
        />
        <path
          d="M19.624 37.1559H9.16604C8.87904 37.1559 8.64104 37.3939 8.64104 37.6809C8.64104 37.9679 8.87904 38.2059 9.16604 38.2059H19.631C19.918 38.2059 20.156 37.9679 20.156 37.6809C20.156 37.3939 19.918 37.1559 19.624 37.1559Z"
          stroke="none"
        />
        <path
          d="M24.727 29.834C24.727 29.547 24.489 29.309 24.202 29.309H5.89002C5.60302 29.309 5.36502 29.547 5.36502 29.834C5.36502 30.121 5.60302 30.359 5.89002 30.359H24.202C24.496 30.359 24.727 30.128 24.727 29.834Z"
          stroke="none"
        />
        <path
          d="M9.16604 22.512H27.478C27.765 22.512 28.003 22.274 28.003 21.987C28.003 21.7 27.765 21.462 27.478 21.462H9.16604C8.87904 21.462 8.64104 21.7 8.64104 21.987C8.64104 22.274 8.87204 22.512 9.16604 22.512Z"
          stroke="none"
        />
        <path
          d="M25.735 44.366C23.159 44.366 21.073 46.452 21.073 49.028C21.073 51.604 23.159 53.69 25.735 53.69C28.311 53.69 30.397 51.604 30.397 49.035V49.028C30.397 46.452 28.311 44.366 25.735 44.366Z"
          stroke="none"
        />
        <path
          d="M53 44.366C50.424 44.366 48.338 46.452 48.338 49.028C48.338 51.604 50.424 53.69 53 53.69H53.007C55.576 53.683 57.662 51.604 57.662 49.035V49.028C57.662 46.452 55.576 44.366 53 44.366Z"
          stroke="none"
        />
        <path
          d="M56.241 19.558C56.157 19.376 55.968 19.257 55.765 19.257H44.775C44.488 19.257 44.25 19.495 44.25 19.782V29.799C44.25 30.086 44.488 30.324 44.775 30.324H60.539C60.721 30.324 60.889 30.233 60.98 30.079C61.078 29.925 61.085 29.736 61.008 29.575L56.241 19.558ZM45.307 29.281V20.307H55.436L59.713 29.274H45.307V29.281Z"
          stroke="none"
        />
      </g>
      <defs>
        <clipPath id="spedizione-clip0">
          <rect width="65.737" height="43.533" fill="white" transform="translate(2.60001 13.3)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Spedizione
