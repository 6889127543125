import React from 'react'

function List(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--strokes icon--list ' + (props.classes ? props.classes : '')}
    >
      <line fill="none" x1="7" y1="12" x2="21" y2="12" strokeWidth="2" />
      <line fill="none" x1="7" y1="5" x2="21" y2="5" strokeWidth="2" />
      <line fill="none" x1="7" y1="19" x2="21" y2="19" strokeWidth="2" />
      <line fill="none" x1="3" y1="12" x2="5" y2="12" strokeWidth="2" strokeLinejoin="round" />
      <line fill="none" x1="3" y1="5" x2="5" y2="5" strokeWidth="2" strokeLinejoin="round" />
      <line fill="none" x1="3" y1="19" x2="5" y2="19" strokeWidth="2" strokeLinejoin="round" />
    </svg>
  )
}

export default List
