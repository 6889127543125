import React from 'react'

function RadioButtonEmpty(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={
        'icon icon--strokes icon--radio-button-empty ' + (props.classes ? props.classes : '')
      }
    >
      <rect x="4" y="4" width="16" height="16" rx="8" fill="none" strokeWidth="2" />
    </svg>
  )
}

export default RadioButtonEmpty
