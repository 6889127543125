import React from 'react'
import Link from 'next/link'
import QuantityHandler from 'components/atoms/QuantityHandler/QuantityHandler'
import { WImage } from 'components/atoms'
import { priceNotation } from 'utils/safe'

function ProdottoCarrello({ prodotto, quantita, omaggio = false }) {
  return (
    <article className="prodotto-carrello">
      <div className="prodotto-carrello__image-box">
        <Link legacyBehavior href={prodotto.url}>
          <a>
            <WImage
              src={prodotto.smallThumb}
              maxWidth={98}
              maxHeight={98}
              alt={`${prodotto.titolo} ${prodotto.sottotitolo || ''}`.toAltTitle()}
              title={`${prodotto.titolo} ${prodotto.sottotitolo || ''}`.toAltTitle()}
              classPrefix="prodotto-carrello"
            />
          </a>
        </Link>
      </div>
      <div className="prodotto-carrello__content">
        <Link legacyBehavior href={prodotto.url}>
          <a className="prodotto-carrello__title">{prodotto.titolo}</a>
        </Link>
        <div className="prodotto-carrello__cols">
          <div>
            <p className="prodotto-carrello__size">{prodotto.formato?.nome}</p>
            <QuantityHandler value={quantita} prodotto={prodotto} />
          </div>
          <div>
            {omaggio ? (
              <p className="prodotto-checkout__finalprice">Omaggio</p>
            ) : (
              <>
                {prodotto.prezzo != prodotto.prezzoScontato && (
                  <p className="prodotto-carrello__fullprice">{priceNotation(prodotto.prezzo)}</p>
                )}
                <p className="prodotto-carrello__finalprice">
                  {priceNotation(prodotto.prezzoScontato)}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </article>
  )
}

export default ProdottoCarrello
