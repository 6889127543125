import { Button, Divider } from 'components/atoms'
import Link from 'next/link'
import { useSelector } from 'react-redux'

function EsitoCheckout({ ordine, bonifico, success, classes }) {
  const { utente } = useSelector((state) => state.utente)

  return success ? (
    <div className={'esito-checkout ' + classes}>
      <h2 className="esito-checkout__title">Grazie, {utente.nome}</h2>
      {bonifico ? (
        <p className="esito-checkout__disclaimer">
          Ti abbiamo inviato una mail con l’IBAN a cui effettuare il bonifico.
          <br /> Puoi controllare l’andamento dell’ordine sulla pagina{' '}
          <Link legacyBehavior href="/myaccount/ordini">
            <a className="esito-checkout__link">I miei ordini</a>
          </Link>
        </p>
      ) : (
        <p className="esito-checkout__disclaimer">
          Il tuo ordine è andato a buon fine.
          <br /> Riceverai presto un’email di conferma.
          <br /> Puoi controllare l’andamento dell’ordine sulla pagina{' '}
          <Link legacyBehavior href="/myaccount/ordini">
            <a className="esito-checkout__link">I miei ordini</a>
          </Link>
        </p>
      )}
      <Divider classes="esito-checkout__divider" />
      <div className="esito-checkout__riepilogo">
        <h3 className="esito-checkout__riepilogo__title">Riepilogo ordine</h3>
        <div className="esito-checkout__riepilogo__section">
          <p>
            <span>Email di contatto</span>
            {utente.email}
          </p>
          <p>
            <span>Metodo di pagamento</span>
            {ordine.sistemaPagamento.alias}
          </p>
        </div>
        <div className="esito-checkout__riepilogo__section">
          <p>
            <span>Indirizzo di spedizione</span>
            <span>
              {ordine.indirizzoSpedizione.nome} {ordine.indirizzoSpedizione.cognome}
            </span>
            {ordine.indirizzoSpedizione.indirizzo}
            <br />
            {ordine.indirizzoSpedizione.cap} {ordine.indirizzoSpedizione.citta} (
            {ordine.indirizzoSpedizione.provincia}) {ordine.indirizzoSpedizione.nazione || 'Italia'}
            <br />
            {ordine.indirizzoSpedizione.consegnaPresso}
            <br />
            {ordine.indirizzoSpedizione.informazioniAggiuntive}
          </p>
          <p>
            <span>Indirizzo di fatturazione</span>
            <span>
              {ordine.indirizzoFatturazione.nome} {ordine.indirizzoFatturazione.cognome}
            </span>
            {ordine.indirizzoFatturazione.indirizzo}
            <br />
            {ordine.indirizzoFatturazione.cap} {ordine.indirizzoFatturazione.citta} (
            {ordine.indirizzoFatturazione.provincia}){' '}
            {ordine.indirizzoFatturazione.nazione || 'Italia'}
            <br />
            {ordine.indirizzoFatturazione.cf}
          </p>
        </div>
      </div>
      <Button label="Continua lo shopping" href="/" classes="esito-checkout__cta" />
    </div>
  ) : (
    <div className={'esito-checkout ' + classes}>
      <h2 className="esito-checkout__title">Acquisto non riuscito</h2>
      <p className="esito-checkout__disclaimer">
        Il tuo ordine non è andato a buon fine. Prova a controllare i dati inseriti oppure modifica
        il metodo di pagamento.
      </p>
      <Button
        label="Concludi l'acquisto"
        href="/cart/checkout"
        classes="esito-checkout__cta-error"
      />
      <h3 className="esito-checkout__title-help">Ti serve aiuto?</h3>
      <p className="esito-checkout__disclaimer">
        Se hai dei dubbi o delle domande, visita la nostra pagina FAQ oppure contattaci
        all’indirizzo{' '}
        <a href="mailto:info@matis-paris.it" className="esito-checkout__email">
          info@matis-paris.it
        </a>
      </p>
    </div>
  )
}

export default EsitoCheckout
