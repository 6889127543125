import React from 'react'

function Plus({ classes }) {
  return (
    <svg className={'icon icon--strokes ' + classes} viewBox="0 0 24 24" fill="none">
      <path d="M12.112 3.06433V21.1596" strokeWidth="2" />
      <path d="M21.1596 12.1119H3.06433" strokeWidth="2" />
    </svg>
  )
}

export default Plus
