import { BreadcrumbItem, JsonLd } from 'components/atoms'
import { DOMAIN } from 'utils'

function Breadcrumbs({ breadcrumbs = [], white = false, classes = '', linea = false }) {
  const jsonLdItems = breadcrumbs.map((item, i) => ({
    position: i + 1,
    name: item.titolo ? item.titolo.replace(/"/g, '') : '',
    item: `${DOMAIN}${item.url || '/'}`, // per homepage
  }))

  return (
    <section className={`breadcrumbs ${classes}`}>
      {breadcrumbs.map((item, index) => (
        <BreadcrumbItem
          titolo={item.titolo}
          url={item.url}
          last={index === breadcrumbs.length - 1}
          white={white}
          linea={linea}
          key={index}
        />
      ))}
      <JsonLd item={jsonLdItems} type="breadcrumbs" />
    </section>
  )
}

export default Breadcrumbs
