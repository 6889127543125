import React from 'react'
import { Check } from '../Icons'
import Alert from '../Icons/Alert'
import Remove from '../Icons/Remove'

const NotificationBar = ({ placeholder, type, close, classes = '' }) => {
  if (type === 'error')
    return close ? (
      <div className={'notification-bar ' + classes}>
        <Alert />
        <div className="notification-bar__placeholder">{placeholder}</div>
        <Remove />
      </div>
    ) : (
      <div className={'notification-bar ' + classes}>
        <Alert />
        <div className="notification-bar__placeholder">{placeholder}</div>
      </div>
    )

  if (type === 'success')
    return close ? (
      <div className={'notification-bar notification-bar--success ' + classes}>
        <Check />
        <div className="notification-bar__placeholder">{placeholder}</div>
        <Remove />
      </div>
    ) : (
      <div className={'notification-bar notification-bar--success ' + classes}>
        <Check />
        <div className="notification-bar__placeholder">{placeholder}</div>
      </div>
    )

  return close ? (
    <div className={'notification-bar notification-bar--default ' + classes}>
      <div className="notification-bar__placeholder">{placeholder}</div>
      <Remove />
    </div>
  ) : (
    <div className={'notification-bar__default ' + classes}>
      <div className="notification-bar__placeholder">{placeholder}</div>
    </div>
  )
}

export default NotificationBar
