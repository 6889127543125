import { ChevronRight } from 'components/atoms/Icons'
import React from 'react'

const StepItem = ({ titolo, num = 0, active = false, disabled = false, last = false }) => {
  return (
    <div className={`steps__item`}>
      {active ? (
        <span className={'steps__item__label steps__item__label--active'}>
          {num}. {titolo}
        </span>
      ) : disabled ? (
        <span className={'steps__item__label steps__item__label--disabled'}>
          {num}. {titolo}
        </span>
      ) : (
        <span className={'steps__item__label'}>
          {num}. {titolo}
        </span>
      )}
      {disabled && num > 0 && !last ? (
        <ChevronRight classes="steps__item__icon steps__item__icon--disabled" />
      ) : !last ? (
        <ChevronRight classes="steps__item__icon" />
      ) : null}
    </div>
  )
}

export default StepItem
