import React from 'react'
import Link from 'next/link'
import { ChevronLeft } from 'components/atoms/Icons'
import { MyAccountBlock, OrdineRiepilogo, OrdineStato } from 'components/molecules'
import { Button } from 'components/atoms'

const MyAccountOrdiniDetail = ({ ordine, isIndex }) => {
  return (
    <div className="myaccount-ordini-detail">
      {isIndex ? (
        <div className="myaccount-ordini-detail__title">
          <span>
            <strong>N° Ordine {ordine.pk}</strong> | Data {ordine.data}
          </span>
        </div>
      ) : (
        <Link legacyBehavior href="/myaccount/ordini">
          <a className="myaccount-ordini-detail__back">
            <ChevronLeft classes="myaccount-ordini-detail__back-icon" />
            <span>
              <strong>N° Ordine {ordine.pk}</strong> | Data {ordine.data}
            </span>
          </a>
        </Link>
      )}
      {ordine.attivo && (
        <div className="myaccount-ordini-detail__status">
          <OrdineStato ordine={ordine} />
        </div>
      )}
      <div className="myaccount-ordini-detail__top-details">
        <div className="myaccount-ordini-detail__top-detail">
          <p className="myaccount-ordini-detail__top-detail__title">Indirizzo di spedizione</p>
          <p className="myaccount-ordini-detail__top-detail__content">
            {ordine.indirizzoSpedizione.nome} {ordine.indirizzoSpedizione.cognome}
            <br />
            {ordine.indirizzoSpedizione.indirizzo}
            <br />
            {ordine.indirizzoSpedizione.cap} {ordine.indirizzoSpedizione.citta} (
            {ordine.indirizzoSpedizione.provincia})
          </p>
        </div>
        <div className="myaccount-ordini-detail__top-detail">
          <p className="myaccount-ordini-detail__top-detail__title">Indirizzo di fatturazione</p>
          <p className="myaccount-ordini-detail__top-detail__content">
            {ordine.indirizzoFatturazione.nome} {ordine.indirizzoFatturazione.cognome}
            <br />
            {ordine.indirizzoFatturazione.indirizzo}
            <br />
            {ordine.indirizzoFatturazione.cap} {ordine.indirizzoFatturazione.citta} (
            {ordine.indirizzoFatturazione.provincia})<br />
            {ordine.indirizzoFatturazione.cf}
          </p>
        </div>
        <div className="myaccount-ordini-detail__top-detail">
          <p className="myaccount-ordini-detail__top-detail__title">Metodo di pagamento</p>
          <p className="myaccount-ordini-detail__top-detail__content">
            {ordine.sistemaPagamento.nome}
          </p>
        </div>
      </div>
      <div className="myaccount-ordini-detail__main">
        <MyAccountBlock title="Riepilogo ordine">
          <OrdineRiepilogo ordine={ordine} />
          {!isIndex && !ordine.attivo && (
            <Button
              href="/condizioni-vendita"
              label="Informazioni su spedizioni e resi"
              type="secondary"
              size="md"
              classes="myaccount-ordini-detail__info-button"
            />
          )}
        </MyAccountBlock>
      </div>
    </div>
  )
}

export default MyAccountOrdiniDetail
