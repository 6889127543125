// Qui solo funzioni che non facciano crashare Storybook, thanks <3

export const numberNotation = (valore, numeroDecimali) => {
  let valoreSafe = parseFloat(valore) || 0
  let [intPart, decimals] = valoreSafe.toFixed(numeroDecimali).toString().split('.')
  return intPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + (numeroDecimali > 0 ? ',' + decimals : '')
}

// Lo distinguo da numberNotation, non si sa mai che i prezzi possano avere un formato diverso successivamente (es includere valuta)
export const priceNotation = (valore) => {
  return '€ ' + numberNotation(valore, 2).toString()
}

export const dateIsoToIt = (dateIso) => {
  let dateParts = dateIso ? dateIso.match(/([0-9]{4})-([0-9]{2})-([0-9]{2})/) : null
  return dateParts ? dateParts[3] + '/' + dateParts[2] + '/' + dateParts[1] : ''
}

export const findIndex = (list, obj, prop) => {
  for (let i = 0; i < list.length; i += 1) {
    if (list[i][prop] === obj[prop]) {
      return i
    }
  }
  return -1
}

export const normalizeGraphQLResults = (obj) => {
  if (!obj) return obj

  if (typeof obj === 'object') {
    if (obj.edges) {
      if (obj.edges.length > 0) return obj.edges.map((o) => normalizeGraphQLResults(o.node))
      else return []
    }

    if (Array.isArray(obj)) {
      return obj
    }

    let result = {}
    Object.keys(obj).forEach(function (k) {
      result = { ...result, [k]: normalizeGraphQLResults(obj[k]) }
    })
    return result
  }

  return obj
}
