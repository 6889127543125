import React, { useState } from 'react'
import Link from 'next/link'
import { ArrowRight, ChevronLeft } from 'components/atoms/Icons'
import { SubMenuMobile } from 'components/molecules'
import { trackingGAevent } from 'utils/gtm'

const MenuItemMobile = ({
  item,
  type,
  setMenuMobileOpen,
  className,
  level = 0,
  ancestors = [],
  rootLabel = '',
  boldLabel = '',
}) => {
  const [childMenuOpen, setChildMenuOpen] = useState(false)
  const hasChildren = item?.node?.children?.edges?.length > 0

  const classes =
    'menuitem-mobile' +
    (type ? ' menuitem-mobile--' + type : '') +
    (hasChildren ? ' menuitem-mobile--with-children' : '') +
    (className ? ' ' + className : '')

  const submenu = hasChildren ? (
    <SubMenuMobile
      parent={item}
      open={childMenuOpen}
      onBack={() => setChildMenuOpen(false)}
      setMenuMobileOpen={setMenuMobileOpen}
      level={level + 1}
      ancestors={ancestors.concat(item.node.name)}
    />
  ) : (
    <></>
  )

  function handleTracking() {
    const parents = [...new Set(ancestors)]

    const rootLabel = parents.length ? parents[0] : ''
    const boldLabel = parents.length > 1 ? parents[1] : ''

    if (!level) {
      trackingGAevent(19, 'menu', 'click', item.node.name) // primo livello
    } else {
      if (level === 2) {
        trackingGAevent(20, 'menu', `click ${rootLabel}`, item.node.name) // secondo livello (da mobile è doppio)
      } else {
        trackingGAevent(20, 'menu', `click ${rootLabel}`, `${boldLabel} ${item.node.name}`) // terzo livello
      }
    }
  }

  if (hasChildren) {
    return (
      <>
        <div className={classes}>
          {item?.node?.url ? (
            <Link legacyBehavior href={item.node.url}>
              <a
                className="menuitem-mobile__label"
                onClick={() => {
                  setMenuMobileOpen(false)
                  handleTracking()
                }}
              >
                {item.node.name}
              </a>
            </Link>
          ) : (
            <span className="menuitem-mobile__label" onClick={() => setChildMenuOpen(true)}>
              {item.node.name}
            </span>
          )}
          <span className={`menuitem-mobile__icon`} onClick={() => setChildMenuOpen(true)}>
            <ArrowRight />
          </span>
        </div>
        {submenu}
      </>
    )
  }

  return item?.node?.url ? (
    <Link legacyBehavior href={item.node.url}>
      <a
        onClick={() => {
          setMenuMobileOpen(false)
          handleTracking()
        }}
        className={classes}
      >
        {item.node.name}
      </a>
    </Link>
  ) : (
    <span className={classes}>{item.node.name}</span>
  )
}

export default MenuItemMobile
