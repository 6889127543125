import { ImageBox } from 'components/atoms'

function BeautyRoutine({ steps = [], rituale = true }) {
  return (
    <section className={`beauty-routine`}>
      <h3 className="beauty-routine__title">
        {rituale ? 'Rituale di bellezza' : 'Consigli di applicazione'}
      </h3>
      <main className="beauty-routine__steps">
        {steps.map((step, index) => (
          <article className="beauty-routine__steps__item" key={index}>
            <ImageBox
              src={step.image}
              maxWidth={100}
              maxHeight={100}
              alt={step.nome.toAltTitle()}
              title={step.nome.toAltTitle()}
              classPrefix="beauty-routine__steps__item"
              lazyLoad
            />
            <p className="beauty-routine__steps__item__title">{step.nome}</p>
            <div
              className="beauty-routine__steps__item__description"
              dangerouslySetInnerHTML={{ __html: step.descrizione }}
            />
          </article>
        ))}
      </main>
    </section>
  )
}

export default BeautyRoutine
