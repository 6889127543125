import React from 'react'

const Checkbox = ({ name, disabled, onChange, checked, label, classes = '' }) => {
  return (
    <label className={'checkbox__container ' + classes}>
      <div className="checkbox__container__label">{label}</div>
      <input
        id={name}
        name={name}
        onChange={onChange}
        type={'checkbox'}
        disabled={disabled}
        checked={checked}
      />
      <span className="checkbox__container__checkmark" />
    </label>
  )
}

export default Checkbox
