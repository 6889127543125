import Head from 'next/head'
import React from 'react'
import LazyLoad from 'react-lazyload'

const WImage = ({
  src,
  alt,
  title,
  classPrefix,
  className,
  maxWidth,
  maxHeight,
  lazyLoad,
  preLoad = false,
}) => {
  const content = (
    <img
      src={src ? src : 'https://via.placeholder.com/' + maxWidth + 'x' + maxHeight}
      alt={alt}
      title={title}
      className={(classPrefix ? classPrefix + '__image ' : '') + (className ? className : '')}
    />
  )

  return lazyLoad ? (
    <LazyLoad offset={100} once>
      {/* Height dipende dalla risoluzione - viene definito nel css, non lo metto qui */}
      {content}
    </LazyLoad>
  ) : (
    <>
      {preLoad && src && (
        <Head>
          <link rel="preload" href={src} as="image" />
        </Head>
      )}
      {content}
    </>
  )
}

export default WImage
