function Button(props) {
  const {
    type = 'primary',
    inputType = 'button',
    size = 'md',
    responsive, // In mobile diventa md => sm
    label = null,
    href = null,
    disabled = false,
    classes = '',
    onClick = () => {},
    blank = false,
    icon = false,
    iconPos = 'left',
  } = props

  const ButtonIcon = () => {
    return <span className={`button__icon`}>{icon}</span>
  }

  const ButtonContent = () => {
    return (
      <>
        {icon && iconPos !== 'right' && <ButtonIcon />}
        {label && <span className="button__label">{label}</span>}
        {icon && iconPos === 'right' && <ButtonIcon />}
      </>
    )
  }

  const classNames = `button button--${type} button--${size}${
    responsive ? ' button--responsive' : ''
  } ${classes}`

  if (href)
    return (
      <a
        className={classNames}
        disabled={disabled}
        onClick={onClick}
        href={href}
        target={blank ? '_blank' : ''}
      >
        <ButtonContent />
      </a>
    )

  return (
    <button className={classNames} disabled={disabled} onClick={onClick} type={inputType}>
      <ButtonContent />
    </button>
  )
}

export default Button
