import React from 'react'
import { ProgressBar, Button } from 'components/atoms'
import { findIndex } from 'utils/safe'
import { useTrans } from 'utils/hooks'
import { trackingGAevent } from 'utils/gtm'

const OrdineStato = ({ ordine }) => {
  const t = useTrans()
  const statiAttivi = [
    { pk: 4, nome: 'In attesa di pagamento', chiave: 'in-attesa-di-pagamento' },
    { pk: 1, nome: 'In elaborazione', chiave: 'aperto' },
    { pk: 2, nome: 'In attesa di spedizione', chiave: 'confermato' },
    { pk: 9, nome: 'In spedizione', chiave: 'in-spedizione' },
    { pk: 3, nome: 'Spedito', chiave: 'spedito' },
  ]

  // Lo stato "in-attesa-di-pagamento" c'è solo per il bonifico, rimuovo per gli altri
  const statiAttiviFixBonifico =
    ordine.sistemaPagamento.alias?.toLowerCase() === 'bonifico' ? statiAttivi : statiAttivi.slice(1)

  const statoOrdineAttivoIndex = findIndex(statiAttiviFixBonifico, ordine.stato, 'pk')

  return statoOrdineAttivoIndex > -1 ? (
    <div className="ordine-stato">
      <p className="ordine-stato__title">{t("Stato dell'ordine")}</p>
      <div className="ordine-stato__progress">
        <ProgressBar
          steps={statiAttiviFixBonifico.map((stato) => stato.nome)}
          activeStepIndex={statoOrdineAttivoIndex}
          addLast={true}
        />
      </div>

      <div className="ordine-stato__actions">
        {ordine.trackingUrl && (
          <Button
            href={ordine.trackingUrl}
            label={'Traccia la spedizione'}
            type="primary"
            onClick={() => trackingGAevent(34, 'my_account', 'ordini', 'traccia_la_spedizione')}
          />
        )}
        <Button
          href="/condizioni-vendita"
          label={'Informazioni su spedizioni e resi'}
          type="ghost"
          onClick={() =>
            trackingGAevent(34, 'my_account', 'ordini', 'informazioni_su_spedizioni_e_resi')
          }
        />
      </div>
    </div>
  ) : (
    <></>
  )
}

export default OrdineStato
