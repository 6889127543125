import React from 'react'

function Heart(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--strokes icon--heart ' + (props.classes ? props.classes : '')}
    >
      <path
        fill="none"
        d="M11.1672 6.47314L12.0005 7.72724L12.8332 6.47274C12.9285 6.32921 13.0316 6.19213 13.142 6.06251C13.9565 5.10604 15.2101 4.5 16.5 4.5C17.6329 4.5 18.6302 4.94147 19.3443 5.65566C20.0585 6.36985 20.5 7.36713 20.5 8.5C20.5 10.0017 19.8159 11.4374 18.4121 13.1199C17.0801 14.7162 15.2055 16.4149 12.894 18.5094C12.7492 18.6407 12.6026 18.7735 12.4544 18.908L12.4529 18.9093L11.9962 19.325L11.5588 18.9299C11.5584 18.9296 11.5581 18.9293 11.5578 18.929C11.4087 18.7935 11.2613 18.6596 11.1157 18.5274C8.79475 16.4198 6.91771 14.7153 5.58569 13.117C4.18159 11.4323 3.5 9.99896 3.5 8.5C3.5 7.36713 3.94147 6.36985 4.65566 5.65566C5.36985 4.94147 6.36713 4.5 7.5 4.5C8.78987 4.5 10.0435 5.10604 10.858 6.06251C10.9684 6.19213 11.0716 6.32932 11.1672 6.47314Z"
        strokeWidth="2"
      />
    </svg>
  )
}

export default Heart
