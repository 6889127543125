import React from 'react'

function CheckboxEmpty(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--strokes icon--checkbox-empty ' + (props.classes ? props.classes : '')}
    >
      <rect
        x="4"
        y="4"
        width="16"
        height="16"
        rx="1"
        fill="none"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CheckboxEmpty
