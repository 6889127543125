import React from 'react'

function PinEmpty(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--strokes icon--pin-empty ' + (props.classes ? props.classes : '')}
    >
      <path
        fill="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C13.9348 3 15.6871 3.75936 16.9552 4.98908C18.2177 6.21332 19 7.90421 19 9.77273C19 12.3217 16.6228 16.3 11.9993 21.7304C7.3769 16.2995 5 12.3215 5 9.77273C5 7.90421 5.78227 6.21332 7.04477 4.98908C8.31293 3.75936 10.0652 3 12 3ZM12 7.83333C11.4017 7.83333 10.86 8.07585 10.4679 8.46794C10.0758 8.86002 9.83333 9.40169 9.83333 10C9.83333 10.5983 10.0758 11.14 10.4679 11.5321C10.86 11.9242 11.4017 12.1667 12 12.1667C12.5983 12.1667 13.14 11.9242 13.5321 11.5321C13.9242 11.14 14.1667 10.5983 14.1667 10C14.1667 9.40169 13.9242 8.86002 13.5321 8.46794C13.14 8.07585 12.5983 7.83333 12 7.83333Z"
        strokeWidth="2"
      />
    </svg>
  )
}

export default PinEmpty
