import React from 'react'
import { OverlayTrigger, Tooltip as BTooltip } from 'react-bootstrap'

const Tooltip = (mainProps) => {
  const {
    label = '',
    id = '',
    pos = 'top',
    size = 'md',
    forceShow = undefined,
    className = '',
    children = null,
  } = mainProps

  const classNames = `tooltip tooltip--${size} ${className}`

  const renderTooltip = (props) => (
    <BTooltip id={id} className={classNames} {...props}>
      {label}
    </BTooltip>
  )

  return forceShow !== undefined ? (
    <OverlayTrigger
      show={forceShow}
      placement={pos}
      delay={{ show: 50, hide: 50 }}
      overlay={renderTooltip}
    >
      {children}
    </OverlayTrigger>
  ) : (
    <OverlayTrigger placement={pos} delay={{ show: 50, hide: 50 }} overlay={renderTooltip}>
      {children}
    </OverlayTrigger>
  )
}

export default Tooltip
