import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { FixedSizeList as List } from 'react-window'
import { ChevronDown, Info } from 'components/atoms/Icons'

const DropdownIndicator = () => (
  <div className="form-select__dropdown-indicator">
    <ChevronDown />
  </div>
)

const height = 48

const MenuList = (props) => {
  const { options, children, maxHeight, getValue } = props
  const [value] = getValue()
  const initialOffset = options.indexOf(value) * height

  return (
    <List
      height={
        options?.length
          ? options.length * height < maxHeight
            ? options.length * height
            : maxHeight
          : 0
      }
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  )
}

const FormSelect = ({
  placeholder,
  name,
  value,
  className = '',
  disabled,
  status,
  onBlur,
  onFocus,
  onChange,
  errorMessage,
  options,
  isSearchable = false,
}) => {
  const [focused, setFocused] = useState(false)
  const [filled, setFilled] = useState(
    options?.find((option) => option.selected) || value ? true : false
  )
  const localOnChange = (selectedOption, action) => {
    setFilled(selectedOption && selectedOption.value ? true : false)
    if (onChange) onChange(selectedOption, action)
  }
  const localOnFocus = (e) => {
    setFocused(true)
    if (onFocus) onFocus(e)
  }
  const localOnBlur = (e) => {
    setFocused(false)
    if (onBlur) onBlur(e)
  }

  useEffect(() => {
    if (options?.find((option) => option.selected)) setFilled(true)
  }, [options])

  return (
    <div className="form-field__input-wrap">
      <Select
        className={
          'form-field__input' +
          (status ? ` form-field__input--${status}` : '') +
          (placeholder ? '' : ' form-field__input--no-placeholder') +
          (filled || focused ? ' form-field__input--placeholder-up' : '') +
          ' form-select ' +
          className
        }
        classNamePrefix="form-select"
        closeMenuOnSelect
        isClearable={false}
        isSearchable={isSearchable}
        isDisabled={disabled || false}
        instanceId={name}
        name={name}
        placeholder={''}
        components={{ DropdownIndicator, MenuList }}
        onChange={localOnChange}
        onFocus={localOnFocus}
        onBlur={localOnBlur}
        options={options}
        value={options?.find((option) => option.selected) || value}
      />
      {placeholder ? <span className="form-field__input-placeholder">{placeholder}</span> : <></>}
      {status === 'error' && errorMessage ? (
        <p className="form-field__input-error">
          <Info classes="form-field__input-error-icon" />
          {errorMessage}
        </p>
      ) : (
        <></>
      )}
    </div>
  )
}

export default FormSelect
