import { Arrow2Right } from 'components/atoms/Icons'
import { Button, ImageBox } from 'components/atoms'

function BoxMarketing({ box }) {
  const imagePosition = box.imagePosition.toLowerCase()
  const alignment = box.cta ? 'default' : 'center'

  const classes = `box-marketing box-marketing--${imagePosition} box-marketing--${alignment}`

  return (
    <div className={classes}>
      <main className="box-marketing__content">
        <p className="box-marketing__content__title">{box.titolo}</p>
        <div
          className="box-marketing__content__text"
          dangerouslySetInnerHTML={{ __html: box.testo }}
        />
        {box.cta && (
          <Button
            href={box.finalUrl}
            label={box.cta}
            type="primary"
            blank={box.blank}
            icon={<Arrow2Right />}
            iconPos="right"
            classes="box-marketing__content__cta"
          />
        )}
      </main>
      <ImageBox
        src={box.imageThumb}
        maxWidth={370}
        maxHeight={370}
        classPrefix="box-marketing"
        alt={box.titolo.toAltTitle()}
        title={box.titolo.toAltTitle()}
      />
    </div>
  )
}

export default BoxMarketing
