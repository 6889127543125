import { useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import {
  FormLayout,
  FormField,
  FormInput,
  FormRadio,
  FormCheckbox,
  FormSelect,
} from 'components/atoms'
import { MyAccountBlock } from 'components/molecules'
import { ANNI, GIORNI, MESI } from 'utils/forms'
import { UPDATE_UTENTE } from 'utils/queries'
import { datiPersonali } from 'utils/validation'

const FormDatiPersonali = ({ utente, setEdit }) => {
  const initialValues = {
    nome: utente.nome,
    cognome: utente.cognome,
    telefono: utente.telefono,
    sesso: utente.sesso,
    giorno: utente.dataNascita ? parseInt(utente.dataNascita.split('-')[2]) : '',
    mese: utente.dataNascita ? parseInt(utente.dataNascita.split('-')[1]) : '',
    anno: utente.dataNascita ? parseInt(utente.dataNascita.split('-')[0]) : '',
  }

  const [updateUtente, { data }] = useMutation(UPDATE_UTENTE)

  async function handleSubmit(values, setSubmitting) {
    setSubmitting(true)

    const response = await updateUtente({
      variables: {
        input: {
          nome: values.nome,
          cognome: values.cognome,
          giornoNascita: values.giorno.toString(),
          meseNascita: values.mese.toString(),
          annoNascita: values.anno.toString(),
          telefono: values.telefono,
          sesso: values.sesso,
        },
      },
      refetchQueries: ['Me'],
    })

    setSubmitting(false)
    setEdit(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={datiPersonali}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting)
      }}
    >
      {({ values, errors, handleChange, isSubmitting, setFieldValue, status }) => (
        <Form>
          <MyAccountBlock
            title="Dati personali"
            submitting={isSubmitting}
            edit={true}
            setEdit={(val) => {
              setEdit(val)
            }}
          >
            <FormLayout>
              <FormField>
                <FormInput
                  name="nome"
                  value={values.nome}
                  placeholder="Nome*"
                  onChange={handleChange}
                  errorMessage={errors.nome}
                  status={errors.nome ? 'error' : null}
                />
              </FormField>
              <FormField>
                <FormInput
                  name="cognome"
                  value={values.cognome}
                  placeholder="Cognome*"
                  onChange={handleChange}
                  errorMessage={errors.cognome}
                  status={errors.cognome ? 'error' : null}
                />
              </FormField>
              <FormField size="full" space={'md'}>
                <FormInput
                  name="telefono"
                  value={values.telefono}
                  placeholder="Numero di telefono*"
                  onChange={handleChange}
                  errorMessage={errors.telefono}
                  status={errors.telefono ? 'error' : null}
                />
              </FormField>
              <FormField label="Data di nascita" size="full" variant="date" space={'md'}>
                <FormSelect
                  name="giorno"
                  options={GIORNI.map((g) => ({
                    value: g,
                    label: g,
                    selected: g === values.giorno,
                  }))}
                  placeholder="Giorno"
                  onChange={({ value }) => setFieldValue('giorno', value)}
                />
                <FormSelect
                  name="mese"
                  options={MESI.map((m) => ({ value: m, label: m, selected: m === values.mese }))}
                  placeholder="Mese"
                  onChange={({ value }) => setFieldValue('mese', value)}
                />
                <FormSelect
                  name="anno"
                  options={ANNI.map((a) => ({ value: a, label: a, selected: a === values.anno }))}
                  placeholder="Anno"
                  onChange={({ value }) => setFieldValue('anno', value)}
                />
              </FormField>
              <FormField label="Sesso">
                <FormRadio
                  name="sesso"
                  onChange={(e) => {
                    setFieldValue('sesso', e.target.value)
                  }}
                  value={values.sesso}
                  options={[
                    { label: 'Donna', value: 'd' },
                    { label: 'Uomo', value: 'u' },
                    { label: 'Preferisco non rispondere', value: 'x' },
                  ]}
                />
              </FormField>
            </FormLayout>
          </MyAccountBlock>
        </Form>
      )}
    </Formik>
  )
}

export default FormDatiPersonali
