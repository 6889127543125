import React, { useState, useEffect, useRef } from 'react'
import { Search } from 'components/atoms/Icons'
import { Button } from 'components/atoms'
import { useTrans } from 'utils/hooks'
import { trackingGAevent } from 'utils/gtm'

const SearchForm = ({ mobile, searchMobileOpen }) => {
  const t = useTrans()

  const [query, setQuery] = useState('')
  const inputElement = useRef(null)

  function handleQuery(e) {
    setQuery(e.target.value)
  }

  useEffect(() => {
    if (inputElement.current && searchMobileOpen) {
      inputElement.current.focus()
    }
  }, [searchMobileOpen])

  return (
    <div className={'search-form' + (mobile ? ' search-form--mobile' : '')}>
      <form
        action="/ricerca"
        className="search-form__form"
        onSubmit={() => trackingGAevent(13, 'search', query)}
      >
        {mobile ? (
          <Button
            classes="search-form__submit"
            inputType="submit"
            label={mobile ? t('Cerca') : ''}
            icon={mobile ? null : <Search />}
            type={mobile ? 'ghost' : 'primary'}
          />
        ) : (
          <button className="search-form__submit" role="submit" type="submit">
            <Search />
          </button>
        )}
        <input
          className="search-form__input"
          type="text"
          name="q"
          placeholder="Cerca"
          onChange={handleQuery}
          ref={inputElement}
        />
      </form>
    </div>
  )
}

export default SearchForm
