import React from 'react'

function ChevronDown({ classes }) {
  return (
    <svg
      viewBox="0 0 24 24"
      className={'icon icon--strokes icon--chevron ' + (classes ? classes : '')}
    >
      <path fill="none" d="M20 8L12 16L4 8" strokeWidth="2" />
    </svg>
  )
}

export default ChevronDown
