import axios from 'axios'
import getConfig from 'next/config'
import { PAGEBUILDER } from 'utils/endpoint'

const { publicRuntimeConfig } = getConfig()

export const API_HOST = publicRuntimeConfig.API_HOST
export const API_PREFIX = '/d/api/'
export const API_FULL_PATH = `${API_HOST}${API_PREFIX}`

export const GRAPHENE_HOST = publicRuntimeConfig.API_HOST
export const GRAPHENE_PREFIX = '/d/graphql/'
export const GRAPHENE_FULL_PATH = `${GRAPHENE_HOST}${GRAPHENE_PREFIX}`

export const api = axios.create({
  baseURL: API_FULL_PATH,
  withCredentials: true,
})

export const graphene = axios.create({
  baseURL: GRAPHENE_FULL_PATH,
  withCredentials: true,
})

export const toggle = (array, value) => {
  const index = array.indexOf(value)

  if (index === -1) {
    array.push(value)
  } else {
    array.splice(index, 1)
  }
}

String.prototype.capitalize = function () {
  return this.toLowerCase().charAt(0).toUpperCase() + this.slice(1)
}

String.prototype.toAltTitle = function () {
  return this ? `${this} - ${BRAND}`.replace(/  +/g, ' ') : BRAND
}

export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export const initServiceWorker = () => {
  if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
    navigator.serviceWorker
      .register('/sw.js')
      .catch((err) => console.error('Service worker registration failed', err))
  }
}

export const getPlaceholder = async (params) => {
  const key = `key=${params.key}`
  const contentTypeId = params.contentTypeId ? `&content_type_id=${params.contentTypeId}` : ''
  const objectId = params.objectId ? `&object_id=${params.objectId}` : ''

  const response = await api.get(`${PAGEBUILDER}?${key}${contentTypeId}${objectId}`, {
    headers: params.headers,
  })
  const placeholder = response.data

  return placeholder
}

export function inViewport(el) {
  if (!el) return false

  const rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export function generateArrayOfYears() {
  const annoCorrente = new Date().getFullYear()
  var min = annoCorrente - 100
  var years = []

  for (var i = annoCorrente; i >= min; i--) {
    years.push(i)
  }
  return years
}

export const DOMAIN = process.env.NODE_ENV === 'production' ? 'https://www.matis-paris.it' : ''
export const BRAND = 'Matis Paris'

export const RES_SM_MIN = 576

export const FACEBOOK_APP_ID = '1063247584177724'
export const FACEBOOK_PIXEL_ID = '269404935569564'
export const GOOGLE_APP_ID =
  '778012777423-5cmapitaqivgjn1pf217umaa6mtjv1ju.apps.googleusercontent.com'

export const RICERCA_ELEMENTS_PER_PAGE = 9

export const scrollToTarget = (timing) => {
  setTimeout(() => {
    if (typeof window !== 'undefined') {
      const hashId = window.location.hash

      if (hashId) {
        const element = document.querySelector(hashId)

        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          })
        }
      }
    }
  }, timing)
}

export const GOOGLE_MAPS_KEY = 'AIzaSyBblnvUCPEIQlBQgSXKuZ-lq7ZXpI7u3Dc'
export const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${GOOGLE_MAPS_KEY}`

export const LOGIN_FORBID_TIME = 60000 // 1 minuto
export const LOGIN_ATTEMPT_MESSAGE = 'Troppi tentativi. Riprova tra 1 minuto'

export const FREE_SHIPPING = 100

export const LABEL_CORRELATI = {
  linea: "L'intesa perfetta",
  caratteristiche: 'Lasciati ispirare',
}

export const LIST_CORRELATI = {
  linea: 'intesa_perfetta',
  caratteristiche: 'lasciati_ispirare',
}

export const LABEL_ORDINAMENTO = {
  'titolo': 'category_listing',
  'prezzo': 'sorting_increasing_price',
  '-prezzo': 'sorting_descreasing_price',
  'in_evidenza': 'most_popular', // predisposto
}

export const LABEL_SISTEMA_PAGAMENTO = {
  1: 'paypal',
  2: 'bonifico',
  3: 'gestpay',
}
