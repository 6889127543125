import { useQuery } from '@apollo/client'
import { FormSelect, FormLayout, FormField } from 'components/atoms'
import { CentroEsteticoListItem } from 'components/molecules'
import { useEffect, useState } from 'react'
import { CENTRI_ESTETICI } from 'utils/queries'
import { useTrans } from 'utils/hooks'

function SelezionaCentro({ formik, classes }) {
  const t = useTrans()
  const { loading, data } = useQuery(CENTRI_ESTETICI, { fetchPolicy: 'cache-first' })

  const [province, setProvince] = useState([])
  const [centri, setCentri] = useState([])

  const [filters, setFilters] = useState({ provincia: '', citta: '' })

  useEffect(() => {
    if (data?.centriEstetici) initProvince(data.centriEstetici.edges)
  }, [data])

  useEffect(() => {
    if (filters.citta) {
      const centri = data.centriEstetici.edges
        .filter(({ node }) => node.citta === filters.citta)
        .map(({ node }) => node)
      setCentri(centri)
    } else {
      setCentri([])
    }
  }, [filters.citta])

  function initProvince(centri) {
    const province = new Set(centri.map(({ node }) => node.provincia).sort())
    setProvince([...province])
  }

  function getCitta(provincia) {
    const citta = new Set(
      data.centriEstetici.edges
        .filter(({ node }) => node.provincia === provincia)
        .map(({ node }) => node.citta)
        .sort()
    )

    return [...citta].map((c) => ({ value: c, label: c, selected: false }))
  }

  function handleChangeFilters(filters) {
    setFilters(filters)
    formik.setFieldValue('centro', '')
  }

  return (
    <section className={'seleziona-centro ' + classes}>
      <h4 className="seleziona-centro__title">
        {t('seleziona-centro__title')}
        {/* Seleziona il tuo centro Matis di riferimento */}
      </h4>
      <p className="seleziona-centro__disclaimer">
        {t('seleziona-centro__description')}
        {/* Se non sei cliente Matis seleziona il centro estetico più vicino a casa tua. Al tuo primo
        acquisto on line, riceverai un buono sconto da poter utilizzare nell’Istituto selezionato. */}
      </p>
      <div className="seleziona-centro__filters">
        <FormField>
          <FormSelect
            name="provincia"
            options={province.map((p) => ({ value: p, label: p }))}
            placeholder={t('Seleziona una provincia')}
            onChange={({ value }) => handleChangeFilters({ citta: '', provincia: value })}
          />
        </FormField>
        {filters.provincia && (
          <FormField>
            <FormSelect
              value={filters.citta ? { value: filters.citta, label: filters.citta } : null}
              name="citta"
              options={getCitta(filters.provincia)}
              placeholder={t('Seleziona una città')}
              onChange={({ value }) => handleChangeFilters({ ...filters, citta: value })}
            />
          </FormField>
        )}
      </div>
      {filters.citta && (
        <p className="seleziona-centro__count">
          {centri.length === 1
            ? t('1 centro estetico trovato')
            : t('{0} centri estetici trovati', [centri.length])}
        </p>
      )}
      {filters.citta && (
        <div className="seleziona-centro__list">
          {centri.map((centro) => (
            <CentroEsteticoListItem
              key={centro.pk}
              centro={centro}
              onChange={formik.handleChange}
            />
          ))}
        </div>
      )}
    </section>
  )
}

export default SelezionaCentro
