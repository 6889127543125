import React, { useState } from 'react'
import { SubMenu } from 'components/molecules'
import { MenuItem } from 'components/atoms'

const Menu = ({ vociMenu }) => {
  const [currentItem, setCurrentItem] = useState(null)
  const isCurrentItem = (item) => currentItem?.node && currentItem.node.id == item.node.id
  return (
    <div className="menu" onMouseLeave={() => setCurrentItem(null)}>
      <nav className="menu__nav">
        {vociMenu?.edges?.length
          ? vociMenu.edges.map((item, i) => (
              <>
                <MenuItem
                  href={item?.node?.url}
                  key={'menu_key_' + i}
                  onMouseEnter={() => setCurrentItem(item)}
                  active={isCurrentItem(item)}
                  label={item.node.name}
                  type="main"
                />
                {item?.node?.children?.edges?.length ? (
                  <div
                    className={`menu__panel ${currentItem === item ? '' : 'hidden'}`}
                    onMouseEnter={() => setCurrentItem(item)}
                    onMouseLeave={() => setCurrentItem(null)}
                  >
                    <SubMenu menuItem={item} />
                  </div>
                ) : null}
              </>
            ))
          : null}
      </nav>
    </div>
  )
}

export default Menu
