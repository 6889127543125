import TagManager from 'react-gtm-module'
import replace from 'lodash/replace'
import { LABEL_SISTEMA_PAGAMENTO } from 'utils'

const GTM_PAGE_DEFAULT = '404'
const GTM_ID = 'GTM-PW369BR'

export const initGTMdataLayer = () => {
  TagManager.initialize({
    gtmId: GTM_ID,
    dataLayerName: 'dataLayer',
  })
}

export const sendGTMData = (data) => {
  TagManager.dataLayer({
    dataLayer: data,
    dataLayerName: 'dataLayer',
  })
}

let currentPage = ''
const getGTMProps = (paginaCorrente, router) => {
  let page = paginaCorrente?.pagina?.chiave || GTM_PAGE_DEFAULT
  let channel = 'corporate'
  let firstLevelCategory = ''
  let secondLevelCategory = ''
  let thirdLevelCategory = ''

  let pageType = 'corporate'

  if (page.includes('homepage')) pageType = 'home'
  if (page.includes('categoria')) pageType = 'listing'
  if (page.includes('linea')) pageType = 'listing'
  if (page.includes('prodotto')) pageType = 'product'
  if (page.includes('checkout')) pageType = 'checkout'
  if (page.includes('cart')) pageType = 'checkout'
  if (page.includes('checkout-success')) pageType = 'thankyou'
  if (page.includes('404')) pageType = '404'
  if (page.includes('myaccount')) pageType = 'my_account'
  if (page.includes('centri')) pageType = 'store_locator'
  if (page.includes('trattamenti')) pageType = 'trattamenti'
  if (page.includes('wishlist')) pageType = 'wishlist'

  if (
    page.includes('categoria') ||
    page.includes('prodotto') ||
    page.includes('linea') ||
    page.includes('checkout')
  )
    channel = 'store'

  if (page.includes('categoria') && router?.asPath) {
    let parts = router?.asPath.split('/')
    firstLevelCategory = parts[parts.indexOf('prodotti') + 1] || ''
    secondLevelCategory = parts[parts.indexOf('prodotti') + 2] || ''
    thirdLevelCategory = parts[parts.indexOf('prodotti') + 3] || ''
  }

  currentPage = page.includes('cart') ? 'cart' : pageType
  return {
    pageType,
    channel,
    firstLevelCategory,
    secondLevelCategory,
    thirdLevelCategory,
  }
}

const getUtenteProps = (utente, numProdottiCart, numProdottiWishlist) => {
  let login = utente ? 'logged' : 'not_logged'
  let userID = utente ? utente?.pk : 'not_logged'
  let registrationDate = utente ? utente?.dataRegistrazione : 'not_logged'
  let wishListTotalItems = numProdottiWishlist
  let cartTotalProducts = numProdottiCart

  return {
    login,
    userID,
    registrationDate,
    wishListTotalItems,
    cartTotalProducts,
  }
}

export const getPageType = () => {
  return currentPage
}

export const trackingProductDetail = (prodotto) => {
  let product = []

  const stored = JSON.parse(sessionStorage.getItem(`prodotto-${prodotto.pk}`)) || {
    position: 1,
    list: 'product_page',
  }

  product.push({
    name: uniformData(prodotto?.titolo),
    id: prodotto?.codice || '',
    price: prodotto?.prezzoScontato?.toFixed(2) || '',
    brand: 'matis_paris',
    category: uniformData(prodotto?.categoria?.titolo),
    variant: uniformData(prodotto?.linea?.nome),
    list: stored.list || 'product_page',
    position: stored.position,
    dimension7: prodotto?.disponibile ? 'disponibile' : 'no',
    dimension9: prodotto?.prezzo - prodotto?.prezzoScontato == 0 ? 'no_sale' : 'sale',
    metric1: (prodotto?.prezzo - prodotto?.prezzoScontato)?.toFixed(2) || '0.00',
  })

  sendGTMData({
    event: 'productDetail',
    ecommerce: {
      currencyCode: 'EUR',
      detail: {
        actionField: {
          list: stored.list || 'product_page',
        },
        products: product,
      },
    },
  })
}

export const trackingProductClick = (prodotto, position, sezione) => {
  let product = []
  const list = sezione || listType()

  sessionStorage.setItem(`prodotto-${prodotto.pk}`, JSON.stringify({ position, list }))

  product.push({
    name: uniformData(prodotto?.titolo),
    id: prodotto?.codice || '',
    price: prodotto?.prezzoScontato?.toFixed(2) || '',
    brand: 'matis_paris',
    category: uniformData(prodotto?.categoria?.titolo),
    variant: uniformData(prodotto?.linea?.nome),
    list,
    position,
    dimension7: prodotto?.disponibile ? 'disponibile' : 'no',
    dimension9: prodotto?.prezzo - prodotto?.prezzoScontato == 0 ? 'no_sale' : 'sale',
    metric1: (prodotto?.prezzo - prodotto?.prezzoScontato)?.toFixed(2) || '0.00',
  })
  sendGTMData({
    event: 'productClick',
    ecommerce: {
      currencyCode: 'EUR',
      click: {
        actionField: {
          list,
        },
        products: product,
      },
    },
  })
}

export const trackingProductImpression = (prodotti, sezione, startIndex = 0) => {
  let products = []
  for (let i = 0; prodotti && i < prodotti.length; i++) {
    let prodotto = sezione == 'wishlist' ? prodotti[i].prodotto : prodotti[i]
    products.push({
      name: uniformData(prodotto?.titolo),
      id: prodotto?.codice || '',
      price: prodotto?.prezzoScontato?.toFixed(2) || '',
      brand: 'matis_paris',
      category: uniformData(prodotto?.categoria?.titolo),
      variant: uniformData(prodotto?.linea?.nome),
      list: sezione,
      position: i + 1 + startIndex, // per infinite scroll
      dimension7: prodotto?.disponibile ? 'disponibile' : 'no',
      dimension9: prodotto?.prezzo - prodotto?.prezzoScontato == 0 ? 'no_sale' : 'sale',
    })
  }

  if (products.length > 0)
    sendGTMData({
      event: 'productsImpression',
      ecommerce: {
        currencyCode: 'EUR',
        impressions: products,
      },
    })
}

export const trackingPromoView = (promotions) => {
  let promo = []
  for (let i = 0; promotions && i < promotions.length; i++) {
    let promotion = promotions[i]
    promo.push({
      id: ('000' + i).slice(-4),
      name: uniformData(promotion?.nome),
      creative: 'slider',
      position: i + 1,
    })
  }
  sendGTMData({
    event: 'promoView',
    ecommerce: {
      promoView: {
        promotions: promo,
      },
    },
  })
}

export const trackingPromoClick = (promotion, position) => {
  let promo = []
  promo.push({
    id: ('000' + position).slice(-4),
    name: uniformData(promotion?.nome),
    creative: 'slider',
    position: position + 1,
  })
  sendGTMData({
    event: 'promoClick',
    ecommerce: {
      promoClick: {
        promotions: promo,
      },
    },
  })
}

export const trackingAddRemoveFromCart = (prodotto, quantita) => {
  let product = []
  let list = ''
  let position = 1

  if (prodotto.list && prodotto.position) {
    list = prodotto.list
    position = prodotto.position
  } else {
    const stored = JSON.parse(sessionStorage.getItem(`prodotto-${prodotto.pk}`)) || {
      position: 1,
      list: listType(),
    }
    list = stored.list
    position = stored.position
  }

  product.push({
    name: uniformData(prodotto?.titolo),
    id: prodotto?.codice || '',
    price: prodotto?.prezzoScontato?.toFixed(2) || '',
    brand: 'matis_paris',
    category: uniformData(prodotto?.categoria?.titolo),
    variant: uniformData(prodotto?.linea?.nome),
    list: ['popup', 'modifiche_cart'].includes(prodotto.type) ? '' : list,
    position: ['popup', 'modifiche_cart'].includes(prodotto.type) ? '' : position,
    dimension7: prodotto?.disponibile ? 'disponibile' : 'no',
    dimension9: prodotto?.prezzo - prodotto?.prezzoScontato == 0 ? 'no_sale' : 'sale',
    metric1: (prodotto?.prezzo - prodotto?.prezzoScontato)?.toFixed(2) || '0.00',
  })

  let type = 'modifiche_cart'
  switch (currentPage) {
    case 'wishlist':
      type = 'wishlist'
      break
    case 'listing':
      type = 'listing'
      break
    case 'product':
      type = 'product'
      break
    case 'home':
      type = 'listing'
      break
  }

  if (quantita == 1)
    sendGTMData({
      event: 'addToCart',
      add_type: prodotto.type || type,
      ecommerce: {
        currencyCode: 'EUR',
        add: {
          actionField: {
            list,
          },
          products: product,
        },
      },
    })
  else
    sendGTMData({
      event: 'removeFromCart',
      remove_type: prodotto.type || 'modifiche_cart',
      ecommerce: {
        currencyCode: 'EUR',
        remove: {
          actionField: {
            list,
          },
          products: product,
        },
      },
    })
}

export const trackingCheckout = (cart, step, values) => {
  let products = []
  let checkoutOpt = ''

  switch (step) {
    case 1:
      break
    case 2:
      checkoutOpt = 'spedizione_a_casa'
      break
    case 3:
      checkoutOpt = 'spedizione_a_casa'
      break
    case 4:
      checkoutOpt = LABEL_SISTEMA_PAGAMENTO[values?.sistemaPagamento || 1]
      break
  }

  for (let i = 0; cart && i < cart?.items.length; i++) {
    let prodotto = cart.items[i]?.prodotto
    products.push({
      name: uniformData(prodotto?.titolo),
      id: prodotto?.codice || '',
      price: prodotto?.prezzoScontato?.toFixed(2) || '',
      brand: 'matis_paris',
      category: uniformData(prodotto?.categoria?.titolo),
      variant: uniformData(prodotto?.linea?.nome),
      list: '',
      position: '',
      quantity: cart.items[i]?.quantita || '',
      dimension7: prodotto?.disponibile ? 'disponibile' : 'no',
      dimension9: prodotto?.prezzo - prodotto?.prezzoScontato == 0 ? 'no_sale' : 'sale',
      metric1: (prodotto?.prezzo - prodotto?.prezzoScontato)?.toFixed(2) || '0.00',
    })
  }

  if (cart?.items.length > 0)
    sendGTMData({
      event: 'checkout',
      ecommerce: {
        currencyCode: 'EUR',
        checkout: {
          actionField: {
            step: step,
            option: checkoutOpt,
          },
          products: products,
        },
      },
    })
}

export const trackingPurchase = (cart, id_ordine) => {
  let products = []

  for (let i = 0; cart && i < cart?.items?.length; i++) {
    let prodotto = cart.items[i]?.prodotto
    products.push({
      name: uniformData(prodotto?.titolo),
      id: prodotto?.codice || '',
      price: prodotto?.prezzoScontato?.toFixed(2) || '',
      brand: 'matis_paris',
      category: uniformData(prodotto?.categoria?.titolo),
      variant: uniformData(prodotto?.linea?.nome),
      list: '',
      position: '',
      quantity: cart.items[i]?.quantita || '',
      dimension7: prodotto?.disponibile ? 'disponibile' : 'no',
      dimension9: prodotto?.prezzo - prodotto?.prezzoScontato == 0 ? 'no_sale' : 'sale',
      metric1: (prodotto?.prezzo - prodotto?.prezzoScontato)?.toFixed(2) || '0.00',
    })
  }

  sendGTMData({
    event: 'purchase',
    ecommerce: {
      currencyCode: 'EUR',
      purchase: {
        actionField: {
          id: id_ordine,
          revenue: cart?.totaleProdottiScontato?.toFixed(2),
          tax: cart?.totaleProdottiScontato
            ? (cart.totaleProdottiScontato - (cart.totaleProdottiScontato / 122) * 100).toFixed(2)
            : 0,
          shipping: cart?.sistemaSpedizione?.prezzoScontato?.toFixed(2),
          tipo_spedizione: 'spedizione_a_casa',
          tipo_pagamento: LABEL_SISTEMA_PAGAMENTO[cart.sistemaPagamento.pk],
          coupon: cart?.promoApplicata == null ? 'no_promo' : cart?.promoApplicata,
          metric2: cart?.scontoAssoluto?.toFixed(2) || '0.00',
        },
        products: products,
      },
    },
  })
}

function uniformData(param) {
  // toLower + standardizzazione parametri
  return replace(replace(param, / /g, '_'), /-/g, '_').toLowerCase()
}

function uniformDataWithDash(param) {
  // toLower + standardizzazione parametri ma mantenimento del '-'
  return replace(param, / /g, '_').toLowerCase()
}

function listType() {
  // campo 'list' dei tracciamenti ecommerce
  let type = 'product_page'
  switch (currentPage) {
    case 'listing':
      type = 'category_listing'
      break
    case 'thankyou':
      type = 'thankyou_page'
      break
    case 'home':
      type = 'home_page'
      break
    case 'wishlist':
      type = 'wishlist'
      break
  }
  return type
}

export const trackingGAevent = (id, category, action, label, beauty_center, sku) => {
  let data = {
    event: 'GAevent',
    eventID: id,
    eventCategory: uniformData(category),
    eventAction: uniformData(action),
    eventLabel: [12, 50, 51].indexOf(id) < 0 ? uniformData(label) : uniformDataWithDash(label),
  }

  if (beauty_center) data['beauty_center'] = uniformData(beauty_center)
  if (sku) data['sku'] = uniformData(sku)

  sendGTMData(data)
}

export const updateGTMDataLayer = (
  paginaCorrente,
  utente,
  numProdottiCart,
  numProdottiWishlist,
  router
) => {
  const gtmProps = getGTMProps(paginaCorrente, router)
  const utenteProps = getUtenteProps(utente, numProdottiCart, numProdottiWishlist)

  sendGTMData({
    event: 'wSetup',
    ...gtmProps,
    ...utenteProps,
  })

  sendGTMData({
    event: 'virtual_pageview',
    pagePath: router.asPath,
  })
}
