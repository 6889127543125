import React from 'react'

function Crosshair({ classes }) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--strokes icon--crosshair ' + (classes ? classes : '')}
    >
      <path d="M12.1121 2.06445V6.11218" fill="none" strokeWidth="2" />
      <path d="M12.1121 18V22.0477" fill="none" strokeWidth="2" />
      <path d="M6.11182 12.1113H2.06431" fill="none" strokeWidth="2" />
      <path d="M22.0476 12.1113H18.0001" fill="none" strokeWidth="2" />
      <circle cx="12" cy="12" r="6" fill="none" strokeWidth="2" />
      <circle cx="12" cy="12" r="3" stroke="none" />
    </svg>
  )
}

export default Crosshair
