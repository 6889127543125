import { ImageBox } from 'components/atoms'

function GenericStep({ menu = {} }) {
  return (
    <section className={`generic-step`}>
      <div className={`generic-step__container`}>
        <h3 className="generic-step__title">{menu.name}</h3>
        <main className="generic-step__steps">
          {menu?.children?.edges.map((step, index) => (
            <article className="generic-step__steps__item" key={index}>
              <ImageBox
                src={step.node.image}
                maxWidth={100}
                maxHeight={100}
                alt={step.node.name.toAltTitle()}
                title={step.node.name.toAltTitle()}
                classPrefix="generic-step__steps__item"
                lazyLoad
              />
              <div className="generic-step__content">
                <p className="generic-step__steps__item__title">{step.node.name}</p>
                <div
                  className="generic-step__steps__item__description"
                  dangerouslySetInnerHTML={{ __html: step.node.description }}
                />
              </div>
            </article>
          ))}
        </main>
      </div>
    </section>
  )
}

export default GenericStep
