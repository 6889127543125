import React from 'react'

function ChevronRight({ classes = 'icon--chevron-right' }) {
  return (
    <svg className={'icon ' + classes} viewBox="0 0 8 12" fill="none">
      <path d="M1.66669 1.33337L6.33335 6.00004L1.66669 10.6667" strokeWidth="2" />
    </svg>
  )
}

export default ChevronRight
