import React from 'react'

function Remove({ classes = 'icon--remove' }) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--strokes icon--remove ' + classes}
    >
      <path d="M20.1121 4.11133L4.11208 20.1113" fill="none" strokeWidth="2" />
      <path d="M20.1121 20.1113L4.11208 4.11135" fill="none" strokeWidth="2" />
    </svg>
  )
}

export default Remove
