import { useQuery } from '@apollo/client'
import { Button, InfiniteLoader } from 'components/atoms'
import { Plus } from 'components/atoms/Icons'
import { IndirizzoCard } from 'components/molecules'
import { useEffect, useState } from 'react'
import { MyAccountIndirizzoAddForm, MyAccountIndirizzoEditForm } from 'components/organisms'
import { INDIRIZZI_FATTURAZIONE } from 'utils/queries'

function FatturazioneCheckout({ formik, classes }) {
  const [indirizzi, setIndirizzi] = useState([])
  const [selected, setSelected] = useState(null)
  const [add, setAdd] = useState(false)
  const [edit, setEdit] = useState(false)

  const { data: indirizziFatturazione, loading } = useQuery(INDIRIZZI_FATTURAZIONE)

  useEffect(() => {
    if (indirizziFatturazione?.indirizzi)
      setIndirizzi(indirizziFatturazione.indirizzi.edges.map(({ node }) => node))
  }, [indirizziFatturazione])

  function handleSelect(value) {
    formik.setFieldValue('indirizzoFatturazione', value)
  }

  return (
    <section className={'fatturazione-checkout ' + classes}>
      <p className="fatturazione-checkout__title">Indirizzo di fatturazione</p>
      {loading ? (
        <InfiniteLoader classes="icon--loader loading" />
      ) : indirizzi.length ? (
        <div className="fatturazione-checkout__list">
          {indirizzi?.map((indirizzo) =>
            edit && selected === indirizzo.pk ? (
              <MyAccountIndirizzoEditForm
                indirizzo={indirizzo}
                setEdit={setEdit}
                checkout
                key={indirizzo.pk}
              />
            ) : (
              !edit && (
                <IndirizzoCard
                  indirizzo={indirizzo}
                  selectable
                  fatturazione
                  onSelect={() => handleSelect(indirizzo.pk)}
                  editable
                  onEdit={() => {
                    setSelected(indirizzo.pk)
                    setEdit(true)
                  }}
                  checked={formik.values.indirizzoFatturazione === indirizzo.pk}
                  key={indirizzo.pk}
                />
              )
            )
          )}
        </div>
      ) : (
        <p className="fatturazione-checkout__empty">Non hai ancora aggiunto nessun indirizzo</p>
      )}
      {add ? (
        <MyAccountIndirizzoAddForm isFatturazione={true} checkout setEdit={setAdd} />
      ) : (
        !edit && (
          <Button
            label="Aggiungi indirizzo"
            size="md"
            type="secondary"
            icon={<Plus />}
            classes="fatturazione-checkout__add-button"
            onClick={() => setAdd(true)}
          />
        )
      )}
    </section>
  )
}

export default FatturazioneCheckout
