import React from 'react'
import { WImage } from 'components/atoms'

const ImageBox = ({ src, alt, title, classPrefix, maxWidth, maxHeight, lazyLoad }) => {
  return (
    <div className={'image-box' + (classPrefix ? ' ' + classPrefix + '__image-box' : '')}>
      <WImage
        src={src ? src : 'https://via.placeholder.com/' + maxWidth + 'x' + maxHeight}
        alt={alt}
        title={title}
        classPrefix={classPrefix}
        lazyLoad={lazyLoad}
      />
    </div>
  )
}

export default ImageBox
