import React from 'react'
import Link from 'next/link'
import { WImage } from 'components/atoms'
import { priceNotation } from 'utils/safe'

function ProdottoOrdine({ rigaOrdine }) {
  return rigaOrdine.prodotto ? (
    <article className="prodotto-ordine">
      <div className="prodotto-ordine__image-box">
        <Link legacyBehavior href={rigaOrdine.prodotto.url}>
          <a>
            <WImage
              src={rigaOrdine.prodotto.smallThumb}
              maxWidth={60}
              maxHeight={60}
              alt={rigaOrdine.prodotto.titolo.toAltTitle()}
              title={rigaOrdine.prodotto.titolo.toAltTitle()}
              classPrefix="prodotto-ordine"
            />
          </a>
        </Link>
      </div>
      <div className="prodotto-ordine__content">
        <div className="prodotto-ordine__cols">
          <div>
            <Link legacyBehavior href={rigaOrdine.prodotto.url}>
              <a className="prodotto-ordine__title">{rigaOrdine.prodotto.titolo}</a>
            </Link>
            <p className="prodotto-ordine__size">{rigaOrdine.prodotto.formato?.nome}</p>
            <p className="prodotto-ordine__quantita">Quantità: {rigaOrdine.quantitaOrdinata}</p>
          </div>
          <div>
            {rigaOrdine?.omaggio ? (
              <p className="prodotto-checkout__finalprice">Omaggio</p>
            ) : (
              <>
                {rigaOrdine.prezzoTotale != rigaOrdine.prezzoTotaleScontato && (
                  <p className="prodotto-ordine__fullprice">
                    {priceNotation(rigaOrdine.prezzoTotale)}
                  </p>
                )}
                <p className="prodotto-ordine__finalprice">
                  {priceNotation(rigaOrdine.prezzoTotaleScontato)}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </article>
  ) : (
    <></>
  )
}

export default ProdottoOrdine
