import React from 'react'
import { ArrowRight } from 'components/atoms/Icons'
import { Button } from 'components/atoms'
// import { useTrans } from 'utils/hooks'
import { useTrans } from 'utils/hooks'

const BoxContatti = () => {
  const t = useTrans()

  return (
    <div className="box-contatti">
      <div className="w-container">
        <p className="box-contatti__title">{t('box-contatti__titolo')}</p>
        <p className="box-contatti__description">{t('box-contatti__descrizione')}</p>
        <div className="box-contatti__cta">
          <Button
            href="/contatti"
            label={t('Contattaci')}
            icon={<ArrowRight />}
            iconPos="right"
            type="primary"
          />
        </div>
      </div>
    </div>
  )
}

export default BoxContatti
