import React from 'react'

function RadioButton({ name, type, label, disabled, value, onBlur, onChange, checked }) {
  return (
    <div className="radio-button">
      <label className="container">
        <input
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          className="input"
          type={type ? type : 'radio'}
          disabled={disabled}
          checked={checked}
        />
        <span className="checkmark" />
        {label}
      </label>
    </div>
  )
}

export default RadioButton
