import React from 'react'

function ChevronLeft(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--strokes icon--chevron-left ' + (props.classes ? props.classes : '')}
    >
      <path d="M16 20L8 12L16 4" fill="none" strokeWidth="2" />
    </svg>
  )
}

export default ChevronLeft
