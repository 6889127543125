import React from 'react'

function Profile(props) {
  return (
    <svg
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--strokes icon--profile ' + (props.classes ? props.classes : '')}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4385 21.1875C19.4385 16.2169 16.2063 12.1875 12.2193 12.1875C8.23217 12.1875 5 16.2169 5 21.1875C8.86288 21.1875 15.066 21.1875 19.4385 21.1875Z"
        fill="none"
        strokeWidth="2"
      />
      <path
        d="M12.3637 12.1875C14.8327 12.1875 16.8342 10.1728 16.8342 7.6875C16.8342 5.20222 14.8327 3.1875 12.3637 3.1875C9.89462 3.1875 7.89307 5.20222 7.89307 7.6875C7.89307 10.1728 9.89462 12.1875 12.3637 12.1875Z"
        fill="none"
        strokeWidth="2"
      />
    </svg>
  )
}

export default Profile
