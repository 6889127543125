import React from 'react'
import Link from 'next/link'
import { WImage } from 'components/atoms'
import { priceNotation } from 'utils/safe'

function ProdottoCheckout({ prodotto, quantita, omaggio = false }) {
  return (
    <article className="prodotto-checkout">
      <div className="prodotto-checkout__image-box">
        <Link legacyBehavior href={prodotto.url}>
          <a>
            <WImage
              src={prodotto.smallThumb}
              maxWidth={60}
              maxHeight={60}
              alt={`${prodotto.titolo} ${prodotto.sottotitolo || ''}`.toAltTitle()}
              title={`${prodotto.titolo} ${prodotto.sottotitolo || ''}`.toAltTitle()}
              classPrefix="prodotto-checkout"
            />
          </a>
        </Link>
      </div>
      <div className="prodotto-checkout__content">
        <div className="prodotto-checkout__cols">
          <div>
            <Link legacyBehavior href={prodotto.url}>
              <a className="prodotto-checkout__title">{prodotto.titolo}</a>
            </Link>
            <p className="prodotto-checkout__size">{prodotto.formato?.nome}</p>
            <p className="prodotto-checkout__quantita">Quantità: {quantita}</p>
          </div>
          <div>
            {omaggio ? (
              <p className="prodotto-checkout__finalprice">Omaggio</p>
            ) : (
              <>
                {prodotto.prezzo != prodotto.prezzoScontato && (
                  <p className="prodotto-checkout__fullprice">{priceNotation(prodotto.prezzo)}</p>
                )}
                <p className="prodotto-checkout__finalprice">
                  {priceNotation(prodotto.prezzoScontato)}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </article>
  )
}

export default ProdottoCheckout
