import React, { useState } from 'react'
import { ChevronDown } from 'components/atoms/Icons'
import { trackingGAevent } from 'utils/gtm'

const Accordion = ({
  placeholder,
  children,
  id,
  category,
  action,
  label,
  initialValue = false,
}) => {
  const [open, setOpen] = useState(initialValue)

  return (
    <div className={`accordion ${open ? 'accordion--open' : ''}`}>
      <div
        className={`accordion__container`}
        onClick={() => {
          if (!open && (id == '31' || id == '40')) trackingGAevent(id, category, action, label)

          setOpen(!open)
        }}
      >
        <p className={`accordion__placeholder`}>{placeholder ? placeholder : 'Accordion'}</p>
        <span className="accordion__arrow">
          <ChevronDown />
        </span>
      </div>
      <div className="accordion__dropdown">
        <div className="accordion__content">{children}</div>
      </div>
    </div>
  )
}

export default Accordion
