import React from 'react'
import { Profile, Email, List, Heart, PinEmpty, Exit } from 'components/atoms/Icons'
import { MyAccountMenuItem } from 'components/molecules'
import { useRouter } from 'next/router'
import { LOGOUT } from 'utils/queries'
import { useMutation } from '@apollo/client'
import { trackingGAevent } from 'utils/gtm'

const MyAccountMenu = ({ chiave }) => {
  const router = useRouter()

  const [logout] = useMutation(LOGOUT)

  async function handleLogout() {
    trackingGAevent(32, 'my_account', 'click', 'Logout')
    localStorage.removeItem('jwt')
    await logout()
    window.location.href = '/' // force SSR
  }

  return (
    <div className="myaccount-menu">
      <div className="myaccount-menu__top">
        <MyAccountMenuItem
          icon={<Profile />}
          label="Il mio profilo"
          link="/myaccount/profilo"
          active={chiave === 'myaccount-profilo'}
        />
        <MyAccountMenuItem
          icon={<Email />}
          label="I miei indirizzi"
          link="/myaccount/indirizzi"
          active={chiave === 'myaccount-indirizzi'}
        />
        <MyAccountMenuItem
          icon={<List />}
          label="I miei ordini"
          link="/myaccount/ordini"
          active={chiave === 'myaccount-ordini' || chiave === 'myaccount-ordini-detail'}
        />
        <MyAccountMenuItem
          icon={<Heart />}
          label="La mia wishlist"
          link="/myaccount/wishlist"
          active={chiave === 'myaccount-wishlist'}
        />
        <MyAccountMenuItem
          icon={<PinEmpty />}
          label="Il mio centro estetico"
          link="/myaccount/centroestetico"
          active={chiave === 'myaccount-centroestetico'}
        />
      </div>
      <div className="myaccount-menu__bottom">
        <MyAccountMenuItem icon={<Exit />} label="Logout" onClick={handleLogout} />
      </div>
    </div>
  )
}

export default MyAccountMenu
