import { Remove } from 'components/atoms/Icons'
import React from 'react'

const Chip = ({ label, removable, onClick }) => {
  return (
    <div className={'chip' + (removable || onClick ? ' chip--clickable' : '')} onClick={onClick}>
      <div className="chip__label">{label}</div>
      {removable && (
        <div className="chip__icon">
          <Remove />
        </div>
      )}
    </div>
  )
}

export default Chip
