import React from 'react'
import { Form, Formik } from 'formik'
import { MyAccountForm, SelezionaCentro } from '..'
import { useMutation } from '@apollo/client'
import { UPDATE_UTENTE } from 'utils/queries'

const FormCentroEstetico = ({ setEdit }) => {
  const initialValues = {
    centro: '',
  }

  const [updateUtente, { data }] = useMutation(UPDATE_UTENTE)

  async function handleSubmit(values, setSubmitting) {
    setSubmitting(true)

    const response = await updateUtente({
      variables: {
        input: {
          centroEstetico: values.centro,
        },
      },
      refetchQueries: ['CentroEstetico'],
    })

    setSubmitting(false)
    setEdit(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting)
      }}
    >
      {(props) => (
        <Form>
          <MyAccountForm
            add
            centro
            onCancel={() => setEdit(false)}
            submitting={props.isSubmitting}
            classes={'form-centro-estetico'}
          >
            <SelezionaCentro formik={props} />
          </MyAccountForm>
        </Form>
      )}
    </Formik>
  )
}

export default FormCentroEstetico
