import { Button, Divider, NotificationBar } from 'components/atoms'
import { Close } from 'components/atoms/Icons'
import { ProdottoCarrello } from 'components/molecules'
import React, { useContext } from 'react'
import { FREE_SHIPPING } from 'utils'
import { CartContext, initialCart } from 'utils/context'
import { trackingGAevent } from 'utils/gtm'
import { priceNotation } from 'utils/safe'

function PreCarrello() {
  const context = useContext(CartContext)
  const cart = context ? context.cart : initialCart
  const showCart = context ? context.showCart : false
  const closeCart = context ? context.closeCart : () => {}

  let leftForFreeShipping = 0
  if (cart.totaleProdottiScontato < FREE_SHIPPING)
    leftForFreeShipping = FREE_SHIPPING - cart.totaleProdottiScontato

  return (
    <aside className={`pre-carrello ${showCart ? 'pre-carrello--open' : ''}`}>
      <header>
        <Close onClick={closeCart} classes="pre-carrello__close" />
        <p className="pre-carrello__title">Il tuo carrello</p>
      </header>
      {leftForFreeShipping ? (
        <NotificationBar
          type="error"
          classes="pre-carrello__alert notification-bar--grey"
          placeholder={`Ti mancano ancora € ${leftForFreeShipping.toFixed(
            2
          )} per avere diritto alla spedizione standard gratuita!`}
        />
      ) : null}
      <span className="pre-carrello__num-prodotti">
        {cart.numProdotti} {cart.numProdotti === 1 ? 'prodotto' : 'prodotti'}
      </span>
      <main className="pre-carrello__prodotti">
        {cart.items.map((item) => (
          <ProdottoCarrello
            prodotto={item.prodotto}
            quantita={item.quantita}
            omaggio={item.omaggio}
            key={item.pk}
          />
        ))}
      </main>
      <footer className="pre-carrello__footer">
        <Divider />
        <section className="pre-carrello__total">
          <span className="pre-carrello__total__label">Totale</span>
          <span className="pre-carrello__total__price">
            {priceNotation(cart.totaleProdottiScontato)}
          </span>
        </section>
        <section className="pre-carrello__link">
          <Button
            type="ghost"
            href="/"
            label="Continua gli acquisti"
            onClick={() =>
              trackingGAevent(15, 'cart_popup', 'add_to_cart', 'continua_gli_acquisti')
            }
          />
          <Button
            href="/cart/checkout"
            label="Vai al checkout"
            onClick={() => trackingGAevent(15, 'cart_popup', 'add_to_cart', 'vai_al_checkout')}
          />
        </section>
      </footer>
    </aside>
  )
}

export default PreCarrello
