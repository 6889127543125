import React from 'react'
import { Info, RadioButtonEmpty, RadioButtonFull } from 'components/atoms/Icons'

const FormRadioOption = ({ label, name, value, checked, status, className, onClick, disabled }) => {
  return (
    <label
      className={
        `form-radio-option` +
        (status ? ` form-radio-option--${status}` : '') +
        (className ? ' ' + className : '')
      }
    >
      <input
        checked={checked}
        name={name}
        value={value}
        className="form-radio-option__radio"
        type="radio"
        disabled={disabled}
        onClick={onClick}
        readOnly={true}
      />
      <span className="form-radio-option__styledradio">
        {checked ? <RadioButtonFull /> : <RadioButtonEmpty />}
      </span>
      <span className="form-radio-option__label">{label}</span>
    </label>
  )
}

const FormRadio = ({
  name,
  size,
  description,
  value,
  className = '',
  disabled,
  status,
  onChange,
  errorMessage,
  options,
}) => {
  const onOptionClick = (e) => {
    // console.log(e)
    if (!e.target.disabled) onChange(e)
  }
  return (
    <div className="form-field__input-wrap">
      <div
        className={
          'form-radio' + (size ? ' form-radio--' + size : '') + (className ? ' ' + className : '')
        }
      >
        {description ? <div className="form-radio__description">{description}</div> : <></>}
        <div className="form-radio__options">
          {options?.map((option, i) => (
            <FormRadioOption
              name={name}
              label={option.label}
              value={option.value}
              checked={option.value == value}
              onClick={onOptionClick}
              disabled={disabled || option.disabled}
              key={'formradiooption_' + i}
            />
          ))}
        </div>
      </div>
      {status === 'error' && errorMessage ? (
        <p className="form-field__input-error">
          <Info classes="form-field__input-error-icon" />
          {errorMessage}
        </p>
      ) : (
        <></>
      )}
    </div>
  )
}

export default FormRadio
