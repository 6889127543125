import * as yup from 'yup'

export const registrazione = yup.object().shape({
  nome: yup.string().min(3, 'Troppo corto').required('Campo obbligatorio'),
  cognome: yup.string().min(3, 'Troppo corto').required('Campo obbligatorio'),
  email: yup.string().email('Email non valida').required('Campo obbligatorio'),
  email2: yup
    .string()
    .email('Email non valida')
    .required('Campo obbligatorio')
    .oneOf([yup.ref('email'), null], 'Le email non corrispondono'),
  password: yup
    .string()
    .required('Campo obbligatorio')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.]{8,}$/,
      'Deve contenere 8 caratteri con lettere, numeri, maiuscole, minuscole e caratteri speciali'
    ),
  password2: yup
    .string()
    .required('Campo obbligatorio')
    .oneOf([yup.ref('password'), null], 'Le password non corrispondono'),
  privacy: yup.bool().oneOf([true]),
  commerciale: yup.bool().oneOf([true, false]),
  centro: yup.string().required(),
  telefono: yup
    .string()
    .required('Campo obbligatorio')
    .matches(/^\d+$/gi, 'Non è stato inserito un numero di telefono valido')
    .min(6, 'Numero troppo corto')
    .test(
      'validate',
      'Non è stato inserito un numero di telefono valido',
      (val) => Number.isInteger(parseFloat(val)) && parseFloat(val) >= 0
    )
    .nullable(),
})

export const registrazioneComplete = yup.object().shape({
  privacy: yup.bool().oneOf([true]),
  centro: yup.string().required(),
})

export const datiPersonali = yup.object().shape({
  nome: yup.string().min(3, 'Troppo corto').required('Campo obbligatorio'),
  cognome: yup.string().min(3, 'Troppo corto').required('Campo obbligatorio'),
  telefono: yup
    .string()
    .required('Campo obbligatorio')
    .matches(/^\d+$/gi, 'Non è stato inserito un numero di telefono valido')
    .min(6, 'Numero troppo corto')
    .test(
      'validate',
      'Non è stato inserito un numero di telefono valido',
      (val) => Number.isInteger(parseFloat(val)) && parseFloat(val) >= 0
    )
    .nullable(),
})

export const cambiaPassword = yup.object().shape({
  oldPassword: yup.string().required('Campo obbligatorio'),
  newPassword: yup
    .string()
    .required('Campo obbligatorio')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.]{8,}$/,
      'Deve contenere 8 caratteri con lettere, numeri, maiuscole, minuscole e caratteri speciali'
    ),
  confirmPassword: yup
    .string()
    .required('Campo obbligatorio')
    .oneOf([yup.ref('newPassword'), null], 'Le password non corrispondono'),
})

export const editIndirizzoSpedizione = yup.object().shape({
  nome: yup.string().min(3, 'Troppo corto').required('Campo obbligatorio'),
  cognome: yup.string().min(3, 'Troppo corto').required('Campo obbligatorio'),
  indirizzo: yup.string().required('Campo obbligatorio'),
  citta: yup.string().required('Campo obbligatorio'),
  cap: yup.string().required('Campo obbligatorio'),
  provincia: yup.string().required('Campo obbligatorio'),
  telefono: yup
    .string()
    .required('Campo obbligatorio')
    .matches(/^\d+$/gi, 'Non è stato inserito un numero di telefono valido')
    .min(6, 'Numero troppo corto')
    .test(
      'validate',
      'Non è stato inserito un numero di telefono valido',
      (val) => Number.isInteger(parseFloat(val)) && parseFloat(val) >= 0
    )
    .nullable(),
})

export const editIndirizzoFatturazione = yup.object().shape({
  nome: yup.string().min(3, 'Troppo corto').required('Campo obbligatorio'),
  cognome: yup.string().min(3, 'Troppo corto').required('Campo obbligatorio'),
  indirizzo: yup.string().required('Campo obbligatorio'),
  citta: yup.string().required('Campo obbligatorio'),
  cap: yup.string().required('Campo obbligatorio'),
  provincia: yup.string().required('Campo obbligatorio'),
  cf: yup
    .string()
    .min(16, 'Numero troppo corto')
    .max(16, 'Numero troppo lungo')
    .nullable()
    .required('Campo obbligatorio')
    .matches(
      /^[A-Za-z]{6}[A-Za-z0-9]{2}[abcdehlmprstABCDEHLMPRST][A-Za-z0-9]{2}[A-Za-z][A-Za-z0-9]{3}[A-Za-z]$/gi,
      'Non è stato inserito un codice fiscale valido'
    ),
  telefono: yup
    .string()
    .required('Campo obbligatorio')
    .matches(/^\d+$/gi, 'Non è stato inserito un numero di telefono valido')
    .min(6, 'Numero troppo corto')
    .test(
      'validate',
      'Non è stato inserito un numero di telefono valido',
      (val) => Number.isInteger(parseFloat(val)) && parseFloat(val) >= 0
    )
    .nullable(),
})

export const richiestaContatto = yup.object().shape({
  nome: yup.string().min(3, 'Troppo corto').required('Campo obbligatorio'),
  cognome: yup.string().min(3, 'Troppo corto').required('Campo obbligatorio'),
  email: yup.string().email('Email non valida').required('Campo obbligatorio'),
  richiesta: yup.string().required('Campo obbligatorio'),
  messaggio: yup.string().required('Campo obbligatorio'),
  privacy: yup.bool().oneOf([true]),
  privacyCommerciale: yup.bool().oneOf([true, false]),
})

export const richiestaCentro = yup.object().shape({
  nome: yup.string().min(3, 'Troppo corto').required('Campo obbligatorio'),
  cognome: yup.string().min(3, 'Troppo corto').required('Campo obbligatorio'),
  email: yup.string().email('Email non valida').required('Campo obbligatorio'),
  telefono: yup.number().min(0, 'Telefono non valido').required('Campo obbligatorio'),
  centroEstetico: yup.bool().oneOf([true, false]),
  province: yup.string().required('Campo obbligatorio'),
  messaggio: yup.string(),
  privacy: yup.bool().oneOf([true]),
  privacyCommerciale: yup.bool().oneOf([true, false]),
})

export const newsletterBox = (t) =>
  yup.object().shape({
    email: yup.string().email(t('Email non valida')).required(t('Campo obbligatorio')),
    privacy: yup.bool().oneOf([true], t('Campo obbligatorio')),
  })

export const resetPassword = yup.object().shape({
  password: yup
    .string()
    .required('Campo obbligatorio')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.]{8,}$/,
      'Deve contenere 8 caratteri con lettere, numeri, maiuscole, minuscole e caratteri speciali'
    ),
  password2: yup
    .string()
    .required('Campo obbligatorio')
    .oneOf([yup.ref('password'), null], 'Le password non corrispondono'),
})

export const recuperaPassword = yup.object().shape({
  email: yup.string().email('Email non valida').required('Campo obbligatorio'),
})

export const selezionaCentroCheckout = yup.object().shape({
  centro: yup.string().required(),
})
