import React from 'react'

function Eye(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--strokes icon--eye ' + (props.classes ? props.classes : '')}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18.3333C15.6819 18.3333 19.0152 16.1111 22 11.6667C19.0152 7.22222 15.6819 5 12 5C8.3181 5 4.98477 7.22222 2 11.6667C4.98477 16.1111 8.3181 18.3333 12 18.3333Z"
        fill="none"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M11.9999 15.304C14.0082 15.304 15.6363 13.6759 15.6363 11.6676C15.6363 9.65931 14.0082 8.03125 11.9999 8.03125C9.99158 8.03125 8.36353 9.65931 8.36353 11.6676C8.36353 13.6759 9.99158 15.304 11.9999 15.304Z"
        fill="none"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  )
}

export default Eye
