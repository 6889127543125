import { useMutation } from '@apollo/client'
import { Accordion, Button, Divider, FormInput, NotificationBar } from 'components/atoms'
import { ChevronDown } from 'components/atoms/Icons'
import { ProdottoCheckout } from 'components/molecules'
import React, { useEffect, useState } from 'react'
import { SET_PROMO_CODE, REMOVE_PROMO_CODE } from 'utils/queries'
import { useTrans } from 'utils/hooks'
import { trackingGAevent, trackingPurchase } from 'utils/gtm'
import { priceNotation } from 'utils/safe'

function IlTuoOrdine({ checkout, typ = false, classes = '', ordine = null }) {
  const [promoCode, editPromoCode] = useState(checkout.promoApplicata?.codice || '')
  const [show, setShow] = useState(false)
  const [error, setError] = useState('')

  const [setPromoCode, { data: setPayload }] = useMutation(SET_PROMO_CODE)
  const [removePromoCode, { data: removePayload }] = useMutation(REMOVE_PROMO_CODE)
  const t = useTrans()

  function handleChangePromoCode({ target: { value } }) {
    editPromoCode(value)
  }

  async function handleSetPromoCode() {
    await setPromoCode({
      variables: {
        promoCode,
      },
      refetchQueries: ['Checkout'],
    })
  }

  async function handleRemovePromoCode() {
    trackingGAevent(28, 'coupon', 'remove', promoCode)
    await removePromoCode({
      variables: {
        promoCode,
      },
      refetchQueries: ['Checkout'],
    })
  }

  useEffect(() => {
    if (setPayload?.setPromoCode) {
      if (setPayload?.setPromoCode.status) {
        setError('')
        trackingGAevent(18, 'coupon', 'utilizzo', promoCode)
      } else setError('Il codice inserito non è valido')
    }
  }, [setPayload?.setPromoCode])

  useEffect(() => {
    if (typ && ordine && checkout) trackingPurchase(checkout, ordine.pk)
  }, [checkout])

  return (
    <section className={`il-tuo-ordine ${classes} ${show ? 'il-tuo-ordine--open' : ''}`}>
      <p className="il-tuo-ordine__title">{t('Il tuo ordine')}</p>
      {t('notifica-checkout__testo') && (
        <NotificationBar
          type="error"
          classes="il-tuo-ordine__alert notification-bar--grey"
          placeholder={t('notifica-checkout__testo')}
        />
      )}
      <div className="il-tuo-ordine__trigger" onClick={() => setShow(!show)}>
        <ChevronDown />
        {show ? 'Nascondi riepilogo dell’ordine' : 'Mostra riepilogo dell’ordine'}
        <span className="il-tuo-ordine__trigger__total">
          {priceNotation(checkout.totaleScontatoNetto)}
        </span>
      </div>
      <main className="il-tuo-ordine__main">
        <Divider classes="il-tuo-ordine__mobile-divider" />
        {!typ && (
          <>
            <div className="il-tuo-ordine__promo-accordion">
              <Accordion
                placeholder="Hai un codice sconto?"
                id="40"
                category="acccordion_checkout"
                action="click"
                label="codice_sconto"
              >
                <div className="il-tuo-ordine__promo-code">
                  <FormInput
                    placeholder={t('Codice sconto')}
                    variant={'standard'}
                    name="promo_code"
                    value={promoCode}
                    onChange={handleChangePromoCode}
                    status={error ? 'error' : ''}
                    errorMessage={error}
                  />
                  {checkout.promoApplicata?.conCodice &&
                  checkout.promoApplicata?.codice?.toLowerCase() === promoCode.toLowerCase() ? (
                    <Button
                      label="Rimuovi"
                      size="sm"
                      type="ghost"
                      onClick={handleRemovePromoCode}
                    />
                  ) : (
                    <Button
                      label="Applica"
                      size="sm"
                      onClick={handleSetPromoCode}
                      disabled={!promoCode}
                    />
                  )}
                </div>
              </Accordion>
            </div>
            <Divider />
            <div className="il-tuo-ordine__subtotale">
              <span className="il-tuo-ordine__subtotale__label">Subtotale</span>
              <span className="il-tuo-ordine__subtotale__price">
                {priceNotation(checkout.totaleProdottiScontato)}
              </span>
            </div>
            {checkout.promoApplicata ? (
              <div className="il-tuo-ordine__promozione">
                <span className="il-tuo-ordine__subtotale__label">
                  {checkout.promoApplicata.alias}
                </span>
                <span className="il-tuo-ordine__subtotale__price">
                  - {priceNotation(checkout.scontoAssoluto)}
                </span>
              </div>
            ) : null}
            <div className="il-tuo-ordine__spedizione">
              <span className="il-tuo-ordine__spedizione__label">Spedizione</span>
              <span className="il-tuo-ordine__spedizione__price">
                {checkout.sistemaSpedizione.prezzoScontato === 0
                  ? 'Gratuita'
                  : priceNotation(checkout.sistemaSpedizione.prezzoScontato)}
              </span>
            </div>
            <Divider />
            <div className="il-tuo-ordine__totale">
              <span className="il-tuo-ordine__totale__label">Totale</span>
              <span className="il-tuo-ordine__totale__price">
                {priceNotation(checkout.totaleScontatoNetto)}
              </span>
            </div>
            <Divider />
          </>
        )}
        <Accordion
          placeholder="Riepilogo prodotti"
          initialValue={typ}
          id="40"
          category="acccordion_checkout"
          action="click"
          label="prodotti"
        >
          <div className="il-tuo-ordine__prodotti">
            {checkout.items.map((item) => (
              <ProdottoCheckout
                prodotto={item.prodotto}
                quantita={item.quantita}
                omaggio={item.omaggio}
                key={item.prodotto.pk}
              />
            ))}
          </div>
        </Accordion>
        {typ && (
          <>
            <div className="il-tuo-ordine__subtotale">
              <span className="il-tuo-ordine__subtotale__label">Subtotale</span>
              <span className="il-tuo-ordine__subtotale__price">
                {priceNotation(checkout.totaleProdottiScontato)}
              </span>
            </div>
            {checkout.promoApplicata ? (
              <div className="il-tuo-ordine__promozione">
                <span className="il-tuo-ordine__subtotale__label">
                  {checkout.promoApplicata.alias}
                </span>
                <span className="il-tuo-ordine__subtotale__price">
                  - {priceNotation(checkout.scontoAssoluto)}
                </span>
              </div>
            ) : null}
            <div className="il-tuo-ordine__spedizione">
              <span className="il-tuo-ordine__spedizione__label">Spedizione</span>
              <span className="il-tuo-ordine__spedizione__price">
                {checkout.sistemaSpedizione.prezzoScontato === 0
                  ? 'Gratuita'
                  : priceNotation(checkout.sistemaSpedizione.prezzoScontato)}
              </span>
            </div>
            <Divider />
            <div className="il-tuo-ordine__totale">
              <span className="il-tuo-ordine__totale__label">Totale</span>
              <span className="il-tuo-ordine__totale__price">
                {priceNotation(checkout.totaleScontatoNetto)}
              </span>
            </div>
          </>
        )}
      </main>
    </section>
  )
}

export default IlTuoOrdine
