import React from 'react'
import { WContainer, MetaSeo } from 'components/atoms'
import { Breadcrumbs } from 'components/molecules'

const PageTitle = ({ title }) => {
  return title ? <h1 className="page__title">{title}</h1> : <></>
}

const PageDescription = ({ description }) => {
  return description ? (
    <div className="page__description" dangerouslySetInnerHTML={{ __html: description }} />
  ) : (
    <></>
  )
}

const Page = ({ pagina, classes = '', children, noContainer }) => {
  return (
    <main className={`page ${classes}`}>
      <MetaSeo seo={pagina.seo} />
      <WContainer>
        <div className="page__head">
          {pagina?.breadcrumbs && <Breadcrumbs breadcrumbs={pagina.breadcrumbs} />}
          <PageTitle title={pagina?.titolo} />
          <PageDescription description={pagina?.descrizione} />
        </div>
        {!noContainer && children}
      </WContainer>
      {noContainer && children}
    </main>
  )
}

Page.Title = PageTitle
Page.Description = PageDescription

export default Page
