import React from 'react'

function Bin(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--strokes icon--bin ' + (props.classes ? props.classes : '')}
    >
      <rect x="6" y="7" width="12" height="14" fill="none" strokeWidth="2" />
      <rect x="9" y="4" width="6" height="3" fill="none" strokeWidth="2" />
      <line x1="3" y1="7" x2="21" y2="7" fill="none" strokeWidth="2" />
    </svg>
  )
}

export default Bin
