import Toolbar from 'components/molecules/Toolbar/Toolbar'
import { Footer, Header } from 'components/molecules'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useIsClient, useUtente } from 'utils/hooks'
import { InfiniteLoader, PreHeader } from 'components/atoms'
import { PreCarrello } from 'components/organisms'

export default function Layout({
  loginRequired,
  centroRequired,
  withHeader,
  withPreHeader,
  withFooter,
  children,
  item = null,
  page = null,
}) {
  const router = useRouter()

  const [utente, loading] = useUtente()
  const isClientSide = useIsClient(false)

  useEffect(() => {
    const checkUtente = setTimeout(() => {
      if (!loading) {
        // pagina permessa a utente autenticato -> login
        if (loginRequired && !loading && !utente) router.replace(`/login?next=${router.asPath}`)

        // utente social che non ha completato la registrazione
        if (utente && !utente.completed && !router.asPath.includes('/registrazione/complete')) {
          router.push('/registrazione/complete')
        }

        // se l'utente ha un centro estetico non valido viene rimandato alla pagina per selezionarne uno attivo (da pagina di checkout)
        if (utente && !utente.hasCentro && centroRequired) {
          router.push('/seleziona-centro')
        }
      }
    }, 300)

    return () => clearTimeout(checkUtente)
  }, [utente, loading])

  return (
    <>
      {utente && utente.isStaff && <Toolbar item={item} page={page} />}
      {withPreHeader && <PreHeader />}
      {withHeader && <Header isHome={router.asPath === '/'} />}
      {loginRequired ? (
        loading ? (
          <InfiniteLoader classes="icon--loader loading" />
        ) : utente ? (
          children
        ) : (
          <InfiniteLoader classes="icon--loader loading" />
        )
      ) : (
        children
      )}
      {isClientSide && <PreCarrello />}
      {withFooter && <Footer />}
      <style jsx global>{`
        @font-face {
          font-family: 'Prompt';
          src: url('/fonts/Prompt-Regular.ttf');
          font-weight: 400;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Prompt';
          src: url('/fonts/Prompt-Medium.ttf');
          font-weight: 500;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Prompt';
          src: url('/fonts/Prompt-Bold.ttf');
          font-weight: 700;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Playfair Display';
          src: url('/fonts/Playfair.ttf');
          font-weight: 700;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Nunito Sans';
          src: url('/fonts/NunitoSans-Regular.ttf');
          font-weight: 400;
          font-style: normal;
          font-display: swap;
        }
        @font-face {
          font-family: 'Nunito Sans';
          src: url('/fonts/NunitoSans-Bold.ttf');
          font-weight: 700;
          font-style: normal;
          font-display: swap;
        }
      `}</style>
    </>
  )
}
