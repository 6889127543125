import React from 'react'

function Minus({ classes }) {
  return (
    <svg
      className={'icon icon--strokes ' + classes}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.8696 9.08398H2.2982" strokeWidth="2" />
    </svg>
  )
}

export default Minus
