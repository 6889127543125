import React from 'react'
import { RadioButton } from 'components/atoms'
import { Edit } from 'components/atoms/Icons'
import { trackingGAevent } from 'utils/gtm'

function IndirizzoCard({
  indirizzo,
  selectable,
  onSelect,
  editable,
  onEdit,
  fatturazione = false,
  checked = false,
  checkout = false,
}) {
  return (
    <div className="indirizzo-card">
      {selectable && (
        <RadioButton name="indirizzo" value={indirizzo.pk} onChange={onSelect} checked={checked} />
      )}
      <div className="indirizzo-card__content">
        <p className="indirizzo-card__title">
          {indirizzo.nome} {indirizzo.cognome}
        </p>
        <p className="indirizzo-card__address">
          {indirizzo.indirizzo}, {indirizzo.numero_civico} {indirizzo.cap} {indirizzo.citta} (
          {indirizzo.provincia}) - {indirizzo.nazione || 'Italia'}
          <br />
          {fatturazione ? indirizzo.cf : indirizzo.informazioniAggiuntive}
        </p>
      </div>
      {editable && (
        <a
          onClick={() => {
            fatturazione
              ? null
              : trackingGAevent(
                  33,
                  'shipping_address',
                  'modifica',
                  checkout ? 'checkout' : 'my_account'
                )
          }}
        >
          <button className="indirizzo-card__edit-button" onClick={onEdit}>
            <Edit />
          </button>
        </a>
      )}
    </div>
  )
}

export default IndirizzoCard
