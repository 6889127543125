import React, { useState } from 'react'
import { MyAccountMenu } from 'components/organisms'
import { useSelector } from 'react-redux'
import { MetaSeo } from 'components/atoms'
import { ChevronLeft, Close } from 'components/atoms/Icons'

const MyAccountLayout = ({ children, pagina }) => {
  const { utente } = useSelector((state) => state.utente)
  const [sidebarMobileOpen, setSidebarMobileOpen] = useState(false)

  return (
    <div className="page myaccount-layout">
      <MetaSeo seo={pagina.seo} />
      <aside
        className={
          'myaccount-layout__sidebar' +
          (sidebarMobileOpen ? ' myaccount-layout__sidebar--open' : '')
        }
      >
        <div className="myaccount-sidebar">
          <button className="myaccount-sidebar__close" onClick={() => setSidebarMobileOpen(false)}>
            <Close />
          </button>
          <p className="myaccount-sidebar__title">
            Ciao, {utente.nome} {utente.cognome}
          </p>
          <MyAccountMenu chiave={pagina.chiave} />
        </div>
      </aside>
      <main className="myaccount-layout__main">
        <div className="myaccount-layout__back">
          <span className="myaccount-layout__back__icon" onClick={() => setSidebarMobileOpen(true)}>
            <ChevronLeft />
          </span>
          <span className="myaccount-layout__back__label">{pagina.titolo}</span>
        </div>
        {children}
      </main>
    </div>
  )
}

export default MyAccountLayout
