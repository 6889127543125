import React from 'react'
import { Close, Pin, Phone, Directions, ChevronDown } from 'components/atoms/Icons'
import { Button } from 'components/atoms'
import { trackingGAevent } from 'utils/gtm'

const CentroEsteticoCard = ({
  centroEstetico,
  variant,
  onClose,
  iconUp = false,
  myAccount = false,
}) => {
  const getMapsUrl = () => {
    if (
      /* if we're on iOS, open in Apple Maps */
      navigator.platform.indexOf('iPhone') != -1 ||
      navigator.platform.indexOf('iPad') != -1 ||
      navigator.platform.indexOf('iPod') != -1
    )
      return `maps://maps.google.com/maps?daddr=${centroEstetico?.latitudine},${centroEstetico?.longitudine}&amp;ll=`
    else {
      /* else use Google */
      return `https://maps.google.com/maps?daddr=${centroEstetico?.latitudine},${centroEstetico?.longitudine}&amp;ll=`
    }
  }

  const waPosition = variant === 'map' ? 'map' : 'list'

  return (
    <div className={'centro-estetico-card' + (variant ? ' centro-estetico-card--' + variant : '')}>
      {iconUp ? (
        <div className="centro-estetico-card__chevron">
          <ChevronDown />
        </div>
      ) : null}
      {variant == 'map' ? (
        <button
          className="centro-estetico-card__close"
          onClick={onClose ? (e) => onClose(e) : null}
        >
          <Close />
        </button>
      ) : (
        <span className={'centro-estetico-card__icon ' + (iconUp ? 'iconup' : '')}>
          <Pin />
        </span>
      )}
      <p className="centro-estetico-card__title">
        {centroEstetico.alias ? centroEstetico.alias : centroEstetico.ragioneSociale}
      </p>
      <p className="centro-estetico-card__address">
        {centroEstetico.indirizzo} <br />
        {centroEstetico.cap} {centroEstetico.citta} ({centroEstetico.provincia}) - Italia
      </p>
      {centroEstetico?.orari ? (
        <div className="centro-estetico-card__opening-hours">
          <p className="centro-estetico-card__opening-hours-title">Orari di apertura</p>
          <p className="centro-estetico-card__opening-hours-value">{centroEstetico.orari}</p>
        </div>
      ) : null}
      {centroEstetico?.telefono ? (
        <a
          href={'tel:' + centroEstetico.telefono}
          className="centro-estetico-card__phone"
          onClick={() => {
            trackingGAevent(4, 'store_locator', `call_${waPosition}`, centroEstetico.ragioneSociale)
            myAccount ? trackingGAevent(36, 'my_account', `negozi_preferiti`, 'chiama') : null
          }}
        >
          <span className="centro-estetico-card__phone-icon">
            <Phone />
          </span>
          <span className="centro-estetico-card__phone-value">{centroEstetico.telefono}</span>
        </a>
      ) : null}
      <div className="centro-estetico-card__buttons">
        {centroEstetico?.telefono ? (
          <Button
            href={'tel:' + centroEstetico.telefono}
            type="secondary"
            size="sm"
            icon={<Phone />}
            label="Chiama"
            onClick={() =>
              trackingGAevent(
                4,
                'store_locator',
                `call_${waPosition}`,
                centroEstetico.ragioneSociale
              )
            }
          />
        ) : null}
        <Button
          href={getMapsUrl()}
          type="secondary"
          size="sm"
          icon={<Directions />}
          label="Direzioni"
          onClick={() =>
            trackingGAevent(
              4,
              'store_locator',
              `indicazioni_${waPosition}`,
              centroEstetico.ragioneSociale
            )
          }
        />
      </div>
    </div>
  )
}

export default CentroEsteticoCard
