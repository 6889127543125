import { Button, ImageBox, WContainer } from 'components/atoms'
import { Minus, Plus } from 'components/atoms/Icons'
import { useState } from 'react'
import { trackingGAevent } from 'utils/gtm'

function PrincipiAttivi({ attivi = [], complementari = [], prodotto }) {
  const [collapsed, setCollapsed] = useState(true)

  function handleCollapsed() {
    if (collapsed) {
      trackingGAevent(43, 'product', 'click', 'attivi_complementari', null, prodotto?.codice)
    }
    setCollapsed(!collapsed)
  }

  return (
    <section className={`principi-attivi ${collapsed ? '' : 'expanded'}`}>
      <WContainer>
        <h3 className="principi-attivi__title">Principi attivi</h3>
        <main className="principi-attivi__list">
          {attivi.map((principio, index) => (
            <article className="principi-attivi__list__item" key={index}>
              <ImageBox
                src={principio.principiAttiviImageThumb}
                maxWidth={100}
                maxHeight={100}
                alt={principio.nome.toAltTitle()}
                title={principio.nome.toAltTitle()}
                classPrefix="principi-attivi__list__item"
                lazyLoad
              />
              <p className="principi-attivi__list__item__title">{principio.nome}</p>
              <div
                className="principi-attivi__list__item__description"
                dangerouslySetInnerHTML={{ __html: principio.descrizione }}
              />
            </article>
          ))}
        </main>
        {complementari && complementari.length > 0 ? (
          <>
            <Button
              type="ghost"
              icon={collapsed ? <Plus /> : <Minus />}
              label="Attivi complementari"
              classes="principi-attivi__cta"
              onClick={handleCollapsed}
            />
            <main className="principi-attivi__list principi-attivi__list--complementari">
              {complementari.map((principio, index) => (
                <article className="principi-attivi__list__item" key={index}>
                  <p className="principi-attivi__list__item__title">{principio.nome}</p>
                  <div
                    className="principi-attivi__list__item__description"
                    dangerouslySetInnerHTML={{ __html: principio.descrizione }}
                  />
                </article>
              ))}
            </main>
          </>
        ) : (
          <></>
        )}
      </WContainer>
    </section>
  )
}

export default PrincipiAttivi
