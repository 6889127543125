function BoxQuote({ box }) {
  return (
    <blockquote
      className={
        'box-quote ' +
        (box.noQuotes ? ' box-quote--no-quotes' : '') +
        (box.size ? ' box-quote--' + box.size : '')
      }
      style={box.colore ? { background: `#${box.colore}` } : {}}
    >
      <div className="box-quote__content">
        <p className="box-quote__title">{box.titolo}</p>
      </div>
    </blockquote>
  )
}

export default BoxQuote
