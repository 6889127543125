import { RadioButton } from 'components/atoms/index'

function CentroEsteticoListItem({ centro, onChange }) {
  const label = (
    <div className="centro-list-item__side">
      <p className="centro-list-item__title">
        {centro.alias ? centro.alias : centro.ragioneSociale}
      </p>
      <p className="centro-list-item__address">
        {centro.indirizzo}, {centro.cap} {centro.citta} ({centro.provincia})
      </p>
    </div>
  )

  return (
    <div className="centro-list-item">
      <RadioButton name="centro" value={centro.pk} label={label} onChange={onChange} />
    </div>
  )
}

export default CentroEsteticoListItem
