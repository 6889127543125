import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import {
  FormLayout,
  FormField,
  FormInput,
  FormRadio,
  FormCheckbox,
  FormSelect,
  Button,
  FormTextarea,
  Modal,
} from 'components/atoms'
import { CREATE_RICHIESTA_CENTRO, LISTA_PROVINCE } from 'utils/queries'
import { richiestaCentro } from 'utils/validation'
import { useTrans } from 'utils/hooks'
import { Check } from 'components/atoms/Icons'
import { trackingGAevent } from 'utils/gtm'
import { province } from '../../../utils/province'

const FormDiventaCentroEstetico = ({}) => {
  const [province, setProvince] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [statusRichiesta, setStatusRichiesta] = useState({})

  const t = useTrans()
  const { data: ProvinceData } = useQuery(LISTA_PROVINCE, {
    fetchPolicy: 'cache-first',
  })
  const [createRichiestaCentro] = useMutation(CREATE_RICHIESTA_CENTRO)

  const initialValues = {
    nome: '',
    cognome: '',
    email: '',
    telefono: '',
    centroEstetico: null,
    provincia: '',
    messaggio: '',
    privacy: false,
    privacyCommerciale: null,
  }

  async function handleCreateRichiesta(values) {
    const response = await createRichiestaCentro({
      variables: {
        input: {
          nome: values.nome,
          cognome: values.cognome,
          email: values.email,
          telefono: values.telefono,
          centroEstetico: Boolean(parseInt(values.centroEstetico)),
          provincia: values.province,
          messaggio: values.messaggio,
          privacy: values.privacy,
          privacyCommerciale: Boolean(parseInt(values.privacyCommerciale)),
        },
      },
    })
    return response.data.createRichiestaCentro
  }

  const handleSubmit = async (values, setSubmitting) => {
    setSubmitting(true)

    await handleCreateRichiesta(values).then((response) => {
      setStatusRichiesta(response)
    })

    setOpenModal(true)

    setSubmitting(false)
  }
  useEffect(() => {
    if (ProvinceData?.province) {
      setProvince(ProvinceData.province)
    }
  }, [ProvinceData])
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={richiestaCentro}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting)
        trackingGAevent(
          '01',
          'landing',
          'diventa-un-centro',
          'request_sent_' + values.centroEstetico + '_' + values.privacyCommerciale
        )
      }}
    >
      {({ values, errors, handleChange, isSubmitting, setFieldValue, isValid }) => (
        <Form className="form-contatti">
          <FormLayout>
            <FormField>
              <FormInput
                name="nome"
                value={values.nome}
                placeholder="Nome*"
                onChange={handleChange}
                status={errors.nome ? 'error' : null}
              />
            </FormField>
            <FormField>
              <FormInput
                name="cognome"
                value={values.cognome}
                placeholder="Cognome*"
                onChange={handleChange}
                status={errors.cognome ? 'error' : null}
              />
            </FormField>
            <FormField>
              <FormInput
                name="email"
                value={values.email}
                placeholder="Email*"
                onChange={handleChange}
                status={errors.email ? 'error' : null}
              />
            </FormField>
            <FormField>
              <FormInput
                name="telefono"
                value={values.telefono}
                placeholder="Numero di telefono*"
                onChange={handleChange}
                status={errors.telefono ? 'error' : null}
              />
            </FormField>
            <label className="form-contatti__label">
              <strong>Hai già un centro estetico?*</strong>
            </label>
            <FormField size="full">
              <FormRadio
                name="centroEstetico"
                value={values.centroEstetico}
                options={[
                  { label: t('Sì, ho già un centro estetico'), value: 1 },
                  { label: t('No, vorrei aprire un nuovo centro'), value: 0 },
                ]}
                onChange={handleChange}
              />
            </FormField>
            <FormField size="full" space={'sx'}>
              <FormSelect
                name="province"
                options={province.map((item) => ({
                  value: item.provinciaChiave,
                  label: item.provincia,
                }))}
                placeholder="Provincia del centro estetico*"
                onChange={({ value }) => setFieldValue('province', value)}
                status={errors.cognome ? 'error' : null}
              />
            </FormField>
            <FormField size="full" space={'sx'}>
              <FormTextarea
                name="messaggio"
                value={values.messaggio}
                placeholder="Messaggio"
                onChange={handleChange}
                status={errors.messaggio ? 'error' : null}
              />
            </FormField>
            <label>*Campi obbligatori</label>
            <FormField size="full" space={'md'}>
              <FormCheckbox
                name="privacy"
                checked={values.privacy}
                label={
                  <>
                    Autorizzo il trattamento dei miei dati personali per rispondere alle mie
                    richieste come indicato al punto C) dell'<a href="/privacy/">informativa</a>.
                  </>
                }
                onChange={handleChange}
              />
            </FormField>
            <FormField size="full">
              <FormRadio
                name="privacyCommerciale"
                value={values.privacyCommerciale}
                options={[
                  { label: t('Sì'), value: 1 },
                  { label: t('No'), value: 0 },
                ]}
                description={
                  <>
                    Fornisco il consenso con la finalità di ricezione di comunicazioni promozionali
                    e commerciali come indicato nel punto C) nell’
                    <a href="/privacy/">informativa</a> e dichiaro di avere compiuto almeno 16 anni
                  </>
                }
                onChange={handleChange}
              />
            </FormField>
          </FormLayout>
          <div className="form-contatti__button">
            <Button
              disabled={isSubmitting || !isValid}
              label={'Invia la tua richiesta'}
              type="primary"
              inputType="submit"
              size="sm"
            />
          </div>
          <Modal open={openModal} setOpen={() => setOpenModal(false)}>
            {statusRichiesta.status ? (
              <div className="form-contatti__modal">
                <Check />
                <p className="form-contatti__modal__titolo">{t('form-contatti__modal__titolo')}</p>
                <p className="form-contatti__modal__testo">{t('form-contatti__modal__testo')}</p>
                <Button
                  href="/"
                  label={'Torna alla homepage'}
                  type="primary"
                  inputType="submit"
                  size="sm"
                />
              </div>
            ) : (
              <div className="form-contatti__modal">
                <p className="form-contatti__modal__titolo">
                  {t('form-contatti__modal__titolo-errore')}
                </p>
                <Button
                  href="/"
                  label={t('Torna alla homepage')}
                  type="primary"
                  inputType="submit"
                  size="sm"
                />
              </div>
            )}
          </Modal>
        </Form>
      )}
    </Formik>
  )
}

export default FormDiventaCentroEstetico
