import React, { useState } from 'react'
import { MenuItem } from 'components/atoms'
import { LancioMenu } from 'components/molecules'
import { useTrans } from 'utils/hooks'

const SubMenu = ({ menuItem }) => {
  const t = useTrans()
  const hasChildren = (item) => item?.node?.children?.edges?.length > 0
  const hasImage = (item) => item?.node?.image
  const [currentSubItem, setCurrentSubItem] = useState(null)
  const isCurrentSubItem = (item) => currentSubItem?.node && currentSubItem.node.id == item.node.id

  const itemLaunch = menuItem.node.children.edges.filter((item) => item?.node?.image)
  const itemList = menuItem.node.children.edges.filter((item) => !item?.node?.image)

  return (
    <div className={'submenu' + (menuItem.node.key ? ' submenu--' + menuItem.node.key : '')}>
      {menuItem.node.children.edges.length > 0 && (
        <div className="submenu__list">
          {menuItem.node.children
            ? itemList.map((item, i) => (
                <div
                  className={
                    'submenu__item' + (hasChildren(item) ? ' submenu__item--with-children' : '')
                  }
                  key={'submenu-item_' + menuItem.node.pk + '_' + i.toString()}
                >
                  <MenuItem
                    href={item?.node?.url}
                    label={item.node.name}
                    type={hasChildren(item) ? 'category' : 'category-item'}
                    level={1}
                    rootLabel={menuItem.node.name}
                  />
                  {hasChildren(item)
                    ? item.node.children.edges.map((subitem, k) => (
                        <MenuItem
                          href={subitem?.node?.url}
                          key={'subsubmenu_key_' + i.toString() + '_' + k.toString()}
                          label={subitem.node.name}
                          onMouseEnter={() => setCurrentSubItem(item)}
                          type="category-item"
                          active={isCurrentSubItem(item)}
                          level={2}
                          rootLabel={menuItem.node.name}
                          boldLabel={item.node.name}
                        />
                      ))
                    : null}
                </div>
              ))
            : null}
        </div>
      )}
      {itemLaunch.length > 0 && (
        <div className="submenu__lanciomenu">
          <LancioMenu
            image={itemLaunch[0].node.image}
            label={itemLaunch[0].node.name}
            href={itemLaunch[0].node.url ? itemLaunch[0].node.url : ''}
            labelCta={t('Scopri')}
          />
        </div>
      )}
    </div>
  )
}

export default SubMenu
