import React from 'react'
import Link from 'next/link'
import { Minus } from 'components/atoms/Icons'
import { trackingGAevent } from 'utils/gtm'

const MenuItem = ({
  href,
  label,
  onMouseEnter = null,
  className = '',
  active = null,
  type = '',
  level = 0,
  rootLabel = '',
  boldLabel = '',
}) => {
  const classes =
    'menuitem' +
    (type ? ' menuitem--' + type : '') +
    (active ? ' menuitem--active' : '') +
    (className ? ' ' + className : '')

  const renderContent = () => (
    <>
      {type == 'category-item' && (
        <span className="menuitem__icon">
          <Minus />
        </span>
      )}
      <span>{label}</span>
    </>
  )

  function handleTracking() {
    if (!level) {
      trackingGAevent(19, 'menu', 'click', label) // primo livello
    } else {
      if (level === 1) {
        trackingGAevent(20, 'menu', `click ${rootLabel}`, label) // secondo livello
      } else {
        trackingGAevent(20, 'menu', `click ${rootLabel}`, `${boldLabel} ${label}`) // terzo livello
      }
    }
  }

  if (href) {
    return (
      <Link legacyBehavior href={href}>
        <a onMouseEnter={onMouseEnter} className={classes} onClick={handleTracking}>
          {renderContent()}
        </a>
      </Link>
    )
  }

  return (
    <span onMouseEnter={onMouseEnter} className={classes}>
      {renderContent()}
    </span>
  )
}

export default MenuItem
