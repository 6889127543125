import React from 'react'

function RadioButtonFull(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={
        'icon icon--strokes icon--radio-button-full ' + (props.classes ? props.classes : '')
      }
    >
      <rect x="4" y="4" width="16" height="16" rx="8" strokeWidth="2" fill="none" />
      <rect x="7" y="7" width="10" height="10" rx="5" />
    </svg>
  )
}

export default RadioButtonFull
