import React from 'react'
import Link from 'next/link'
import { ArrowRight } from 'components/atoms/Icons'
import { Datatable, Button } from 'components/atoms'
import { dateIsoToIt, priceNotation } from 'utils/safe'
import { trackingGAevent } from 'utils/gtm'

const OrdineDatatableRow = ({ cols, ordine, onLinkClick }) => {
  return (
    <Datatable.Row>
      <Datatable.Cell col={cols.codice} className={'ordine-datatable__codice'}>
        <span className="ordine-datatable__mobile-title">Ordine n° </span>
        {ordine.pk}
      </Datatable.Cell>
      <Datatable.Cell col={cols.data} className={'ordine-datatable__data'}>
        <span className="ordine-datatable__mobile-title">Data </span>
        {/* {dateIsoToIt(ordine.data)} */}
        {ordine.data}
      </Datatable.Cell>
      <Datatable.Cell col={cols.totale} className={'ordine-datatable__totale'}>
        {priceNotation(ordine.prezzoTotale)}
      </Datatable.Cell>
      <Datatable.Cell col={cols.stato} className={'ordine-datatable__stato'}>
        {ordine.stato.nome}
      </Datatable.Cell>
      <Datatable.Cell col={cols.action} className={'ordine-datatable__action'}>
        {onLinkClick ? (
          <Button
            type="ghost"
            size="md"
            responsive={true}
            label="Dettagli ordine"
            icon={<ArrowRight />}
            iconPos="right"
            onClick={() => {
              onLinkClick && onLinkClick(ordine)
              trackingGAevent(34, 'my_account', 'ordini', 'dettagli_ordini')
            }}
          />
        ) : (
          <Link legacyBehavior href={ordine.url}>
            <a onClick={() => trackingGAevent(34, 'my_account', 'ordini', 'dettagli_ordini')}>
              <Button
                type="ghost"
                size="md"
                responsive={true}
                label="Dettagli ordine"
                icon={<ArrowRight />}
                iconPos="right"
              />
            </a>
          </Link>
        )}
      </Datatable.Cell>
    </Datatable.Row>
  )
}

const OrdineDatatable = ({ ordini, onLinkClick }) => {
  const cols = {
    codice: { label: 'N° ordine', desktopCols: 2, mobileCols: 12 },
    data: { label: 'Data', desktopCols: 2, mobileCols: 6 },
    totale: { label: 'Totale', desktopCols: 2, mobileCols: 6, mobileAlign: 'right' },
    stato: { label: 'Stato', desktopCols: 2, mobileCols: 6 },
    action: { mobileCols: 6, desktopCols: 4, align: 'right' },
  }

  return (
    <Datatable className="ordine-datatable" cols={cols}>
      {ordini?.map((ordine, index) => (
        <OrdineDatatableRow key={index} cols={cols} ordine={ordine} onLinkClick={onLinkClick} />
      ))}
    </Datatable>
  )
}

export default OrdineDatatable
