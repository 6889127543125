import React from 'react'

function Arrow2Right({ classes = '' }) {
  return (
    <svg
      className={'icon icon--strokes icon--arrow-2-right ' + classes}
      fill="none"
      viewBox="0 0 25 24"
    >
      <line x1="4.3689" y1="11.5" x2="20.3689" y2="11.5" fill="none" strokeWidth="2" />
      <path d="M15.8689 7L20.3689 11.5L15.8689 16" fill="none" strokeWidth="2" />
    </svg>
  )
}

export default Arrow2Right
