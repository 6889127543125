import React from 'react'

function Regalo(props) {
  return (
    <svg
      viewBox="0 0 71 70"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--beauty-expert ' + (props.classes ? props.classes : '')}
    >
      <g clipPath="url(#beauty-expert-clip0)">
        <path
          d="M36.431 34.475C37.985 34.475 38.762 32.914 39.539 32.914C37.985 32.137 36.431 32.137 35.654 32.914C34.422 32.032 32.931 32.34 31.762 32.914C32.539 32.914 33.4 34.475 34.87 34.475H36.431Z"
          stroke="none"
        />
        <path
          d="M51.88 47.719C50.809 37.793 51.53 27.867 52.769 19.684C52.769 15.533 50.683 11.851 47.834 9.20502C44.985 6.55202 41.359 4.90002 37.985 4.90002C33.295 4.90002 29.333 6.43302 26.799 8.81302C24.328 8.59602 22.347 10.01 21.129 11.879C19.855 13.839 19.239 16.247 19.309 18.13C19.309 28 19.785 39.312 18.623 48.111C13.499 50.722 11 54.075 11 58.352V64.491C11 64.778 11.238 65.016 11.525 65.016H20.198H35.654H51.11H59.783C60.07 65.016 60.308 64.778 60.308 64.491V58.352C60.301 53.865 57.536 50.379 51.88 47.719ZM35.969 17.255C38.027 21.427 42.598 24.234 46.105 26.327C45.965 28.994 44.53 31.409 42.486 33.453C40.442 35.49 37.859 37.1 35.654 38.241C33.435 37.1 30.838 35.497 28.794 33.453C26.743 31.402 25.329 28.98 25.196 26.299C28.724 24.192 33.344 21.406 35.969 17.255ZM40.323 36.617V42.952H30.978V36.638C32.448 37.674 33.939 38.542 35.283 39.214C35.5 39.326 35.773 39.326 35.99 39.214C37.341 38.542 38.846 37.66 40.323 36.617ZM26.337 46.438L25.7 47.215L26.918 48.188L29.473 45.003C30.229 44.758 30.663 44.443 30.978 43.813H40.323C40.316 44.1 40.498 44.387 40.757 44.513L50.452 48.027C45.678 56.826 36.179 56.966 35.682 56.966C35.654 56.966 35.654 56.966 35.654 56.966H35.647C35.528 56.966 25.784 57.036 20.884 48.083L26.337 46.438ZM12.05 58.352C12.05 54.362 14.542 51.219 19.673 48.755V63.966H12.05V58.352ZM20.723 49.875C25.371 56.966 33.043 57.883 35.129 58.002V63.966H20.723V49.875ZM36.172 58.002C38.258 57.883 45.93 56.966 50.578 49.875V63.959H36.172V58.002ZM59.251 63.966H51.628V48.755C56.752 51.212 59.251 54.362 59.251 58.352V63.966Z"
          stroke="none"
        />
      </g>
      <defs>
        <clipPath id="beauty-expert-clip0">
          <rect width="49.301" height="60.116" fill="white" transform="translate(11 4.90002)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Regalo
