import React from 'react'

function Menu(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--strokes icon--menu ' + (props.classes ? props.classes : '')}
      {...props}
    >
      <line x1="3" y1="12" x2="17" y2="12" fill="none" strokeWidth="2" />
      <line x1="3" y1="5" x2="21" y2="5" fill="none" strokeWidth="2" />
      <line x1="3" y1="19" x2="21" y2="19" fill="none" strokeWidth="2" />
    </svg>
  )
}

export default Menu
