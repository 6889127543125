import React from 'react'

const FormBlock = ({ title, description, children }) => {
  return (
    <div className="form-block">
      {title && <h3 className="form-block__title">{title}</h3>}
      {children}
    </div>
  )
}

export default FormBlock
