import React from 'react'

const Tag = ({ label, color }) => {
  return (
    <span className="tag" style={{ backgroundColor: color }}>
      {label}
    </span>
  )
}

export default Tag
