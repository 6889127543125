import React, { useState } from 'react'
import { ArrowRight } from 'components/atoms/Icons'
import { Button, Tag, ImageBox } from 'components/atoms'
import { trackingGAevent } from 'utils/gtm'

const TrattamentoCard = ({ trattamento, expanded, onDetailClick, onVideoOpen }) => {
  const tags = [{ label: trattamento.zonaTrattamento?.title, color: '#8F405D' }]
    .concat(trattamento.tipologieTrattamento.map((t) => ({ label: t.title, color: '#69645E' })))
    .concat(
      trattamento.durateTrattamento.map((d) => ({ label: d.minutes + ' min', color: '#BAB2AA' }))
    )

  const adatto_per = trattamento.tipologiePelle.map((pelle) => pelle.title).join(', ')

  const durate = trattamento.durateTrattamento.map((d) => d.title).join(', ')

  return (
    <div
      className={'trattamento-card' + (expanded ? ' trattamento-card--expanded' : '')}
      id={trattamento?.slug}
    >
      <ImageBox
        src={trattamento.image}
        maxWidth={368}
        maxHeight={260}
        alt={trattamento.title.toAltTitle()}
        title={trattamento.title.toAltTitle()}
        classPrefix="trattamento-card"
      />
      <div className="trattamento-card__content">
        <div className="trattamento-card__tags">
          {tags &&
            tags.map((tag, i) => <Tag label={tag.label} color={tag.color} key={'tag_' + i} />)}
        </div>
        <p className="trattamento-card__title">{trattamento.title}</p>
        <p className="trattamento-card__abstract">{trattamento.abstract}</p>

        {/* visibile solo in expanded mobile (gestito via css) - sempre presente per transition */}
        <div className="trattamento-card__mobile-expansion">
          <div className="trattamento-card__description">{trattamento.description}</div>
          <div className="trattamento-card__details">
            {adatto_per ? (
              <p className="trattamento-card__detail-item">
                <span className="trattamento-card__detail-title">Adatto per pelli: </span>
                <span className="trattamento-card__detail-value">{adatto_per}</span>
              </p>
            ) : null}
            <p className="trattamento-card__detail-item">
              <span className="trattamento-card__detail-title">Durata trattamento: </span>
              <span className="trattamento-card__detail-value">{durate}</span>
            </p>
          </div>
          <div className="trattamento-card__buttons">
            {trattamento.videoTrattamento && (
              <Button
                type="primary"
                label="Guarda il video"
                onClick={() => {
                  trackingGAevent(45, 'trattamenti', 'guarda_il_video', trattamento.title)
                  onVideoOpen()
                }}
              />
            )}
            <Button
              type="secondary"
              icon={<ArrowRight />}
              iconPos={'right'}
              label="Trova il centro più vicino"
              href="/centri"
              onClick={() =>
                trackingGAevent(45, 'trattamenti', 'trova_il_centro_più_vicino', trattamento.title)
              }
            />
          </div>
        </div>

        <Button
          type="ghost"
          label="Dettagli"
          classes="trattamento-card__expand-button"
          onClick={() => {
            trackingGAevent(45, 'trattamenti', 'dettagli', trattamento.title)
            onDetailClick()
          }}
        />
      </div>
    </div>
  )
}

export default TrattamentoCard
