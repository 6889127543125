import { useRouter } from 'next/router'
import React from 'react'
import { useSelector } from 'react-redux'
import { api } from 'utils'
import { TOOLBAR } from 'utils/endpoint'
import Button from '../../atoms/Button/Button'
import { Tooltip } from 'components/atoms'
import { Info } from '../../atoms/Icons'

api.defaults.xsrfCookieName = 'csrftoken'
api.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

const Toolbar = React.memo(({ page = null, item = null }) => {
  const router = useRouter()

  const { utente } = useSelector((state) => state.utente)

  if (!utente) return null

  if (!(utente.isStaff || utente.isImpersonate)) return null

  const handleSwitchMode = (edit) => {
    api
      .post(TOOLBAR + 'set_edit/', { edit })
      .then(() => {
        router.reload()
      })
      .catch((error) => console.log(error))
  }

  const VisibilitaTooltipDescription = ({item}) => {
    return (
      <div>
        <p>Stato pubblicazione: {item.pubblicato ? 'pubblicato' : 'non pubblicato'}</p>
        {item.visibilitaDal && <p>Visibilità dal: {item.visibilitaDal}</p>}
        {item.visibilitaAl && <p>Visibilità fino al: {item.visibilitaAl}</p>}
      </div>
    )
  }

  return (
    <div className="toolbar">
      {utente.isImpersonate ? (
        <div className="toolbar__user">
          Stai impersonando {utente.nome} {utente.cognome} -{' '}
          <a href="/d/impersonate/stop/">Smetti di impersonare</a>
        </div>
      ) : (
        <>
          <div className="toolbar__user">
            {utente.nome} {utente.cognome}
          </div>
          <section className="toolbar__left"></section>
          <section className="toolbar__right">
            {item && 'visibile' in item ? (
              <span className='visibilita--text'>
                Stato:{' '}
                <span className={`visibilita--${item.visibile}`}>
                  {item.visibile ? 'visibile' : 'non visibile'}
                </span>
                <Tooltip
                  id={`tooltip-toolbar-item-visibilita`}
                  label={<VisibilitaTooltipDescription item={item} />}
                  pos="bottom"
                  className={'tooltip'}
                >
                  <span className={"info"}>
                    <Info />
                  </span>
                </Tooltip>
              </span>
            ) : (
              page &&
              'visibile' in page && (
                <span className='visibilita--text'>
                  Stato:{' '}
                  <span className={`visibilita--${page.visibile}`}>
                    {page.visibile ? 'visibile' : 'non visibile'}
                  </span>
                  <Tooltip
                    id={`tooltip-toolbar-page-visibilita`}
                    label={<VisibilitaTooltipDescription item={page} />}
                    pos="bottom"
                    className={'tooltip'}
                  >
                    <span className={"info"}>
                      <Info />
                    </span>
                  </Tooltip>
                </span>
              )
            )}
            {item && item.admin_url && (
              <div className="button-div">
                <a href={item.admin_url} className="button button--tertiary" target="_blank">
                  Modifica {item.model_class?.capitalize()}
                </a>
              </div>
            )}
            {page && page.admin_url && (
              <div className="button-div">
                <a href={page.admin_url} className="button button--tertiary" target="_blank">
                  Admin Pagina
                </a>
              </div>
            )}
            {router.isPreview ? (
              <Tooltip
                id={`tooltip-toolbar-stop-preview`}
                label={
                  'Con la modalità preview disattivata non potrai visualizzare le pagine non pubblicate'
                }
                pos="bottom"
                className={'tooltip'}
              >
                <div className="button-div">
                  <Button
                    label="DISATTIVA MODALITÀ PREVIEW"
                    type="primary"
                    size="sm"
                    href={`/api/stop-preview?secret=${process.env.PREVIEW_MODE_SECRET}&redirect=${router.asPath}`}
                  />
                </div>
              </Tooltip>
            ) : (
              <Tooltip
                id={`tooltip-toolbar-attiva-preview`}
                label={
                  'Con la modalità preview attiva puoi visualizzare le pagine anche se non pubblicate'
                }
                pos="bottom"
                className={'tooltip'}
              >
                <div className="button-div">
                  <Button
                    external
                    label="ATTIVA MODALITÀ PREVIEW"
                    type="primary"
                    size="sm"
                    href={`/api/preview?secret=${process.env.PREVIEW_MODE_SECRET}&redirect=${router.asPath}`}
                  />
                </div>
              </Tooltip>
            )}
            {utente.edit ? (
              <div className="button-div">
                <Button
                  label="VEDI PUBBLICATA"
                  type="primary"
                  size="sm"
                  onClick={() => handleSwitchMode(false)}
                />
              </div>
            ) : (
              <div className="button-div">
                <Button
                  label="MODIFICA"
                  type="primary"
                  size="sm"
                  onClick={() => handleSwitchMode(true)}
                />
              </div>
            )}
          </section>
        </>
      )}
    </div>
  )
})

export default Toolbar
