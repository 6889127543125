import { Divider } from 'components/atoms'
import { ProdottoCarrello, RigaCarrello } from 'components/molecules'
import { Fragment } from 'react'
import { useIsMobile } from 'utils/hooks'

const RigheCarrello = ({ items }) => {
  const isMobile = useIsMobile()

  return (
    <div className="righe-carrello">
      <header className="righe-carrello__head">
        <section className="righe-carrello__head__content">
          <p>Prodotto</p>
        </section>
        <section className="righe-carrello__head__summary">
          <p>Size</p>
          <p>Quantità</p>
          <p>Prezzo</p>
        </section>
      </header>
      <main className="righe-carrello__content">
        {items.map((item) => (
          <Fragment key={item.pk}>
            {isMobile ? (
              <ProdottoCarrello
                prodotto={item.prodotto}
                quantita={item.quantita}
                omaggio={item.omaggio}
              />
            ) : (
              <RigaCarrello
                prodotto={item.prodotto}
                quantita={item.quantita}
                omaggio={item.omaggio}
              />
            )}
            {items.length > 1 ? <Divider /> : null}
          </Fragment>
        ))}
      </main>
    </div>
  )
}

export default RigheCarrello
