import { createWrapper } from 'next-redux-wrapper'
import { applyMiddleware, createStore } from 'redux'
// import { composeWithDevTools } from "redux-devtools-extension"
import promise from 'redux-promise-middleware'
import thunk from 'redux-thunk'
import createRootReducer from './root-reducer'

const middleware = applyMiddleware(promise, thunk)

//__NEXT_REDUX_WRAPPER_HYDRATE__ controllare
const makeStore = () => createStore(createRootReducer, middleware)

export const wrapper = createWrapper(makeStore, { debug: false })
