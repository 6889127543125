import React from 'react'
import QuantityHandler from 'components/atoms/QuantityHandler/QuantityHandler'
import Link from 'next/link'
import { WImage } from 'components/atoms'
import { priceNotation } from 'utils/safe'

function RigaCarrello({ prodotto, quantita, omaggio = false }) {
  return (
    <article className="riga-carrello">
      <Link legacyBehavior href={prodotto.url}>
        <a className="riga-carrello__content">
          <WImage
            src={prodotto.smallThumb}
            maxWidth={98}
            maxHeight={98}
            alt={`${prodotto.titolo} ${prodotto.sottotitolo || ''}`.toAltTitle()}
            title={`${prodotto.titolo} ${prodotto.sottotitolo || ''}`.toAltTitle()}
            classPrefix="riga-carrello"
          />
          <p className="riga-carrello__title">{prodotto.titolo}</p>
        </a>
      </Link>
      <section className="riga-carrello__summary">
        <div className="riga-carrello__size">{prodotto.formato?.nome}</div>
        <div className="riga-carrello__quantity">
          <QuantityHandler value={quantita} prodotto={prodotto} />
        </div>
        <div className="riga-carrello__price">
          {omaggio ? (
            <p className="prodotto-checkout__finalprice">Omaggio</p>
          ) : (
            <>
              {prodotto.prezzo != prodotto.prezzoScontato && (
                <p className="riga-carrello__fullprice">{priceNotation(prodotto.prezzo)}</p>
              )}
              <p className="riga-carrello__finalprice">{priceNotation(prodotto.prezzoScontato)}</p>
            </>
          )}
        </div>
      </section>
    </article>
  )
}

export default RigaCarrello
