// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://04d4df2c9f464d2d8ceadd450d9797e8@o19983.ingest.sentry.io/5982816',
  tracesSampleRate: 0.2,
  enabled: process.env.NODE_ENV !== 'development',
  integrations: [
    new Sentry.Integrations.BrowserTracing({
      tracingOrigins: [
        'matis-paris-staging.webranking.biz',
        'www.matis-paris.it',
        'matis-paris.it',
        'www.matis-paris.it/d/graphql',
      ],
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
})
