import React from 'react'
import { Info } from 'components/atoms/Icons'

const FormField = ({ label, size, space, variant, status, errorMessage, className, children }) => {
  return (
    <div
      className={
        `form-field` +
        (size ? ` form-field--${size}` : '') +
        (space ? ` form-field--space-${space}` : '') +
        (variant ? ` form-field--${variant}` : '') +
        (status ? ` form-field--${status}` : '') +
        (className ? ' ' + className : '')
      }
    >
      {label ? <label className="form-field__label">{label}</label> : ''}
      <div className="form-field__children">{children}</div>
      {status === 'error' && errorMessage ? (
        <p className="form-field__error">
          <Info classes="form-field__error-icon" />
          {errorMessage}
        </p>
      ) : (
        <></>
      )}
    </div>
  )
}

export default FormField
