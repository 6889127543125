import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { FormLayout, FormField, FormInput, Button, Checkbox, FormSelect } from 'components/atoms'
import MyAccountForm from '../MyAccountForm/MyAccountForm'
import { editIndirizzoSpedizione, editIndirizzoFatturazione } from 'utils/validation'
import { CREATE_INDIRIZZO } from 'utils/queries'
import italia from 'utils/italia'

const MyAccountIndirizzoAddForm = ({ isFatturazione = false, setEdit, checkout = false }) => {
  const [createIndirizzo, { data: dataCreateIndirizzo }] = useMutation(CREATE_INDIRIZZO)

  const [cap, setCap] = useState([])
  const [province, setProvince] = useState([])

  const initialValues = {
    nome: '',
    cognome: '',
    indirizzo: '',
    isFatturazione: isFatturazione,
    informazioniAggiuntive: '',
    consegnaPresso: '',
    citta: '',
    cap: '',
    provincia: '',
    telfono: '',
    cf: null,
    default: true,
  }

  const refetchQueries = checkout
    ? ['IndirizziSpedizione', 'IndirizziFatturazione', 'Checkout']
    : ['IndirizziSpedizione', 'IndirizziFatturazione']

  async function handleSubmit(values, setSubmitting) {
    setSubmitting(true)

    await createIndirizzo({
      variables: {
        input: {
          nome: values.nome,
          cognome: values.cognome,
          indirizzo: values.indirizzo,
          isFatturazione: values.isFatturazione,
          informazioniAggiuntive: values.informazioniAggiuntive,
          consegnaPresso: values.consegnaPresso,
          citta: values.citta,
          cap: values.cap,
          provincia: values.provincia,
          telefono: values.telefono,
          cf: values.cf,
          default: values.default,
        },
      },
      refetchQueries,
    })

    setSubmitting(false)
    setEdit(false)
  }

  function handleChangeCitta(citta, setFieldValue) {
    const cap = italia
      .filter((c) => c.comune === citta)
      .map((c) => c.cap)
      .flat()

    const province = italia.filter((c) => c.comune === citta).map((c) => c.provincia)

    setCap(cap)
    setProvince(province)

    if (cap.length) setFieldValue('cap', cap[0])
    if (province.length) setFieldValue('provincia', province[0])
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={isFatturazione ? editIndirizzoFatturazione : editIndirizzoSpedizione}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting)
      }}
    >
      {({ values, errors, handleChange, isSubmitting, setFieldValue, status, handleSubmit }) => (
        <Form className="my-account-indirizzo-add-form">
          <MyAccountForm
            add
            saveLabel="Aggiungi indirizzo"
            title={
              isFatturazione
                ? 'Aggiungi indirizzo di fatturazione'
                : 'Aggiungi indirizzo di spedizione'
            }
            onCancel={() => setEdit(false)}
            onSave={handleSubmit}
            submitting={isSubmitting}
            checkout={checkout}
          >
            <FormLayout>
              <FormField>
                <FormInput
                  name="nome"
                  value={values.nome}
                  placeholder="Nome*"
                  onChange={handleChange}
                  errorMessage={errors.nome}
                  status={errors.nome ? 'error' : null}
                />
              </FormField>
              <FormField>
                <FormInput
                  name="cognome"
                  value={values.cognome}
                  placeholder="Cognome*"
                  onChange={handleChange}
                  errorMessage={errors.cognome}
                  status={errors.cognome ? 'error' : null}
                />
              </FormField>
              <FormField>
                <FormInput
                  name="indirizzo"
                  value={values.indirizzo}
                  placeholder="Indirizzo*"
                  onChange={handleChange}
                  errorMessage={errors.indirizzo}
                  status={errors.indirizzo ? 'error' : null}
                />
              </FormField>
              {!isFatturazione && (
                <FormField>
                  <FormInput
                    name="consegnaPresso"
                    value={values.consegnaPresso}
                    placeholder={'Consegna presso (se azienda)'}
                    onChange={handleChange}
                  />
                </FormField>
              )}
              <FormField>
                <FormInput
                  name="informazioniAggiuntive"
                  value={values.informazioniAggiuntive}
                  placeholder={'Informazioni aggiuntive'}
                  onChange={handleChange}
                />
              </FormField>
              <FormField>
                <FormSelect
                  name="citta"
                  options={italia.map(({ comune }) => ({
                    value: comune,
                    label: comune,
                    selected: comune === values.citta,
                  }))}
                  placeholder="Città"
                  onChange={({ value }) => {
                    setFieldValue('citta', value)
                    handleChangeCitta(value, setFieldValue)
                  }}
                  errorMessage={errors.citta}
                  status={errors.citta ? 'error' : null}
                  isSearchable
                />
              </FormField>
              <FormField size="sm">
                <FormSelect
                  name="cap"
                  options={cap.map((cap) => ({
                    value: cap,
                    label: cap,
                    selected: cap === values.cap,
                  }))}
                  placeholder="CAP"
                  onChange={({ value }) => setFieldValue('cap', value)}
                  errorMessage={errors.cap}
                  status={errors.cap ? 'error' : null}
                />
              </FormField>
              <FormField size="sm">
                <FormSelect
                  name="provincia"
                  options={province.map((provincia) => ({
                    value: provincia,
                    label: provincia,
                    selected: provincia === values.provincia,
                  }))}
                  placeholder="Provincia*"
                  onChange={({ value }) => setFieldValue('provincia', value)}
                  errorMessage={errors.provincia}
                  status={errors.provincia ? 'error' : null}
                />
              </FormField>
              <FormField>
                <FormInput
                  name="telefono"
                  value={values.telefono}
                  placeholder={'Telefono*'}
                  onChange={handleChange}
                  errorMessage={errors.telefono}
                  status={errors.telefono ? 'error' : null}
                />
              </FormField>
              {isFatturazione && (
                <FormField>
                  <FormInput
                    name="cf"
                    value={values.cf}
                    placeholder={'Codice fiscale*'}
                    onChange={handleChange}
                    errorMessage={errors.cf}
                    status={errors.cf ? 'error' : null}
                  />
                </FormField>
              )}
            </FormLayout>
            {checkout && (
              <Checkbox
                name="default"
                label={'Salva questo indirizzo per le prossime volte'}
                checked={values.default}
                onChange={handleChange}
                classes="my-account-indirizzo-add-form__default"
              />
            )}
          </MyAccountForm>
        </Form>
      )}
    </Formik>
  )
}

export default MyAccountIndirizzoAddForm
