import React from 'react'

function Filters(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--strokes icon--filters ' + (props.classes ? props.classes : '')}
    >
      <line x1="3" y1="12" x2="21" y2="12" fill="none" strokeWidth="2" />
      <line x1="3" y1="5" x2="21" y2="5" fill="none" strokeWidth="2" />
      <line x1="3" y1="19" x2="21" y2="19" fill="none" strokeWidth="2" />
      <circle cx="17" cy="12" r="2" stroke="none" />
      <circle cx="8" cy="19" r="2" stroke="none" />
      <circle cx="12" cy="5" r="2" stroke="none" />
    </svg>
  )
}

export default Filters
