import React from 'react'
import Link from 'next/link'
import { ArrowRight } from 'components/atoms/Icons'
import { Button, ImageBox } from 'components/atoms'
import { trackingGAevent } from 'utils/gtm'

function LancioMenu({ image, label, href, labelCta }) {
  return (
    <div className="lanciomenu">
      <ImageBox
        src={image}
        maxWidth={200}
        maxHeight={200}
        classPrefix="lanciomenu"
        alt={label.toAltTitle()}
        title={label.toAltTitle()}
      />
      <p className="lanciomenu__title">{label}</p>
      <Link legacyBehavior href={href}>
        <a
          className="lanciomenu__cta"
          onClick={() => trackingGAevent(27, 'promo_menu', 'scopri', label)}
        >
          <Button
            icon={<ArrowRight />}
            iconPos="right"
            label={labelCta}
            type="ghost"
            variant="primary"
          />
        </a>
      </Link>
    </div>
  )
}

export default LancioMenu
