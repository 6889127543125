import { Button, ImageBox } from 'components/atoms'
import { ProdottoCard } from 'components/molecules'

import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useEffect } from 'react'
import { trackingGAevent, trackingProductImpression } from 'utils/gtm'
SwiperCore.use([Navigation])

function BoxMarketingProdotti({ box }) {
  useEffect(() => {
    if (box.prodotti?.length > 0) trackingProductImpression(box.prodotti.slice(0, 1), 'home_page')
  }, [])

  return (
    <div
      className={`box-marketing-prodotti box-marketing-prodotti--${box.imagePosition?.toLowerCase()}`}
    >
      <div className="box-marketing-prodotti__bg">
        <div className="box-marketing-prodotti__container">
          <div className="box-marketing-prodotti__content">
            <p className="box-marketing-prodotti__title">{box.titolo}</p>
            <div
              className="box-marketing-prodotti__text"
              dangerouslySetInnerHTML={{ __html: box.testo }}
            />
            {box.cta && box.finalUrl && (
              <div className="box-marketing-prodotti__cta-box">
                <Button
                  href={box.finalUrl}
                  label={box.cta}
                  type="primary"
                  blank={box.blank}
                  className=""
                  //onClick={() => trackingGAevent(27, 'promo_menu', 'scopri', box.titolo)}
                />
              </div>
            )}
            {box.prodotti?.length > 0 && (
              <div className="box-marketing-prodotti__slider">
                <Swiper
                  spaceBetween={16}
                  slidesPerView={1}
                  watchOverflow
                  navigation={true}
                  onSlideChange={(e) =>
                    trackingProductImpression(
                      box.prodotti.slice(e.activeIndex, e.activeIndex + 1),
                      'home_page',
                      e.activeIndex
                    )
                  }
                >
                  {box.prodotti.map((prodotto, index) => (
                    <SwiperSlide key={'prodotto-' + index}>
                      <ProdottoCard prodotto={prodotto} waPosition={index + 1} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )}
          </div>
          <ImageBox
            src={box.imageThumb}
            maxWidth={560}
            maxHeight={788}
            classPrefix="box-marketing-prodotti"
            alt={box.titolo.toAltTitle()}
            title={box.titolo.toAltTitle()}
            lazyLoad
          />
        </div>
      </div>
    </div>
  )
}

export default BoxMarketingProdotti
