import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { BRAND, DOMAIN } from 'utils'

// DOCS https://github.com/garmeeh/next-seo

const MetaSeo = ({ seo, additionalMetaTags = null, article = null }) => {
  const { title, description, image } = seo

  const router = useRouter()

  // TODO: default static image
  const ogImgae = image ? image : ''

  const og = {
    localle: 'it_IT',
    site_name: BRAND,
    url: DOMAIN + router.asPath,
    title: title,
    description: description,
    images: [{ url: DOMAIN + ogImgae }],
    type: 'website',
  }

  if (article) {
    og['type'] = 'article'
    og['article'] = {
      publishedTime: article.publicationDate,
      section: article.categoria?.title,
    }
  }

  return (
    <NextSeo
      title={title}
      description={description}
      additionalMetaTags={additionalMetaTags}
      openGraph={og}
    />
  )
}

export default MetaSeo
