import React from 'react'

function Instagram({ classes = 'icon--instagram' }) {
  return (
    <svg className={'icon ' + classes} viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#instagram-clip0)">
        <mask
          id="instagram-mask0"
          masktype="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="25"
          height="24"
        >
          <path d="M0.000488281 0H24.0002V24H0.000488281V0Z" fill="white" />
        </mask>
        <g mask="url(#instagram-mask0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.4065 4.155C17.6105 4.155 16.9655 4.8 16.9655 5.595C16.9655 6.39 17.6105 7.035 18.4065 7.035C19.2015 7.035 19.8445 6.39 19.8445 5.595C19.8445 4.8 19.2015 4.155 18.4065 4.155ZM12.0005 16C9.79149 16 8.00049 14.21 8.00049 12C8.00049 9.791 9.79149 8 12.0005 8C14.2095 8 16.0005 9.791 16.0005 12C16.0005 14.21 14.2095 16 12.0005 16ZM12.0005 5.838C8.59749 5.838 5.83849 8.597 5.83849 12C5.83849 15.403 8.59749 18.163 12.0005 18.163C15.4035 18.163 18.1625 15.405 18.1625 12C18.1625 8.597 15.4035 5.838 12.0005 5.838ZM12.0005 0C8.74149 0 8.33349 0.014 7.05349 0.072C2.69549 0.272 0.273488 2.69 0.0734883 7.052C0.0144883 8.333 0.000488281 8.741 0.000488281 12C0.000488281 15.259 0.0144883 15.668 0.0724883 16.948C0.272488 21.306 2.69049 23.728 7.05249 23.928C8.33349 23.986 8.74149 24 12.0005 24C15.2595 24 15.6685 23.986 16.9485 23.928C21.3025 23.728 23.7305 21.31 23.9275 16.948C23.9865 15.668 24.0005 15.259 24.0005 12C24.0005 8.741 23.9865 8.333 23.9275 7.053C23.7325 2.699 21.3105 0.273 16.9495 0.073C15.6685 0.014 15.2595 0 12.0005 0ZM12.0005 2.163C15.2045 2.163 15.5845 2.175 16.8495 2.233C20.1015 2.381 21.6215 3.924 21.7685 7.152C21.8275 8.417 21.8385 8.797 21.8385 12.001C21.8385 15.206 21.8265 15.585 21.7685 16.85C21.6205 20.075 20.1055 21.621 16.8495 21.769C15.5845 21.827 15.2065 21.839 12.0005 21.839C8.79649 21.839 8.41649 21.827 7.15149 21.769C3.89149 21.62 2.38049 20.07 2.23249 16.849C2.17449 15.584 2.16249 15.205 2.16249 12C2.16249 8.796 2.17549 8.417 2.23249 7.151C2.38149 3.924 3.89649 2.38 7.15149 2.232C8.41749 2.175 8.79649 2.163 12.0005 2.163Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="instagram-clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Instagram
