import React from 'react'
import {
  CheckboxEmpty,
  CheckboxFull,
  RadioButtonEmpty,
  RadioButtonFull,
} from 'components/atoms/Icons'

const FormCheckbox = ({
  name,
  size,
  label,
  disabled,
  value,
  onChange,
  checked,
  readOnly,
  onBlur,
  radioStyle,
  className,
}) => {
  return (
    <label
      className={
        `form-checkbox` +
        (size ? ` form-checkbox--${size}` : '') +
        (disabled ? ' form-checkbox--disabled' : '') +
        (className ? ' ' + className : '')
      }
    >
      <input
        checked={checked}
        name={name}
        value={value}
        className="form-checkbox__input"
        type="checkbox"
        disabled={disabled}
        onChange={onChange}
        readOnly={readOnly}
        onBlur={onBlur}
      />
      <span className="form-checkbox__styledinput">
        {radioStyle ? (
          checked ? (
            <RadioButtonFull />
          ) : (
            <RadioButtonEmpty />
          )
        ) : checked ? (
          <CheckboxFull />
        ) : (
          <CheckboxEmpty />
        )}
      </span>
      <span className="form-checkbox__label">{label}</span>
    </label>
  )
}

export default FormCheckbox
