import React from 'react'

function Arrow2Left({ classes = '' }) {
  return (
    <svg
      className={'icon icon--strokes icon--arrow-2-left ' + classes}
      fill="none"
      viewBox="0 0 25 24"
    >
      <line x1="20.6311" y1="11.5" x2="4.6311" y2="11.5" fill="none" strokeWidth="2" />
      <path d="M9.1311 16L4.6311 11.5L9.1311 7" fill="none" strokeWidth="2" />
    </svg>
  )
}

export default Arrow2Left
