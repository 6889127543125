import React from 'react'

function Email(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--strokes icon--email ' + (props.classes ? props.classes : '')}
    >
      <path
        fill="none"
        d="M20.5715 6H3.57153C3.01925 6 2.57153 6.44772 2.57153 7V17C2.57153 17.5523 3.01925 18 3.57153 18H20.5715C21.1238 18 21.5715 17.5523 21.5715 17V7C21.5715 6.44772 21.1238 6 20.5715 6Z"
        strokeWidth="2"
      />
      <path
        fill="none"
        d="M3.07141 6.5L11.4528 13.0982C11.8157 13.3839 12.3271 13.3839 12.69 13.0982L21.0714 6.5"
        strokeWidth="2"
      />
      <path
        fill="none"
        d="M14.492 11.7148L21.0714 17.5009M3.07141 17.5009L9.59051 11.7679L3.07141 17.5009Z"
        strokeWidth="2"
      />
    </svg>
  )
}

export default Email
