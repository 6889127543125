import React from 'react'

function Regalo(props) {
  return (
    <svg
      viewBox="0 0 71 70"
      xmlns="http://www.w3.org/2000/svg"
      className={'icon icon--regalo ' + (props.classes ? props.classes : '')}
    >
      <path
        d="M54.8333 19.412H47.593C48.1537 18.1274 48.1633 16.619 47.4963 15.2858C46.2977 12.8918 43.3977 11.9186 41.0197 13.1059C39.5213 13.8552 36.834 17.0959 35.4903 18.7989C34.1467 17.1056 31.469 13.8649 29.9707 13.1059C27.5927 11.9186 24.683 12.8918 23.494 15.2858C22.8367 16.619 22.8367 18.1274 23.3973 19.412H16.1667C15.635 19.412 15.2 19.85 15.2 20.3852V27.1974C15.2 27.7327 15.635 28.1706 16.1667 28.1706H17.1333V51.5268C17.1333 52.062 17.5683 52.5 18.1 52.5H52.9C53.4317 52.5 53.8667 52.062 53.8667 51.5268V28.1706H54.8333C55.365 28.1706 55.8 27.7327 55.8 27.1974V20.3852C55.8 19.85 55.365 19.412 54.8333 19.412ZM51.9333 46.6609H19.0667V28.1706H22.45V36.9292C22.45 37.3185 22.682 37.6785 23.0493 37.8245C23.1653 37.8732 23.291 37.9024 23.4167 37.9024C23.668 37.9024 23.9193 37.805 24.103 37.6201L26.3167 35.3916L28.5303 37.6201C28.8107 37.9024 29.2263 37.9802 29.584 37.8342C29.9417 37.6883 30.1833 37.3282 30.1833 36.9389V28.1803H40.8167V36.9389C40.8167 37.3282 41.0487 37.6883 41.416 37.8342C41.532 37.8829 41.6577 37.9121 41.7833 37.9121C42.0347 37.9121 42.286 37.8148 42.4697 37.6299L44.6833 35.4013L46.897 37.6299C47.1773 37.9121 47.593 37.9899 47.9507 37.844C48.3083 37.698 48.55 37.3379 48.55 36.9486V28.1901H51.9333V46.6609ZM53.8667 26.2243H52.9H47.5833H41.7833H29.2167H23.4167H18.1H17.1333V21.3584H35.4323C35.442 21.3584 35.4613 21.3681 35.471 21.3681C35.4807 21.3681 35.5 21.3584 35.5097 21.3584H53.8667V26.2243Z"
        stroke="none"
      />
    </svg>
  )
}

export default Regalo
