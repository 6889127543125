import React from 'react'
import { OverlayView, Marker } from 'react-google-maps'
import { CentroEsteticoCard } from 'components/molecules'
import { useIsMobile } from 'utils/hooks'
import { trackingGAevent } from 'utils/gtm'

const getPixelPositionOffset = (pixelOffset) => (width, height) => ({
  x: -(width / 2) + pixelOffset.x,
  y: -(height / 2) + pixelOffset.y,
})

const GoogleMapMarkerInfo = ({
  centro,
  setSelectedCentro,
  selectedCentro,
  setMapCoords,
  myAccount = false,
}) => {
  const isMobile = useIsMobile()

  const markerClick = () => {
    setMapCoords({ lat: centro.latitudine, lng: centro.longitudine })
    setSelectedCentro(centro)
    trackingGAevent(3, 'store_locator', 'click_placeholder', centro.ragioneSociale)
    myAccount ? trackingGAevent(36, 'my_account', 'negozi_preferiti', 'click_placeholder') : null
  }

  const closeClick = (e) => {
    e.stopPropagation()
    setSelectedCentro(null)
  }

  return (
    <Marker
      position={{
        lat: Number(centro.latitudine),
        lng: Number(centro.longitudine),
      }}
      onClick={() => markerClick()}
      icon={{
        url: `/img/marker_centro.svg`,
      }}
    >
      {selectedCentro && selectedCentro.pk === centro.pk && !isMobile && (
        <OverlayView
          position={{
            lat: Number(centro.latitudine),
            lng: Number(centro.longitudine),
          }}
          key={Math.random()}
          defaultMapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset({ x: 0, y: -150 })}
        >
          <CentroEsteticoCard centroEstetico={centro} variant="map" onClose={closeClick} />
        </OverlayView>
      )}
    </Marker>
  )
}

export default GoogleMapMarkerInfo
