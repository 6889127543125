import React, { useEffect, useState, useRef } from 'react'
import { useLazyQuery } from '@apollo/client'
import { InfiniteLoader } from 'components/atoms'
import { TrattamentoCard, TrattamentoCardBig, VideoOverlay } from 'components/molecules'
import { Filters } from 'components/organisms'
import { TRATTAMENTI } from 'utils/queries'
import { useIsClient, useScroll, useTrans } from 'utils/hooks'
import { normalizeGraphQLResults } from 'utils/safe'

const TrattamentiListItem = ({ trattamento, index, selected, setSelected }) => {
  const [videoOpen, setVideoOpen] = useState(false)
  return (
    <>
      <div className={'trattamenti-list__item trattamenti-list__item--order-' + (index % 6)}>
        <TrattamentoCard
          trattamento={trattamento}
          expanded={selected}
          onDetailClick={() => setSelected(true)}
          onVideoOpen={() => setVideoOpen(true)}
        />
      </div>
      {/* Sempre presente per transition */}
      <div
        className={
          'trattamenti-list__item trattamenti-list__item--expanded' +
          (selected ? ' trattamenti-list__item--expanded--open' : '')
        }
      >
        <TrattamentoCardBig
          trattamento={trattamento}
          onClose={() => setSelected(false)}
          onVideoOpen={() => setVideoOpen(true)}
        />
      </div>
      <VideoOverlay
        video={trattamento.videoTrattamento}
        open={videoOpen}
        onClose={() => setVideoOpen(false)}
      />
    </>
  )
}

const TrattamentiList = ({ trattamenti }) => {
  const [selectedId, setSelectedId] = useState(null)

  return (
    <div className="trattamenti-list">
      {/* SUPERHACK - Serve per aprire la parte espansa nel punto corretto (6 perchè denominatore comune degli elementi per riga tra 3(lg) e 2(md)) */}
      {[...Array(Math.ceil(trattamenti.length / 6)).keys()].map((blockIndex) => {
        return (
          <div className="trattamenti-list__block" key={'block-' + blockIndex}>
            {trattamenti
              .slice(blockIndex * 6, Math.min(trattamenti.length, (blockIndex + 1) * 6))
              .map((trattamento, index) => (
                <TrattamentiListItem
                  trattamento={trattamento}
                  index={index}
                  selected={selectedId == trattamento.id}
                  setSelected={(value) => setSelectedId(value ? trattamento.id : null)}
                  key={'trattamentilist-' + index}
                />
              ))}
          </div>
        )
      })}
    </div>
  )
}

const TrattamentiListing = ({ attributiFiltri, trattamenti }) => {
  const t = useTrans()
  const initFiltri = useRef(false)
  const [trattamentiFiltrati, setTrattamentiFiltrati] = useState(trattamenti)
  const [filtri, setFiltri] = useState(
    attributiFiltri.map((attr) => ({
      name: attr.slug,
      label: attr.nome,
      valori_disponibili: attr.valori_disponibili.map((v) => ({
        value: v.pk,
        label: v.nome,
        selezionato: v.pk === null ? true : false, // all è selezionato come default
      })),
    }))
  )

  const isClientSide = useIsClient(false)

  const getValoriFiltro = (name) => {
    return filtri
      .find((attr) => attr.name === name)
      ?.valori_disponibili.filter((v) => v.selezionato && v.value !== null)
      ?.map((v) => v.value.toString())
  }

  const [getTrattamenti, { loading }] = useLazyQuery(TRATTAMENTI, {
    variables: {
      tipologia: getValoriFiltro('tipologia'),
      durata: getValoriFiltro('durata'),
      zona: getValoriFiltro('zona'),
      pelle: getValoriFiltro('pelle'),
    },
    onCompleted: (data) => {
      setTrattamentiFiltrati(
        data && data.trattamenti ? normalizeGraphQLResults(data.trattamenti) : []
      )
    },
    fetchPolicy: 'cache-first',
  })

  const handleFilters = (action, name, value) => {
    let newFiltri = filtri.slice()
    for (let i = 0; i < newFiltri.length; i++) {
      if (newFiltri[i].name === name) {
        let oneSelected = false
        for (let j = 0; j < newFiltri[i].valori_disponibili.length; j++) {
          // Se il value è null, è l'"all" - quindi rimuovo tutte le selezioni per quell'attributo e seleziono solo all
          if (value === null) {
            newFiltri[i].valori_disponibili[j].selezionato =
              newFiltri[i].valori_disponibili[j].value === value
          } else {
            if (value === newFiltri[i].valori_disponibili[j].value) {
              switch (action) {
                case 'remove':
                  newFiltri[i].valori_disponibili[j].selezionato = false
                  break
                case 'toggle':
                  newFiltri[i].valori_disponibili[j].selezionato =
                    !newFiltri[i].valori_disponibili[j].selezionato
                  break
              }
            }
            if (newFiltri[i].valori_disponibili[j].selezionato) oneSelected = true
          }
        }
        // Gestione all - se non ci sono selezionati metto l'all
        for (let j = 0; j < newFiltri[i].valori_disponibili.length; j++) {
          if (newFiltri[i].valori_disponibili[j].value === null) {
            newFiltri[i].valori_disponibili[j].selezionato = !oneSelected
          }
        }
      }
    }
    setFiltri(newFiltri)
  }

  useEffect(() => {
    if (initFiltri.current) {
      getTrattamenti()
    } else {
      initFiltri.current = true
    }
  }, [filtri])

  return (
    <div className="trattamenti-listing">
      {isClientSide && <Filters attributi={filtri} onChange={handleFilters} />}
      {loading ? (
        <div className="trattamenti-listing__loading">
          <InfiniteLoader />
        </div>
      ) : trattamentiFiltrati?.length ? (
        <div className="trattamenti-listing__list">
          <TrattamentiList trattamenti={trattamentiFiltrati} />
        </div>
      ) : (
        <div className="trattamenti-listing__empty">
          {t('Non ci sono trattamenti disponibili.')}
        </div>
      )}
    </div>
  )
}

export default TrattamentiListing
