import { useMutation } from '@apollo/client'
import {
  Button,
  Divider,
  Modal,
  FormLayout,
  FormBlock,
  FormField,
  FormCheckbox,
  FormInput,
  FormRadio,
} from 'components/atoms'
import { Form, Formik } from 'formik'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { UPDATE_UTENTE } from 'utils/queries'
import { useTrans } from 'utils/hooks'
import { SelezionaCentro } from '..'
import { registrazioneComplete } from 'utils/validation'

function FormRegistrazioneComplete({ classes = 'form-registrazione' }) {
  const router = useRouter()
  const t = useTrans()

  const [errors, setErrors] = useState(false)

  const [updateUtente, { loading: mutationLoading }] = useMutation(UPDATE_UTENTE, {
    errorPolicy: 'all',
  })

  async function handleSubmit(values, setSubmitting) {
    setSubmitting(true)

    const response = await updateUtente({
      variables: {
        input: {
          telefono: values.telefono,
          privacyCommerciale: values.commerciale === 's',
          centroEstetico: values.centro,
          privacy: true,
          socialCompleted: true,
        },
      },
    })

    if (response.errors && response.errors.length) {
      setErrors(response.errors[0])
    } else {
      router.push('/') // TODO: gestione next page
    }

    setSubmitting(false)
  }

  return (
    <Formik
      validateOnChange={true}
      validationSchema={registrazioneComplete}
      initialValues={{
        telefono: '',
        commerciale: 'n',
        centro: '',
        privacy: false,
      }}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting)
      }}
    >
      {(props) => (
        <Form className={classes}>
          <section className="form-registrazione__section form-registrazione__dati">
            <FormBlock title={t('Dati personali')}>
              <FormField>
                <FormInput
                  name="telefono"
                  placeholder={t('Numero di telefono')}
                  value={props.values.telefono}
                  onChange={props.handleChange}
                />
              </FormField>
            </FormBlock>
          </section>
          <SelezionaCentro
            formik={props}
            classes="form-registrazione__section form-registrazione__centro"
          />
          <section className="form-registrazione__section form-registrazione__privacy">
            <FormLayout>
              <FormField size="full" space="lg">
                <FormCheckbox
                  name="privacy"
                  label={
                    <span>
                      {t('Fornisco il consenso per la registrazione come indicato nell’')}
                      <Link legacyBehavior href="/privacy">
                        <a>{t('informativa')}</a>
                      </Link>
                    </span>
                  }
                  checked={props.values.privacy}
                  onChange={props.handleChange}
                />
              </FormField>
              <FormField size="full">
                <FormRadio
                  size="sm"
                  description={t(
                    'Fornisco il consenso per finalità di Marketing come indicato nell’Informativa e dichiaro di avere compiuto almeno 14 anni.'
                  )}
                  name="commerciale"
                  value={props.values.commerciale}
                  options={[
                    { label: t('Sì').toUpperCase(), value: 's' },
                    { label: t('No').toUpperCase(), value: 'n' },
                  ]}
                  onChange={props.handleChange}
                />
              </FormField>
            </FormLayout>
          </section>
          <section className="form-registrazione__submit">
            <Button
              label="Registrati"
              disabled={!props.isValid || mutationLoading || props.isSubmitting}
              inputType="submit"
            />
            <p className="form-registrazione__submit__login">
              {t('Hai già un account?')}{' '}
              <Link legacyBehavior href="/login">
                <a>{t('Accedi')}</a>
              </Link>
            </p>
          </section>
          <Modal open={errors} setOpen={() => setErrors(false)}>
            <p className="form-registrazione__error">{errors}</p>
          </Modal>
        </Form>
      )}
    </Formik>
  )
}

export default FormRegistrazioneComplete
