import React, { useState, useEffect } from 'react'
import { Button, WImage } from 'components/atoms'
import { Brush, Heart, HeartFilled } from 'components/atoms/Icons'
import { priceNotation } from 'utils/safe'
import { useTrans, useWishlist } from 'utils/hooks'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { trackingGAevent, trackingProductDetail } from 'utils/gtm'

SwiperCore.use([Pagination])

function ProdottoInfo({ prodotto, addToCart = () => {} }) {
  const t = useTrans()

  const [prodottoWishlist, setProdottoWishlist] = useState(
    prodotto.wishlist ? prodotto.wishlist : false
  )
  function handleAddToCart() {
    addToCart(prodotto)
  }

  const handleWishlist = useWishlist()

  const HandleClickWishlist = () => {
    handleWishlist(prodottoWishlist, prodotto).then((response) => {
      setProdottoWishlist(response)
    })

    if (!prodottoWishlist)
      trackingGAevent(
        37,
        'product',
        'add_to_wishlist',
        'product_detail',
        undefined,
        prodotto?.codice
      )
    else
      trackingGAevent(
        38,
        'product',
        'remove_from_wishlist',
        'product_detail',
        undefined,
        prodotto?.codice
      )
  }

  useEffect(() => {
    if (prodotto.prezzoScontato) trackingProductDetail(prodotto)
  }, [prodotto.prezzoScontato])

  return (
    <div className="prodotto-info">
      <span className="prodotto-info__code prodotto-info__code--mobile">
        Cod. {prodotto.codice}
      </span>
      <div className="prodotto-info__image-box">
        {prodotto.immagini.length > 1 ? (
          <div className="prodotto-info__slider">
            <Swiper
              navigation={false}
              direction="vertical"
              pagination={{ clickable: true }}
              slidesPerView={1}
            >
              {prodotto.immagini.map((immagine, index) => (
                <SwiperSlide key={index}>
                  <div className="prodotto-info__slide">
                    <WImage
                      src={immagine.imageThumb}
                      maxWidth={360}
                      maxHeight={360}
                      alt={`${prodotto.titolo} ${prodotto.sottotitolo || ''}`.toAltTitle()}
                      title={`${prodotto.titolo} ${prodotto.sottotitolo || ''}`.toAltTitle()}
                      className="prodotto-info__slide-image"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ) : (
          <WImage
            src={prodotto.immagini?.length > 0 && prodotto.immagini[0].imageThumb}
            maxWidth={360}
            maxHeight={360}
            alt={`${prodotto.titolo} ${prodotto.sottotitolo || ''}`.toAltTitle()}
            title={`${prodotto.titolo} ${prodotto.sottotitolo || ''}`.toAltTitle()}
            classPrefix="prodotto-info"
          />
        )}
      </div>
      <div className="prodotto-info__content">
        <div className="prodotto-info__head">
          <h3 className="prodotto-info__category">{prodotto.linea?.nome}</h3>
          <span className="prodotto-info__brush">
            <Brush color={prodotto.linea?.colore} title={prodotto.linea?.nome} />
          </span>
          <span className="prodotto-info__code prodotto-info__code--desktop">
            Cod. {prodotto.codice}
          </span>
        </div>
        <h1 className="prodotto-info__title">{prodotto.titolo}</h1>
        <h2 className="prodotto-info__subtitle">{prodotto.sottotitolo}</h2>
        <div
          className="prodotto-info__text"
          dangerouslySetInnerHTML={{ __html: prodotto.descrizione }}
        />
        <p className="prodotto-info__size">{prodotto.formato?.nome}</p>
        <p className="prodotto-info__price">
          {prodotto.disponibile &&
            (prodotto.prezzoScontato !== prodotto.prezzo && prodotto.prezzoScontato ? (
              <>
                <span className="prodotto-info__fullprice">{priceNotation(prodotto.prezzo)}</span>
                {'   '}
                {priceNotation(prodotto.prezzoScontato)}
              </>
            ) : (
              <span className="prodotto-info__finalprice">
                {priceNotation(prodotto.prezzoScontato)}
              </span>
            ))}
        </p>
        <div className="prodotto-info__buttons">
          <Button
            type="secondary"
            icon={prodottoWishlist ? <HeartFilled /> : <Heart />}
            onClick={() => HandleClickWishlist()}
          />
          {prodotto.disponibile ? (
            <Button type="primary" label="Aggiungi al carrello" onClick={handleAddToCart} />
          ) : (
            <Button type="primary" label={t('Non disponibile')} disabled />
          )}
        </div>
      </div>
    </div>
  )
}

export default ProdottoInfo
