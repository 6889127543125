import React from 'react'
import Link from 'next/link'
import { Button, WImage } from 'components/atoms'
import { useTrans } from 'utils/hooks'
import { trackingGAevent } from 'utils/gtm'

const ArticoloMagazineCard = ({ articolo, articolo_padre, main, onClick = () => {} }) => {
  const t = useTrans()

  const tracking = () => {
    if (articolo_padre) {
      trackingGAevent(50, 'blog', 'esplora_articoli_correlati', articolo_padre.title)
    } else if (onClick) {
      onClick()
    }
  }

  return (
    <Link legacyBehavior href={articolo.url}>
      <a
        className={`articolo-magazine-card ${main ? 'articolo-magazine-card--main' : ''}`}
        onClick={() => tracking()}
      >
        <div className="articolo-magazine-card__head">
          <WImage
            src={main ? articolo.thumbnailBig : articolo.thumbnailSmall}
            maxWidth={main ? 713 : 365}
            maxHeight={310}
            alt={articolo.title.toAltTitle()}
            title={articolo.title.toAltTitle()}
            classPrefix="articolo-magazine-card"
          />
          <p className="articolo-magazine-card__category">{articolo.categoria?.title}</p>
          <p className="articolo-magazine-card__title">{articolo.title}</p>
        </div>
        <div className="articolo-magazine-card__content">
          <p className="articolo-magazine-card__abstract">{articolo.abstract}</p>
          <p className="articolo-magazine-card__data">{articolo.publicationDate}</p>
          <Button
            type="ghost"
            label={t("Leggi l'articolo")}
            classes="articolo-magazine-card__cta"
          />
        </div>
      </a>
    </Link>
  )
}

export default ArticoloMagazineCard
