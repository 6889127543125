import { useMutation } from '@apollo/client'
import {
  Button,
  FormBlock,
  FormSelect,
  Modal,
  FormInput,
  FormInputPassword,
  FormCheckbox,
  FormLayout,
  FormField,
  FormRadio,
} from 'components/atoms'
import { Form, Formik } from 'formik'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useTrans } from 'utils/hooks'
import { ANNI, GIORNI, MESI } from 'utils/forms'
import { CREATE_UTENTE } from 'utils/queries'
import { SelezionaCentro } from '..'
import { registrazione } from 'utils/validation'
import { trackingGAevent } from 'utils/gtm'

function FormRegistrazione({ classes = 'form-registrazione' }) {
  const router = useRouter()
  const t = useTrans()

  const [errors, setErrors] = useState(false)

  const [createUtente, { loading: mutationLoading }] = useMutation(CREATE_UTENTE, {
    errorPolicy: 'all',
  })

  async function handleRedirect() {
    router.push('/registrazione/activate')
  }

  async function handleSubmit(values, setSubmitting) {
    setSubmitting(true)

    const response = await createUtente({
      variables: {
        input: {
          nome: values.nome,
          cognome: values.cognome,
          email: values.email,
          password: values.password,
          giornoNascita: values.giorno.toString(),
          meseNascita: values.mese.toString(),
          annoNascita: values.anno.toString(),
          telefono: values.telefono,
          sesso: values.sesso,
          privacyCommerciale: Boolean(parseInt(values.commerciale)),
          centroEstetico: values.centro,
          privacy: true,
          isActive: false, // attivazione via email
        },
      },
    })

    if (response.errors && response.errors.length) {
      setErrors(true)
    } else {
      const utente = response.data?.createUtente?.utente
      if (utente)
        trackingGAevent(
          9,
          'user',
          'registration_step2',
          utente.pk,
          utente.centroEstetico.ragioneSociale
        )
      handleRedirect()
    }

    setSubmitting(false)
  }

  return (
    <Formik
      validateOnChange={true}
      validationSchema={registrazione}
      initialValues={{
        nome: '',
        cognome: '',
        telefono: '',
        email: '',
        email2: '',
        password: '',
        password2: '',
        giorno: '',
        mese: '',
        anno: '',
        sesso: '',
        commerciale: null,
        centro: '',
        privacy: false,
      }}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting)
      }}
    >
      {(props) => (
        <Form className={classes}>
          <section className="form-registrazione__section form-registrazione__dati">
            <FormBlock title={t('Dati personali')}>
              <FormLayout>
                <FormField>
                  <FormInput
                    name="nome"
                    placeholder={t('Nome')}
                    value={props.values.nome}
                    status={props.errors.nome ? 'error' : ''}
                    errorMessage={props.errors.nome}
                    onChange={props.handleChange}
                  />
                </FormField>
                <FormField>
                  <FormInput
                    name="cognome"
                    placeholder={t('Cognome')}
                    value={props.values.cognome}
                    status={props.errors.cognome ? 'error' : ''}
                    errorMessage={props.errors.cognome}
                    onChange={props.handleChange}
                  />
                </FormField>
                <FormField size="full">
                  <FormInput
                    name="telefono"
                    placeholder={t('Numero di telefono')}
                    value={props.values.telefono}
                    status={props.errors.telefono ? 'error' : ''}
                    errorMessage={props.errors.telefono}
                    onChange={props.handleChange}
                  />
                </FormField>
                <FormField>
                  <FormInput
                    name="email"
                    placeholder={t('Email')}
                    value={props.values.email}
                    status={props.errors.email ? 'error' : ''}
                    errorMessage={props.errors.email}
                    onChange={props.handleChange}
                  />
                </FormField>
                <FormField>
                  <FormInput
                    name="email2"
                    placeholder={t('Conferma email')}
                    value={props.values.email2}
                    status={props.errors.email2 ? 'error' : ''}
                    errorMessage={props.errors.email2}
                    onChange={props.handleChange}
                  />
                </FormField>
                <FormField>
                  <FormInputPassword
                    name="password"
                    type="password"
                    placeholder={t('Password')}
                    value={props.values.password}
                    status={props.errors.password ? 'error' : ''}
                    errorMessage={props.errors.password}
                    onChange={props.handleChange}
                    togglePassword={false}
                  />
                </FormField>
                <FormField space="lg">
                  <FormInputPassword
                    name="password2"
                    type="password"
                    placeholder={t('Conferma password')}
                    value={props.values.password2}
                    status={props.errors.password2 ? 'error' : ''}
                    errorMessage={props.errors.password2}
                    onChange={props.handleChange}
                    togglePassword={false}
                  />
                </FormField>
                <FormField label={t('Data di nascita')} variant="date" size="full" space="lg">
                  <FormSelect
                    name="giorno"
                    options={GIORNI.map((g) => ({ value: g, label: g }))}
                    placeholder={t('Giorno')}
                    onChange={({ value }) => props.setFieldValue('giorno', value)}
                  />
                  <FormSelect
                    name="mese"
                    options={MESI.map((m) => ({ value: m, label: m }))}
                    placeholder={t('Mese')}
                    onChange={({ value }) => props.setFieldValue('mese', value)}
                  />
                  <FormSelect
                    name="anno"
                    options={ANNI.map((a) => ({ value: a, label: a }))}
                    placeholder={t('Anno')}
                    onChange={({ value }) => props.setFieldValue('anno', value)}
                  />
                </FormField>
                <FormField label={t('Sesso')} size="full">
                  <FormRadio
                    name="sesso"
                    value={props.values.sesso}
                    options={[
                      { label: t('Donna'), value: 'd' },
                      { label: t('Uomo'), value: 'u' },
                      { label: t('Preferisco non rispondere'), value: 'x' },
                    ]}
                    onChange={props.handleChange}
                  />
                </FormField>
              </FormLayout>
            </FormBlock>
          </section>
          <SelezionaCentro
            formik={props}
            classes="form-registrazione__section form-registrazione__centro"
          />
          <section className="form-registrazione__section form-registrazione__privacy">
            <FormLayout>
              <FormField size="full" space="lg">
                <FormCheckbox
                  name="privacy"
                  label={
                    <>
                      Autorizzo il trattamento dei miei dati personali per fini di registrazione
                      come indicato al punto D) dell'<a href="/privacy/">informativa</a>.
                    </>
                  }
                  checked={props.values.privacy}
                  onChange={props.handleChange}
                />
              </FormField>
              <FormField size="full">
                <FormRadio
                  size="sm"
                  description={
                    <>
                      Fornisco il consenso con la finalità di ricezione di comunicazioni
                      promozionali e commerciali come indicato nel punto D) nell’
                      <a href="/privacy/">informativa</a>
                    </>
                  }
                  name="commerciale"
                  value={props.values.commerciale}
                  options={[
                    { label: t('Sì'), value: 1 },
                    { label: t('No'), value: 0 },
                  ]}
                  onChange={props.handleChange}
                />
              </FormField>
            </FormLayout>
          </section>
          <section className="form-registrazione__submit">
            <Button
              label={t('Registrati')}
              disabled={!props.isValid || mutationLoading || props.isSubmitting}
              inputType="submit"
            />
            <p className="form-registrazione__submit__login">
              {t('Hai già un account?')}{' '}
              <Link legacyBehavior href="/login">
                <a>{t('Accedi')}</a>
              </Link>
            </p>
          </section>
          <Modal open={errors} setOpen={() => setErrors(false)}>
            <p className="form-registrazione__error">{t('Email già utilizzata')}</p>
          </Modal>
        </Form>
      )}
    </Formik>
  )
}

export default FormRegistrazione
